import CenteredContainer from '../../../centeredContainer/CenteredContainer';
import { GetCalendlyEvents } from '../../../../Services';
import { Fragment, useEffect, useRef, useState } from 'react';
import Spinner from '../../../../spinner/Spinner';
import './CalendlyBookings.css';
import CalendlyBookingsList from './calendlyBookingsList/CalendlyBookingsList';
import { toast } from 'react-toastify';

const CalendlyBookings = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ bookingData, setBookingData ] = useState(null);
	const [ isCalendlyConnected, setIsCalendlyConnected ] = useState(false);
	const [ isCalendlyWindowOpen, setIsCalendlyWindowOpen ] = useState(false);
	const [ isCalendlyWindowClosed, setIsCalendlyWindowClosed ] = useState(false);
	const calendlyWindow = useRef(null);
	let windowInterval;

	useEffect(() => {
		listEvents();

		return () => {
			clearInterval(windowInterval);
		};
	}, []);

	const listEvents = async () => {
		const response = await GetCalendlyEvents();

		setIsCalendlyConnected(response.IsCalendlyConnected);

		setIsLoading(false);
		console.log(response.Data);
		setBookingData(response.Data);
	};

	useEffect(
		() => {
			if (!isCalendlyWindowOpen && isCalendlyWindowClosed && isCalendlyConnected) {
				toast.success('Calendly connected successfully');
			}
		},
		[ isCalendlyWindowOpen, isCalendlyWindowClosed, isCalendlyConnected ]
	);

	const popupWindow = (url, windowName, win, w, h) => {
		const y = win.top.outerHeight / 2 + win.top.screenY - h / 2;
		const x = win.top.outerWidth / 2 + win.top.screenX - w / 2;
		return win.open(
			url,
			windowName,
			`toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
		);
	};

	const handleCalendlyWindowOpen = () => {
		calendlyWindow.current = popupWindow(
			`${window.location.origin}/c/oauth/calendly/authorize`,
			'',
			window,
			600,
			500
		);
		setIsCalendlyWindowOpen(true);

		windowInterval = setInterval(
			() => {
				if (calendlyWindow.current && calendlyWindow.current.closed) {
					clearInterval(windowInterval);
					setIsCalendlyWindowOpen(false);
					setIsCalendlyWindowClosed(true);
					setIsLoading(true);
					handleCalendlyWindowClose();
				}
			},
			[ 200 ]
		);
	};

	const handleCalendlyWindowClose = () => {
		listEvents();
	};

	return (
		<div className="app-calendly-bookings panel">
			{isLoading ? (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			) : (
				<Fragment>
					{isCalendlyConnected ? (
						<Fragment>
							<div className="container">
								{bookingData && <CalendlyBookingsList bookingsList={bookingData} />}
							</div>
						</Fragment>
					) : (
						<Fragment>
							<CenteredContainer>
								<button
									type="button"
									className="btn btn-primary"
									onClick={handleCalendlyWindowOpen}
									disabled={isCalendlyWindowOpen}
								>
									{!isCalendlyWindowOpen ? (
										<Fragment>
											<span>Connect Calendly</span>
										</Fragment>
									) : (
										<Fragment>
											<Spinner />
											<span>Waiting for Connection...</span>
										</Fragment>
									)}
								</button>
							</CenteredContainer>
						</Fragment>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default CalendlyBookings;
