import './BrandHistoryList.css';
import { Fragment, useState } from 'react';
import BrandHistoryItem from '../brandHistoryItem/BrandHistoryItem';

const BrandHistoryList = ({ items }) => {
	const [ isLoading, setIsLoading ] = useState(true);

	return (
		<Fragment>
			<div className="rw-table table-history">
				<div className="history-list-header rw-table-header">
					<div className="rw-th template">Template</div>
					<div className="rw-th recipient">Recipient(s)</div>
					<div className="rw-th created">Date</div>
					<div className="rw-th action" />
				</div>
				{items.map((item) => <BrandHistoryItem key={item.Guid} item={item} />)}
			</div>
		</Fragment>
	);
};

export default BrandHistoryList;
