import { Fragment, useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { XyzTransitionGroup } from '@animxyz/react';
import { animationDefaults } from '../../../../AnimConfig';
import WebsiteWizardSetupWizardBusinessName from './steps/websiteWizardSetupWizardBusinessName/WebsiteWizardSetupWizardBusinessName';
import './WebsiteWizardSetupWizard.css';
import WebsiteWizardSetupWizardStart from './steps/websiteWizardSetupWizardStart/WebsiteWizardSetupWizardStart';
import WebsiteWizardSetupWizardBusinessPhone from './steps/websiteWizardSetupWizardBusinessPhone/WebsiteWizardSetupWizardBusinessPhone';
import WebsiteWizardSetupWizardBusinessAddress from './steps/websiteWizardSetupWizardBusinessAddress/WebsiteWizardSetupWizardBusinessAddress';
import WebsiteWizardSetupWizardComplete from './steps/websiteWizardSetupWizardComplete/WebsiteWizardSetupWizardComplete';
import WebsiteWizardSetupWizardSuccess from './steps/websiteWizardSetupWizardSuccess/WebsiteWizardSetupWizardSuccess';
import MainAppHeader from '../../mainAppHeader/MainAppHeader';

const WebsiteWizardSetupWizard = () => {
	const { path } = useRouteMatch();

	return (
		<Fragment>
			<MainAppHeader
				hideBanners
				hideCart
				hideProfileInfo
				chatButtonText="Need Help? Start Chat"
			/>
			<div className="panel website-setup-wizard">
				<XyzTransitionGroup xyz={animationDefaults.xyzBottomStagger}>
					<Switch>
						<Route path={`${path}/success`}>
							<WebsiteWizardSetupWizardSuccess />
						</Route>
						<Route path={`${path}/complete`}>
							<WebsiteWizardSetupWizardComplete />
						</Route>
						<Route path={`${path}/business-address`}>
							<WebsiteWizardSetupWizardBusinessAddress />
						</Route>
						<Route path={`${path}/business-phone`}>
							<WebsiteWizardSetupWizardBusinessPhone />
						</Route>
						<Route path={`${path}/business-name`}>
							<WebsiteWizardSetupWizardBusinessName />
						</Route>
						<Route path={`${path}`}>
							<WebsiteWizardSetupWizardStart />
						</Route>
					</Switch>
				</XyzTransitionGroup>
			</div>
		</Fragment>
	);
};

export default WebsiteWizardSetupWizard;
