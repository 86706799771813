import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
	DeleteContact,
	MarkContactSpam,
	RestoreContact,
	UpdateContactStatus
} from '../../../../Services';
import BulkActionsContextProgressDialog from '../../../bulkActionsContextProgressDialog/BulkActionsContextProgressDialog';
import ContextMenu from '../../../contextMenu/ContextMenu';
import CrmLead from './crmLead/CrmLead';
import DeleteLeadDialog from './crmLeadDetails/deleteLeadDialog/DeleteLeadDialog';
import DeleteLeadsDialog from './crmLeadDetails/deleteLeadsDialog/DeleteLeadsDialog';
import './CrmList.css';

const CrmList = ({ leads, onUpdate, listType = 'leads' }) => {
	const [ selectedLeads, setSelectedLeads ] = useState([]);
	const [ isUpdatingBulk, setIsUpdatingBulk ] = useState(false);
	const [ showDeleteConfirmation, setShowDeleteConfirmation ] = useState(
		false
	);
	const [ bulkAction, setBulkAction ] = useState();
	const [ bulkMessage, setBulkMessage ] = useState();
	const [ bulkAdditionalPayload, setBulkAdditionalPayload ] = useState();

	const handleSelection = (lead) => {
		if (lead.selected) {
			let tempSelectedLeads = selectedLeads.map((l) => l);
			tempSelectedLeads.push(lead);

			setSelectedLeads(tempSelectedLeads);
		} else {
			const leadIndex = selectedLeads.findIndex(
				(l) => l.Guid == lead.Guid
			);

			if (leadIndex > -1) {
				let tempSelectedLeads = selectedLeads.map((l) => l);
				tempSelectedLeads.splice(leadIndex, 1);

				setSelectedLeads(tempSelectedLeads);
			}
		}
	};

	const handleArchive = async () => {
		if (listType === 'archive') {
			setBulkMessage('Unarchiving contacts...');
		} else {
			setBulkMessage('Archiving contacts...');
		}
		setBulkAdditionalPayload({
			Status : listType === 'archive' ? 'Active' : 'Archived'
		});
		setBulkAction(() => UpdateContactStatus);
		setIsUpdatingBulk(true);
	};

	const handleSpam = async () => {
		if (listType === 'spam') {
			setBulkMessage('Marking contacts as not spam...');
		} else {
			setBulkMessage('Marking contacts as spam...');
		}
		setBulkAdditionalPayload({
			IsSpam : listType === 'spam' ? false : true
		});
		setBulkAction(() => MarkContactSpam);
		setIsUpdatingBulk(true);
	};

	const handleDelete = async () => {
		if (listType === 'trash') {
			setBulkMessage('Restoring contacts...');
			setBulkAction(() => RestoreContact);
		} else {
			setBulkMessage('Deleting contacts...');
			setBulkAction(() => DeleteContact);
		}
		setIsUpdatingBulk(true);
	};

	const toggleDeleteConfirmation = () => {
		setShowDeleteConfirmation((prev) => !prev);
	};

	const handleCancelSelected = () => {
		setSelectedLeads(new Array());
	};

	const onBulkComplete = (result) => {
		setSelectedLeads([]);
		setBulkMessage();
		setBulkAction();
		setBulkAdditionalPayload();
		setIsUpdatingBulk(false);

		console.log('result object...', result);
		if (!result.success) {
			toast.warning(
				"Operation complete but some items didn't complete successfully."
			);
		} else {
			toast.success('Operation completed successfully.');
		}

		if (onUpdate) {
			onUpdate();
		}
	};

	return (
		<Fragment>
			<div className="rw-container">
				<div className="rw-table table-leads">
					<div className="leads-list-header rw-table-header">
						<div className="rw-th email">Contact</div>
						<div className="rw-th phone">Phone</div>
						<div className="rw-th created">Date Created</div>
						<div className="rw-th action" />
					</div>
					{leads.map((lead) => (
						<CrmLead
							key={lead.Guid}
							lead={lead}
							onSelect={handleSelection}
							disabled={isUpdatingBulk}
							selectedLeads={selectedLeads}
						/>
					))}
				</div>
			</div>

			{selectedLeads &&
			selectedLeads.length > 0 && (
				<Fragment>
					<ContextMenu>
						{/* <button
							type="button"
							className="btn btn-outline-primary"
							disabled={isUpdatingBulk}
						>
							<span>Archive</span>
						</button> */}
						{listType === 'leads' ? (
							<Fragment>
								<button
									type="button"
									className="btn btn-primary"
									disabled={isUpdatingBulk}
									onClick={handleArchive}
								>
									<span>Archive</span>
								</button>
								<button
									type="button"
									className="btn btn-outline-danger"
									disabled={isUpdatingBulk}
									onClick={handleSpam}
								>
									<span>Spam</span>
								</button>
								<button
									type="button"
									className="btn btn-danger"
									onClick={toggleDeleteConfirmation}
									disabled={isUpdatingBulk}
								>
									<span>Delete</span>
								</button>
							</Fragment>
						) : listType === 'spam' ? (
							<Fragment>
								<button
									type="button"
									className="btn btn-outline-danger"
									disabled={isUpdatingBulk}
									onClick={handleSpam}
								>
									<span>Not Spam</span>
								</button>
								<button
									type="button"
									className="btn btn-danger"
									onClick={toggleDeleteConfirmation}
									disabled={isUpdatingBulk}
								>
									<span>Delete</span>
								</button>
							</Fragment>
						) : listType === 'archive' ? (
							<Fragment>
								<button
									type="button"
									className="btn btn-primary"
									disabled={isUpdatingBulk}
									onClick={handleArchive}
								>
									<span>Unarchive</span>
								</button>
								<button
									type="button"
									className="btn btn-outline-danger"
									disabled={isUpdatingBulk}
									onClick={handleSpam}
								>
									<span>Spam</span>
								</button>
								<button
									type="button"
									className="btn btn-danger"
									onClick={toggleDeleteConfirmation}
									disabled={isUpdatingBulk}
								>
									<span>Delete</span>
								</button>
							</Fragment>
						) : (
							listType === 'trash' && (
								<Fragment>
									<button
										type="button"
										className="btn btn-danger"
										onClick={handleDelete}
										disabled={isUpdatingBulk}
									>
										<span>Restore</span>
									</button>
								</Fragment>
							)
						)}
						<button
							type="button"
							className="btn btn-outline-primary"
							onClick={handleCancelSelected}
							disabled={isUpdatingBulk}
						>
							<span>Cancel</span>
						</button>
					</ContextMenu>

					{showDeleteConfirmation && (
						<DeleteLeadsDialog
							onCancel={toggleDeleteConfirmation}
							onDelete={handleDelete}
						/>
					)}

					{isUpdatingBulk && (
						<BulkActionsContextProgressDialog
							records={selectedLeads}
							action={bulkAction}
							message={bulkMessage}
							additionalPayload={bulkAdditionalPayload}
							onComplete={onBulkComplete}
						/>
					)}
				</Fragment>
			)}
		</Fragment>
	);
};

export default CrmList;
