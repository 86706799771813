import './TrialBanner.css';

const TrialBanner = () => {
	const handleClick = () => {
		window.location = window.location.origin + '/r/activate/';
	};

	return (
		<div className="container-fluid text-center app-trial-banner" onClick={handleClick}>
			<h3>
				<a className="app-link">
					<u>Click Here</u>
				</a>{' '}
				to start your 30 day trial and get a FREE domain!
			</h3>
		</div>
	);
};

export default TrialBanner;
