import React, { useState } from 'react';
import ModalDialog from '../../../../modalDialog/ModalDialog';
import ModalDialogContent from '../../../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import { UpdateUserSubscriptionId } from '../../../../../Services';
import { toast } from 'react-toastify';

const UpdateSubscriptionId = ({ userGuid, onClose, onUpdate }) => {
    const [isSaving, setIsSaving] = useState(false);
    const [formData, setFormData] = useState({
        SubscriptionId: ''
    });

    const handleChange = (e) => {
        const name = e.target.name;
        let value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        if (e.target.type !== 'checkbox') {
            value = value.trim();
        }

        setFormData((prev) => {
            return {
                ...prev,
                [name]: value
            };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        let lastConfirm = window.confirm(
            'Are you sure you want to update users subscription id? Doing this will also assign the customer id to the same customer that the subscription is attached to in Stripe!'
        );

        if (!lastConfirm) {
            return;
        }

        let data = {
            UserGuid: userGuid,
            SubscriptionId: formData.SubscriptionId
        };

        console.log(data);

        setIsSaving(true);

        const response = await UpdateUserSubscriptionId(data);

        if (response.IsSuccessful) {
            if (onUpdate) {
                toast.success(response.Message);
                onUpdate();
            }
        } else {
            toast.error(response.Message);
        }

        setIsSaving(false);
    };

    return (
        <ModalDialog>
            <ModalDialogContent>
                <div className="form-inputs">
                    <div className="form-group">
                        <label htmlFor="SubscriptionId">Subscription Id:</label>
                        <input
                            id="SubscriptionId"
                            className="form-control"
                            disabled={isSaving}
                            value={formData.SubscriptionId}
                            onChange={handleChange}
                            placeholder='Enter Subscription Id...'
                            name="SubscriptionId"
                        />
                    </div>
                </div>
            </ModalDialogContent>
            <ModalDialogButtons>
                <button type="button" className="btn btn-primary" disabled={isSaving} onClick={handleSubmit}>
                    {isSaving ? 'Updating Subscription Id...' : 'Update Subscription Id'}
                </button>
                <button type="button" className="btn btn-outline-primary" onClick={onClose}>
                    Cancel
                </button>
            </ModalDialogButtons>
        </ModalDialog>
    );
};

export default UpdateSubscriptionId;
