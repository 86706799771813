import { Fragment, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { animationDefaults } from '../../../../AnimConfig';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';
import './HeaderAccountMenu.css';

const HeaderAccountMenu = ({ hideMenu, xyzClassName }) => {
	const [ { user, brand, showScheduleCall }, dispatch ] = useStateValue();
	const accMenuRef = useRef(null);

	useEffect(
		() => {
			window.addEventListener('mousedown', handleClickOutside);

			return () => {
				window.removeEventListener('mousedown', handleClickOutside);
			};
		},
		[ accMenuRef ]
	);

	const handleClickOutside = (e) => {
		if (accMenuRef.current && !accMenuRef.current.contains(e.target)) {
			hideMenu();
		}
	};

	const toggleScheduleCallPanel = () => {
		hideMenu();
		dispatch({
			type             : actionTypes.SET_SHOW_SCHEDULE_CALL,
			showScheduleCall : true
		});
	};

	return (
		<Fragment>
			{user && (
				<div
					ref={accMenuRef}
					className={`header-account-menu shadow-large ${xyzClassName
						? xyzClassName
						: ''}`}
					xyz={animationDefaults.fadeInFast}
				>
					<div className="header-account-menu-content">
						<div className="profile-details">
							<div className="user-profile">
								{user.ProfilePic && (
									<div className="profile-pic">
										<img
											src={user.ProfilePic}
											alt="Profile Picture"
										/>
									</div>
								)}

								<div className="name">
									{user.FirstName} {user.LastName}
								</div>
							</div>

							<hr />

							{user.IsAdmin && (
								<Fragment>
									<Link
										to="/administration"
										onClick={hideMenu}
										className="btn btn-block text-left"
									>
										<i class="fas fa-sliders-h" />
										<span>Administration</span>
									</Link>
									{/* <a
										href="../AdminDashboard.aspx"
										onClick={hideMenu}
										className="btn btn-block text-left"
									>
										<i class="fas fa-sliders-h" />
										<span>Administration</span>
									</a> */}

									<div className="alert alert-secondary">
										Manage your users, subscriptions and
										invoices here
									</div>

									<hr />
								</Fragment>
							)}

							{/* <a
								onClick={toggleScheduleCallPanel}
								className="btn btn-block text-left"
							>
								<i className="fas fa-phone-alt" />
								<span>Talk to Us</span>
							</a> */}

							{/* <div className="alert alert-secondary">
								If you would like to schedule a call with one of our experts to help with getting your
								website up and running please click on the button above.
							</div> */}

							{/* <hr /> */}

							{/* <Link
								to="/feedback"
								onClick={hideMenu}
								className="btn btn-block text-left"
							>
								<i class="far fa-comment-alt" />
								<span>Give us Feedback</span>
							</Link> */}

							{brand &&
							brand.BrandSupportUrl &&
							brand.BrandSupportUrl !== '' && (
								<Fragment>
									<a
										href={brand.BrandSupportUrl}
										onClick={hideMenu}
										target="_blank"
										className="btn btn-block text-left"
									>
										<i className="fa-regular fa-circle-question" />
										<span>Support</span>
									</a>
								</Fragment>
							)}

							<hr />

							<Link
								to="/account"
								onClick={hideMenu}
								className="btn btn-block text-left"
							>
								<i className="far fa-user-circle" />
								<span>Account Settings</span>
							</Link>
							<Link
								to="/logout"
								onClick={hideMenu}
								className="btn btn-block text-left"
							>
								<i className="fas fa-sign-out-alt" />
								<span>Log out</span>
							</Link>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default HeaderAccountMenu;
