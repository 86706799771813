import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';
import './CartPurchaseSummary.css';
import CartPurchaseSummaryItem from './cartPurchaseSummaryItem/CartPurchaseSummaryItem';

const CartPurchaseSummary = () => {
	const [ { cartItemsResponse, user }, dispatch ] = useStateValue();
	const [ failedPurchases, setFailedPurchases ] = useState(false);
	const history = useHistory();

	// let cartItemsResponseT = [
	// 	{
	// 		DomainName   : 'testdomainname.com',
	// 		IsSuccessful : true
	// 	},
	// 	{
	// 		DomainName   : 'anotherdomain.net',
	// 		IsSuccessful : false,
	// 		Message      : 'Could not register this domain!'
	// 	}
	// ];

	useEffect(
		() => {
			if (!cartItemsResponse) {
				history.push('/cart');
			} else {
				for (let i = 0; i < cartItemsResponse.length; i++) {
					if (!cartItemsResponse[i].IsSuccessful) {
						setFailedPurchases(true);
						break;
					}
				}
			}
		},
		[ cartItemsResponse ]
	);

	const handleChatOpen = () => {
		if (window.LC_API) {
			if (user && user.Email) {
				let name = user.FirstName + ' ' + user.LastName;
				let email = user.Email.toLowerCase();

				window.LC_API.set_visitor_name(name);
				window.LC_API.set_visitor_email(email);
			}
			window.LC_API.open_chat_window();
		}
	};

	const handleContinue = () => {
		dispatch({
			type              : actionTypes.SET_CART_ITEMS_RESPONSE,
			cartItemsResponse : null
		});

		history.push('/');
	};

	return (
		<div className="app-cart-purchase-summary panel">
			<div className="container">
				<h5 className="rw-container-title">
					<i className="fas fa-shopping-bag" />
					<span>Cart Purchase Summary</span>
				</h5>

				<div className="rw-container">
					<div className="cart-purchase-summary-container">
						<div className="rw-table">
							<div className="rw-table-header">
								<div className="rw-th description">Description</div>
								<div className="rw-th status">Status</div>
							</div>

							{cartItemsResponse &&
							cartItemsResponse.length > 0 && (
								<Fragment>
									{cartItemsResponse.map((item) => (
										<CartPurchaseSummaryItem key={item.Id} item={item} />
									))}
								</Fragment>
							)}
						</div>

						{failedPurchases && (
							<div className="alert alert-warning text-center m-0">
								<span>
									It looks like some domains didn't register properly.{' '}
									<a onClick={handleChatOpen} className="app-link">
										Click here
									</a>{' '}
									to contact our support team for assistance.
								</span>
							</div>
						)}

						<div className="rw-container-buttons">
							<button type="button" className="btn btn-primary" onClick={handleContinue}>
								<i className="fas fa-chevron-right" />
								<span>Continue</span>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CartPurchaseSummary;
