import { Route, Switch } from 'react-router';
import ModalDialogFullScreen from '../../../modalDialog/ModalDialogFullScreen';
import WebsiteWizard from './WebsiteWizard';
import WebsiteWizardCompanyDetails from './websiteWizardCompanyDetails/WebsiteWizardCompanyDetails';
import WebsiteWizardPreconfigCreate from './websiteWizardPreconfigCreate/WebsiteWizardPreconfigCreate';
import WebsiteWizardPreconfigSelected from './websiteWizardPreconfigSelected/WebsiteWizardPreconfigSelected';

const WebsiteWizardModal = () => {
	return (
		<ModalDialogFullScreen>
			<div className="website-wizard-container panel pt-0 pb-0">
				<Switch>
					<Route path="/create-website/:preconfigId/create-website">
						<WebsiteWizardPreconfigCreate />
					</Route>
					<Route path="/create-website/:preconfigId/company-info">
						<WebsiteWizardCompanyDetails />
					</Route>
					<Route path="/create-website/:preconfigId/name">
						<WebsiteWizardPreconfigSelected />
					</Route>
					<Route path="/create-website">
						<WebsiteWizard />
					</Route>
				</Switch>
			</div>
		</ModalDialogFullScreen>
	);
};

export default WebsiteWizardModal;
