import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetAccountCards } from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import NoDataMessage from '../../../noDataMessage/NoDataMessage';
import AccountPaymentSource from './accountPaymentSource/AccountPaymentSource';
import './AccountPaymentSources.css';

const AccountPaymentSources = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ creditCards, setCreditCards ] = useState([]);

	useEffect(() => {
		getAccountCards();
	}, []);

	const getAccountCards = async () => {
		const response = await GetAccountCards();

		if (response.IsSuccessful) {
			setCreditCards(JSON.parse(response.Data));
		} else {
		}

		setIsLoading(false);
	};

	return (
		<Fragment>
			<div className="rw-container shadow-large payment-sources">
				{!isLoading ? (
					<Fragment>
						{creditCards && creditCards.length > 0 ? (
							<Fragment>
								{creditCards.map((cc) => (
									<AccountPaymentSource key={cc.Guid} card={cc} onUpdate={getAccountCards} />
								))}
							</Fragment>
						) : (
							<Fragment>
								<NoDataMessage />
							</Fragment>
						)}
						<div className="rw-container-buttons">
							<Link to="/account/add-credit-card" className="btn btn-primary">
								<i className="far fa-credit-card" />
								<span>Add New Card</span>
							</Link>
						</div>
					</Fragment>
				) : (
					<div className="text-center p-3">
						<Spinner />
					</div>
				)}
			</div>
		</Fragment>
	);
};

export default AccountPaymentSources;
