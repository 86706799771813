import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
	GetWebsitePreconfigIndustries,
	SaveWebsiteAsPreconfigured
} from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import ModalDialog from '../../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../../modalDialog/modalDialogContent/ModalDialogContent';
import SaveAsPreconfiguredIndustrySelect from './saveAsPreconfiguredIndustrySelect/SaveAsPreconfiguredIndustrySelect';

const SaveAsPreconfiguredWebsite = ({ website, closeDialog }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isValid, setIsValid ] = useState(false);
	const [ preconfiguredName, setPreconfiguredName ] = useState('');
	const [ preconfigIndustries, setPreconfigIndustries ] = useState();
	const [ selectedIndustries, setSelectedIndustries ] = useState();

	useEffect(() => {
		getIndustries();
	}, []);

	useEffect(
		() => {
			if (preconfiguredName && preconfiguredName.trim() !== '') {
				setIsValid(true);
			} else {
				setIsValid(false);
			}
		},
		[ preconfiguredName ]
	);

	const handleChange = (e) => {
		setPreconfiguredName(e.target.value);
	};

	const getIndustries = async () => {
		let data = {
			Guid : website.Guid
		};

		const response = await GetWebsitePreconfigIndustries(data);

		if (response.IsSuccessful) {
			let tempPreconfigIndustries = JSON.parse(response.Data);

			setPreconfigIndustries(tempPreconfigIndustries);
		} else {
			setPreconfigIndustries([]);
		}

		setIsLoading(false);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!isValid) {
			toast.error('Preconfigured name is required');
			return;
		}

		setIsSaving(true);

		let websiteData = {};
		websiteData.SiteId = website.Guid;
		websiteData.WebsiteName = preconfiguredName;
		websiteData.Industries = selectedIndustries;

		const response = await SaveWebsiteAsPreconfigured(websiteData);

		if (response.IsSuccessful) {
			toast.success(response.Message);
			closeDialog();
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const handleIndustrySelected = (list) => {
		if (list && list.length > 0) {
			let ids = list.map((item) => item.value);

			setSelectedIndustries(ids);
		} else {
			setSelectedIndustries(null);
		}
	};

	return (
		<Fragment>
			<ModalDialog>
				<form onSubmit={handleSubmit}>
					<ModalDialogContent align="left">
						<h5>Save as Preconfigured Website</h5>
						<p>
							Enter the preconfigured website name into the box
							below
						</p>
						<input
							type="text"
							name="preconfiguredName"
							placeholder="Preconfigured website name"
							className="form-control form-control-lg"
							value={preconfiguredName}
							onChange={handleChange}
							disabled={isSaving || isLoading}
						/>
						<div className="mt-3">
							<SaveAsPreconfiguredIndustrySelect
								industries={preconfigIndustries}
								onUpdate={handleIndustrySelected}
								isSaving={isLoading || isSaving}
							/>
						</div>
					</ModalDialogContent>
					<ModalDialogButtons align="left" shaded={true}>
						<button
							type="submit"
							className="btn btn-primary"
							disabled={isSaving || isLoading}
						>
							{!isSaving ? (
								<Fragment>
									<i class="fas fa-cloud-upload-alt" />
									<span>Save</span>
								</Fragment>
							) : (
								<Fragment>
									<Spinner />
									<span>Saving...</span>
								</Fragment>
							)}
						</button>
						<button
							type="button"
							className="btn btn-outline-primary"
							disabled={isSaving}
							onClick={closeDialog}
						>
							<i class="fas fa-window-close" />
							<span>Cancel</span>
						</button>
					</ModalDialogButtons>
				</form>
			</ModalDialog>
		</Fragment>
	);
};

export default SaveAsPreconfiguredWebsite;
