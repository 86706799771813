import { NavLink } from 'react-router-dom';
import './AdministrationNav.css';
import { useEffect, useState } from 'react';
import { GetBrandDomainBalance, GetBrandUsersLimit } from '../../../Services';
import { useStateValue } from '../../../StateProvider';
import { Fragment } from 'react';

const AdministrationNav = () => {
	const [ { brand }, dispatch ] = useStateValue();
	const [ resellerBalance, setResellerBalance ] = useState(-1);
	const [ brandLimits, setBrandLimits ] = useState();

	useEffect(() => {
		if (brand.Id === 1) {
			getResellerBalance();
		} else {
			getBrandLimits();
		}
	}, []);

	const getResellerBalance = async () => {
		// retrieves the current brand balance
		const response = await GetBrandDomainBalance();

		if (response.Success) {
			setResellerBalance(response.Data);
		} else {
			setResellerBalance(-1);
		}
	};

	const getBrandLimits = async () => {
		const response = await GetBrandUsersLimit();

		if (response.Success) {
			setBrandLimits(response.Data);
		} else {
			setBrandLimits();
		}
	};

	return (
		<div className="app-navbar shadow-large">
			<nav className="navbar">
				<NavLink
					to="/administration"
					exact={true}
					activeClassName="active"
					className="nav-item"
				>
					<i className="fas fa-user-friends" />
					<span>Users</span>
				</NavLink>
				<NavLink
					to="/administration/settings"
					activeClassName="active"
					className="nav-item"
				>
					<i className="fas fa-sliders-h" />
					<span>Settings</span>
				</NavLink>
				<NavLink
					to="/administration/webhooks"
					activeClassName="active"
					className="nav-item"
				>
					<i className="fa-solid fa-route" />
					<span>Webhooks</span>
				</NavLink>

				{brand.Id === 1 && (
					<Fragment>
						<NavLink
							to="/administration/email"
							activeClassName="active"
							className="nav-item"
						>
							<i className="fa-regular fa-envelope" />
							<span>Email</span>
						</NavLink>
						<NavLink
							to="/administration/sms"
							activeClassName="active"
							className="nav-item"
						>
							<i className="fa-regular fa-message" />
							<span>SMS</span>
						</NavLink>
						{resellerBalance > -1 && (
							<a
								href="https://godaddy.com"
								target="_blank"
								className="nav-item"
							>
								<i className="fa-regular fa-credit-card" />
								<span>
									Domains Balance: ${resellerBalance.toFixed(2)}
								</span>
							</a>
						)}
					</Fragment>
				)}

				{brandLimits && (
					<Fragment>
						{brandLimits.UsersLimit ? (
							<a href="javascript:void(0)" className="nav-item">
								<i className="fas fa-user-friends" />
								<span>
									{brandLimits.UsersCount} /{' '}
									{brandLimits.UsersLimit} Users
								</span>
							</a>
						) : (
							<a href="javascript:void(0)">
								<i className="fas fa-user-friends" />
								<span>
									{brandLimits.UsersCount} /{' '}
									<i class="fa-solid fa-infinity" /> Users
								</span>
							</a>
						)}
					</Fragment>
				)}
			</nav>
		</div>
	);
};

export default AdministrationNav;
