import { useState } from 'react';
import { useStateValue } from '../../../../StateProvider';
import HeaderAccountMenu from '../headerAccountMenu/HeaderAccountMenu';
import './ProfileInfo.css';

const ProfileInfo = () => {
	const [ { user }, dispatch ] = useStateValue();
	const [ headerMenuVisible, setHeaderMenuVisible ] = useState(false);
	const [ xyzClassName, setXyzClassName ] = useState('xyz-out');

	const toggleHeaderMenu = (e) => {
		e.preventDefault();

		if (headerMenuVisible) {
			hideHeaderMenu();
		} else {
			setXyzClassName('xyz-in');
			setHeaderMenuVisible(true);
		}
	};

	const hideHeaderMenu = () => {
		setXyzClassName('xyz-out');

		setTimeout(() => {
			setHeaderMenuVisible(false);
		}, 500);
	};

	return (
		<div className="profile-info-container">
			{user && (
				<div className="profile-info">
					{user.ProfilePic && (
						<div className="profile-pic">
							<img src={user.ProfilePic} alt="Profile Picture" />
						</div>
					)}

					<div className="profile-name">
						<div className="name">{user.FirstName}</div>
						{/* <div className="email">{user.Email}</div> */}
					</div>

					<a
						href="#"
						onClick={toggleHeaderMenu}
						className={`btn-profile-menu ${headerMenuVisible ? 'active' : ''}`}
					>
						<i class="fas fa-caret-down" />
					</a>

					{headerMenuVisible && <HeaderAccountMenu hideMenu={hideHeaderMenu} xyzClassName={xyzClassName} />}
				</div>
			)}
		</div>
	);
};

export default ProfileInfo;
