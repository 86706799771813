import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
	UpdateDomainRecord,
	DeleteDomainRecord
} from '../../../../../../Services';
import Spinner from '../../../../../../spinner/Spinner';
import ConfirmationDialog from '../../../../../confirmationDialog/ConfirmationDialog';
import './DomainRecord.css';

const DomainRecord = ({ domainRecord, onSave }) => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isDeleting, setIsDeleting ] = useState(false);
	const [ record, setRecord ] = useState(null);
	const [ isEditMode, setIsEditMode ] = useState(false);
	const [ showConfirmDelete, setShowConfirmDelete ] = useState(false);

	useEffect(
		() => {
			setRecord(domainRecord);
		},
		[ domainRecord ]
	);

	const toggleEditMode = () => {
		setIsEditMode((prev) => !prev);
	};

	const handleChange = (e) => {
		setRecord({ ...record, [e.target.name]: e.target.value });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let data = record;

		if (data.Value && data.Value !== '') {
			data.Value = data.Value.trim();
		}

		if (data.Host && data.Host !== '') {
			data.Host = data.Host.trim();
		}

		// cleanup the record before updating DB
		if (data.Type === 'A') {
			data.Priority = null;
			data.Service = null;
			data.Protocol = null;
			data.Weight = null;
			data.Port = null;
		} else if (data.Type === 'CNAME') {
			data.Priority = null;
			data.Service = null;
			data.Protocol = null;
			data.Weight = null;
			data.Port = null;
		} else if (data.Type === 'TXT') {
			// data.Host = '@';
			data.Priority = null;
			data.Service = null;
			data.Protocol = null;
			data.Weight = null;
			data.Port = null;
		} else if (data.Type === 'MX') {
			if (data.Host.trim() === '') data.Host = '@';
			data.Service = null;
			data.Protocol = null;
			data.Weight = null;
			data.Port = null;
		} else if (data.Type === 'SRV') {
			data.Host = '@';
		}

		const response = await UpdateDomainRecord(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (onSave) {
				onSave();
			}

			toggleEditMode();
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const toggleRecordDeleteConfirmation = () => {
		setShowConfirmDelete((prev) => !prev);
	};

	const handleDeleteRecord = async () => {
		setShowConfirmDelete(false);
		setIsEditMode(false);
		setIsSaving(true);
		setIsDeleting(true);

		const response = await DeleteDomainRecord(record);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (onSave) {
				onSave();
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
		setIsDeleting(false);
	};

	return (
		<Fragment>
			<div className="rw-row">
				<div className="rw-td type">{domainRecord.Type}</div>
				<div className="rw-td host">{domainRecord.DisplayHost}</div>
				<div className="rw-td value">{domainRecord.DisplayValue}</div>
				<div className="rw-td action">
					{record &&
					record.Type &&
					!(
						record.Type === 'A' &&
						(record.Host === '@' || record.Host === 'www')
					) && (
						<button
							type="button"
							className="btn btn-danger"
							disabled={isSaving || isDeleting}
							title="Delete this record"
							onClick={toggleRecordDeleteConfirmation}
						>
							{!isDeleting ? (
								<i className="far fa-trash-alt" />
							) : (
								<Spinner />
							)}
						</button>
					)}

					<button
						type="button"
						className="btn btn-primary"
						disabled={isSaving || isDeleting}
						onClick={toggleEditMode}
					>
						<i className="fas fa-pencil-alt" />
						<span>Edit</span>
					</button>
				</div>
			</div>

			{showConfirmDelete && (
				<ConfirmationDialog
					onConfirm={handleDeleteRecord}
					onCancel={toggleRecordDeleteConfirmation}
					confirmBtnClassName="danger"
				>
					<h5 className="rw-container-title">
						<span>Delete Confirmation</span>
					</h5>
					<span>
						Are you sure you want to delete this domain record?
					</span>
					<div className="alert alert-danger mt-2 mb-0">
						<span>
							Please keep in mind that this action is irreversible
						</span>
					</div>
				</ConfirmationDialog>
			)}

			{isEditMode && (
				<Fragment>
					<div className="edit-zone-record">
						{record.Type === 'A' &&
						(record.Host === '@' || record.Host === 'www') && (
							<div className="alert alert-warning text-center m-0">
								<strong>Be Careful!</strong> If you update this
								record to a value that doesn't match our system
								your website will stop functioning. Proceed only
								if you know what you're doing.
							</div>
						)}

						<div className="container-fluid">
							<div className="form-inputs">
								<form
									onSubmit={handleSubmit}
									autoComplete="off"
								>
									<div className="row">
										{(record.Type === 'A' ||
											record.Type === 'CNAME') && (
											<Fragment>
												<div className="col-sm-12 col-lg-6">
													<div className="form-group">
														<label htmlFor="host">
															Host
														</label>
														<input
															type="text"
															id="host"
															name="Host"
															className="form-control form-control-lg"
															disabled={isSaving}
															value={record.Host}
															onChange={
																handleChange
															}
														/>
													</div>
												</div>
												<div className="col-sm-12 col-lg-6">
													<div className="form-group">
														<label htmlFor="value">
															Value
														</label>
														<input
															type="text"
															id="value"
															name="Value"
															className="form-control form-control-lg"
															disabled={isSaving}
															value={record.Value}
															onChange={
																handleChange
															}
														/>
													</div>
												</div>
											</Fragment>
										)}

										{record.Type === 'TXT' && (
											<Fragment>
												<div className="col-sm-12 col-lg-6">
													<div className="form-group">
														<label htmlFor="host">
															Host
														</label>
														<input
															type="text"
															id="host"
															name="Host"
															className="form-control form-control-lg"
															disabled={isSaving}
															value={record.Host}
															onChange={
																handleChange
															}
														/>
													</div>
												</div>
												<div className="col-sm-12 col-lg-6">
													<div className="form-group">
														<label htmlFor="value">
															Value
														</label>
														<input
															type="text"
															id="value"
															name="Value"
															className="form-control form-control-lg"
															disabled={isSaving}
															value={record.Value}
															onChange={
																handleChange
															}
														/>
													</div>
												</div>
											</Fragment>
										)}

										{record.Type === 'MX' && (
											<Fragment>
												<div className="col-sm-12 col-lg-4">
													<div className="form-group">
														<label htmlFor="host">
															Host
														</label>
														<input
															type="text"
															id="host"
															name="Host"
															className="form-control form-control-lg"
															disabled={isSaving}
															value={record.Host}
															onChange={
																handleChange
															}
														/>
													</div>
												</div>
												<div className="col-sm-12 col-lg-4">
													<div className="form-group">
														<label htmlFor="value">
															Value
														</label>
														<input
															type="text"
															id="value"
															name="Value"
															className="form-control form-control-lg"
															disabled={isSaving}
															value={record.Value}
															onChange={
																handleChange
															}
														/>
													</div>
												</div>
												<div className="col-sm-12 col-lg-4">
													<div className="form-group">
														<label htmlFor="priority">
															Priority
														</label>
														<input
															type="number"
															id="priority"
															name="Priority"
															className="form-control form-control-lg"
															disabled={isSaving}
															value={
																record.Priority
															}
															onChange={
																handleChange
															}
														/>
													</div>
												</div>
											</Fragment>
										)}

										{record.Type === 'SRV' && (
											<Fragment>
												<div className="col-sm-12 col-lg-4">
													<div className="form-group">
														<label htmlFor="value">
															Target
														</label>
														<input
															type="text"
															id="value"
															name="Value"
															className="form-control form-control-lg"
															disabled={isSaving}
															value={record.Value}
															onChange={
																handleChange
															}
														/>
													</div>
												</div>
												<div className="col-sm-12 col-lg-4">
													<div className="form-group">
														<label htmlFor="protocol">
															Protocol
														</label>
														<input
															type="text"
															id="protocol"
															name="Protocol"
															className="form-control form-control-lg"
															disabled={isSaving}
															value={
																record.Protocol
															}
															onChange={
																handleChange
															}
														/>
													</div>
												</div>
												<div className="col-sm-12 col-lg-4">
													<div className="form-group">
														<label htmlFor="service">
															Service
														</label>
														<input
															type="text"
															id="service"
															name="Service"
															className="form-control form-control-lg"
															disabled={isSaving}
															value={
																record.Service
															}
															onChange={
																handleChange
															}
														/>
													</div>
												</div>
												<div className="col-sm-12 col-lg-4">
													<div className="form-group">
														<label htmlFor="priority">
															Priority
														</label>
														<input
															type="number"
															id="priority"
															name="Priority"
															className="form-control form-control-lg"
															disabled={isSaving}
															value={
																record.Priority
															}
															onChange={
																handleChange
															}
														/>
													</div>
												</div>
												<div className="col-sm-12 col-lg-4">
													<div className="form-group">
														<label htmlFor="weight">
															Weight
														</label>
														<input
															type="number"
															id="weight"
															name="Weight"
															className="form-control form-control-lg"
															disabled={isSaving}
															value={
																record.Weight
															}
															onChange={
																handleChange
															}
														/>
													</div>
												</div>
												<div className="col-sm-12 col-lg-4">
													<div className="form-group">
														<label htmlFor="port">
															Port
														</label>
														<input
															type="number"
															id="port"
															name="Port"
															className="form-control form-control-lg"
															disabled={isSaving}
															value={record.Port}
															onChange={
																handleChange
															}
														/>
													</div>
												</div>
											</Fragment>
										)}
									</div>

									<div className="buttons-container">
										<button
											type="submit"
											className="btn btn-primary"
											disabled={isSaving}
										>
											{!isSaving ? (
												<Fragment>
													<i className="fas fa-save" />
													<span>Save</span>
												</Fragment>
											) : (
												<Fragment>
													<Spinner />
													<span>
														Saving Record...
													</span>
												</Fragment>
											)}
										</button>

										<button
											type="button"
											className="btn btn-outline-primary"
											disabled={isSaving}
											onClick={toggleEditMode}
										>
											<i className="fas fa-chevron-left" />
											<span>Cancel</span>
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default DomainRecord;
