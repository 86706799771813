import { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import CalendlyAuthorize from './calendlyAuthorize/CalendlyAuthorize';
import CalendlyCallback from './calendlyCallback/CalendlyCallback';

const CalendlyOAuth = () => {
	return (
		<Fragment>
			<Switch>
				<Route path="/oauth/calendly/callback">
					<CalendlyCallback />
				</Route>
				<Route path="/oauth/calendly/authorize">
					<CalendlyAuthorize />
				</Route>
			</Switch>
		</Fragment>
	);
};

export default CalendlyOAuth;
