import { useEffect, useState } from 'react';

const MakeItLiveDomainSuggestion = ({ domain, selectedDomain, onSelect }) => {
	const [ activeClass, setActiveClass ] = useState('');

	useEffect(
		() => {
			if (selectedDomain && selectedDomain === domain) {
				setActiveClass('active');
			} else {
				setActiveClass('');
			}
		},
		[ selectedDomain ]
	);

	const handleClick = () => {
		if (onSelect) {
			onSelect(domain);
		}
	};

	return (
		<div className={`domain-suggestion ${activeClass}`} onClick={handleClick}>
			<span>{domain.DomainName}</span>
		</div>
	);
};

export default MakeItLiveDomainSuggestion;
