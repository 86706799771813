import { Fragment, useEffect, useState } from 'react';
import { GetBillingHistoryAdmin } from '../../../../../Services';
import Spinner from '../../../../../spinner/Spinner';
import { useStateValue } from '../../../../../StateProvider';
import CenteredContainer from '../../../../centeredContainer/CenteredContainer';
import NoDataMessage from '../../../../noDataMessage/NoDataMessage';
import EditUserBillingHistoryItem from './billingHistoryItem/EditUserBillingHistoryItem';
import './EditUserBillingHistory.css';

const EditUserBillingHistory = ({ userDetails }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ { user }, dispatch ] = useStateValue();
	const [ billingHistory, setBillingHistory ] = useState([]);

	useEffect(() => {
		getBillingHistory();
	}, []);

	const getBillingHistory = async () => {
		let data = {
			UserGuid : userDetails.Guid
		};

		const response = await GetBillingHistoryAdmin(data);

		if (response.IsSuccessful) {
			if (response.Data) {
				let tempData = JSON.parse(response.Data);
				let filteredData = new Array();
				let invoiceIds = new Array();

				// console.log('response data:', tempData);

				if (tempData.data && tempData.data.length > 0) {
					for (let i = 0; i < tempData.data.length; i++) {
						let record = tempData.data[i];

						// let's only grab the last invoice payment instead of all of them
						// so it shows as a single line in the history instead of multiple lines
						// (in case of a couple of failed payments for example)
						if (
							!record.InvoiceId ||
							(record.InvoiceId &&
								invoiceIds.indexOf(record.InvoiceId) === -1)
						) {
							if (record.InvoiceId) {
								invoiceIds.push(record.InvoiceId);
							}

							filteredData.push(record);
						}
					}

					setBillingHistory(filteredData);
				}
			}
		}

		setIsLoading(false);
	};

	useEffect(
		() => {
			// console.log('billing history set');
			// console.log(userDetails, billingHistory);
		},
		[ billingHistory ]
	);

	return (
		<div className="rw-container shadow-large acc-billing">
			{!isLoading ? (
				<Fragment>
					{userDetails &&
					userDetails.CreditsBalance > 0 && (
						<div className="rw-container-buttons">
							<span className="credits-balance">
								<span>Current Balance:</span>{' '}
								<strong>
									${userDetails.CreditsBalance.toFixed(2)}
								</strong>
							</span>
						</div>
					)}

					{billingHistory && billingHistory.length > 0 ? (
						<Fragment>
							<div className="acc-billing-history">
								<div className="rw-table">
									<div className="rw-table-header">
										<div className="rw-th date">Date</div>
										<div className="rw-th card-id">
											Card
										</div>
										<div className="rw-th description">
											Description
										</div>
										<div className="rw-th status">
											Status
										</div>
										<div className="rw-th amount">
											Amount
										</div>

										{user.IsAdmin && (
											<Fragment>
												<div className="rw-th action" />
											</Fragment>
										)}
									</div>

									{billingHistory.map((billing) => (
										<EditUserBillingHistoryItem
											item={billing}
											onUpdate={getBillingHistory}
											userDetails={userDetails}
										/>
									))}
								</div>
							</div>
						</Fragment>
					) : (
						<NoDataMessage />
					)}
				</Fragment>
			) : (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			)}
		</div>
	);
};

export default EditUserBillingHistory;
