import { useHistory, useParams } from 'react-router-dom';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';

const MakeItLiveAutoRenew = () => {
	const [ { user, makeItLiveSettings }, dispatch ] = useStateValue();
	const { guid } = useParams();
	const history = useHistory();

	const handleYes = () => {
		let tempSettings = makeItLiveSettings;
		tempSettings.IsAutoRenew = true;

		dispatch({
			type               : actionTypes.SET_MAKEITLIVE_SETTINGS,
			makeItLiveSettings : { ...tempSettings }
		});

		if (user.FreeDomains && user.FreeDomains > 0 && !makeItLiveSettings.IsPrivateRegistration) {
			history.push(`/make-it-live/${guid}/summary`);
		} else {
			history.push(`/make-it-live/${guid}/select-card`);
		}
	};

	const handleNo = () => {
		let tempSettings = makeItLiveSettings;
		tempSettings.IsAutoRenew = false;

		dispatch({
			type               : actionTypes.SET_MAKEITLIVE_SETTINGS,
			makeItLiveSettings : { ...tempSettings }
		});

		if (user.FreeDomains && user.FreeDomains > 0 && !makeItLiveSettings.IsPrivateRegistration) {
			history.push(`/make-it-live/${guid}/summary`);
		} else {
			history.push(`/make-it-live/${guid}/select-card`);
		}
	};

	return (
		<div className="wizard-auto-renew wizard-step">
			<h3>Would you like to auto renew your domain?</h3>
			<p className="text-muted">
				Protect your investment by making sure you don't lose your domain by forgetting to renew it before it
				expires. Strongly recommended.
			</p>

			<div className="buttons-container">
				<button type="button" className="btn btn-primary" onClick={handleYes}>
					<i className="fas fa-check" />
					<span>Yes, Auto Renew My Domain</span>
				</button>

				<button type="button" className="btn btn-outline-primary" onClick={handleNo}>
					<i className="fas fa-times" />
					<span>No, Thank You</span>
				</button>
			</div>
		</div>
	);
};

export default MakeItLiveAutoRenew;
