import { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { actionTypes } from '../../../../reducer';
import { CancelSubscription } from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import { useStateValue } from '../../../../StateProvider';
import ModalDialog from '../../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../../modalDialog/modalDialogContent/ModalDialogContent';
import './AccountCancel.css';

const AccountCancel = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ { user }, dispatch ] = useStateValue();
	const [ showAdditionalFeedback, setShowAdditionalFeedback ] = useState(false);
	const [ cancelReason, setCancelReason ] = useState('');
	const [ additionalFeedback, setAdditionalFeedback ] = useState('');
	const [ showCancelConfirmation, setShowCancelConfirmation ] = useState(false);
	const [ confirmCancellation, setConfirmCancellation ] = useState('');
	const history = useHistory();

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.value;

		if (target === 'cancelReason') {
			setCancelReason(value);

			if (value === 'Other') {
				setShowAdditionalFeedback(true);
			} else {
				setShowAdditionalFeedback(false);
			}
		} else if (target === 'additionalFeedback') {
			setAdditionalFeedback(value);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (cancelReason === '') {
			toast.error('Cancel reason is required to proceed');
			return;
		}

		if (cancelReason === 'Other' && additionalFeedback.trim() === '') {
			toast.error('Please enter a cancel reason into the additional feedback box');
			return;
		}

		setShowCancelConfirmation(true);
	};

	const handleConfirmChange = (e) => {
		const value = e.target.value;

		setConfirmCancellation(value);
	};

	const handleConfirmSubmit = async (e) => {
		e.preventDefault();

		if (confirmCancellation.toLowerCase() !== 'cancel') {
			toast.error('Enter the word CANCEL into the box to confirm subscription cancellation');
			return;
		}

		let data = {
			CancelReason       : cancelReason,
			AdditionalFeedback : additionalFeedback
		};

		setIsLoading(true);

		const response = await CancelSubscription(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			dispatch({
				type : actionTypes.SET_USER,
				user : {
					...user,
					Status : 'Canceled'
				}
			});

			history.push('/account');
		} else {
			toast.error(response.Message);
		}

		setIsLoading(false);
	};

	return (
		<div className="app-account-cancel panel">
			<div className="container">
				<h5 className="rw-container-title">
					<i class="fas fa-power-off" />
					<span>Cancel Subscription</span>
				</h5>

				<div className="rw-container shadow-large cancel-reason">
					<form onSubmit={handleSubmit}>
						<div className="form-inputs">
							<h5>Please tell us why are you considering cancellation:</h5>
							<div className="form-check">
								<input
									type="radio"
									className="form-check-input"
									name="cancelReason"
									id="optCancelTooExpensive"
									value="Too Expensive"
									checked={cancelReason === 'Too Expensive'}
									onChange={handleChange}
									disabled={isLoading}
								/>
								<label className="form-check-label" for="optCancelTooExpensive">
									It's too expensive
								</label>
							</div>

							<div className="form-check">
								<input
									type="radio"
									className="form-check-input"
									name="cancelReason"
									id="optCancelNotWhatINeed"
									value="Not What I Need"
									checked={cancelReason === 'Not What I Need'}
									onChange={handleChange}
									disabled={isLoading}
								/>
								<label className="form-check-label" for="optCancelNotWhatINeed">
									It's not what I need
								</label>
							</div>

							<div className="form-check">
								<input
									type="radio"
									className="form-check-input"
									name="cancelReason"
									id="optCancelNotWorkingProperly"
									value="Not Working Properly"
									checked={cancelReason === 'Not Working Properly'}
									onChange={handleChange}
									disabled={isLoading}
								/>
								<label className="form-check-label" for="optCancelNotWorkingProperly">
									It's not working properly
								</label>
							</div>

							<div className="form-check">
								<input
									type="radio"
									className="form-check-input"
									name="cancelReason"
									id="optCancelMovingToDifferentProvider"
									value="Moving To Different Provider"
									checked={cancelReason === 'Moving To Different Provider'}
									onChange={handleChange}
									disabled={isLoading}
								/>
								<label className="form-check-label" for="optCancelMovingToDifferentProvider">
									I'm moving to another service provider
								</label>
							</div>

							<div className="form-check">
								<input
									type="radio"
									className="form-check-input"
									name="cancelReason"
									id="optCancelTooDifficult"
									value="Too Difficult To Setup"
									checked={cancelReason === 'Too Difficult To Setup'}
									onChange={handleChange}
									disabled={isLoading}
								/>
								<label className="form-check-label" for="optCancelTooDifficult">
									It's too difficult to set up and manage
								</label>
							</div>

							<div className="form-check">
								<input
									type="radio"
									className="form-check-input"
									name="cancelReason"
									id="optCancelOther"
									value="Other"
									checked={cancelReason === 'Other'}
									onChange={handleChange}
									disabled={isLoading}
								/>
								<label className="form-check-label" for="optCancelOther">
									Other
								</label>
							</div>

							{showAdditionalFeedback && (
								<Fragment>
									<h5 className="mt-3">Additional Feedback:</h5>
									<div className="form-group mb-0">
										<textarea
											className="form-control form-control-lg"
											name="additionalFeedback"
											rows={3}
											onChange={handleChange}
											disabled={isLoading}
										/>
									</div>
								</Fragment>
							)}
						</div>
						<div className="rw-container-buttons">
							<button type="submit" className="btn btn-danger" disabled={isLoading}>
								{!isLoading ? (
									<Fragment>
										<i class="fas fa-power-off" />
										<span>Cancel Subscription</span>
									</Fragment>
								) : (
									<Fragment>
										<Spinner />
										<span>Cancelling...</span>
									</Fragment>
								)}
							</button>

							<button
								type="button"
								className="btn btn-outline-primary"
								disabled={isLoading}
								onClick={() => history.push('/account')}
							>
								<i className="fas fa-chevron-left" />
								<span>Back to Account Details</span>
							</button>
						</div>
					</form>
				</div>
			</div>

			{showCancelConfirmation && (
				<Fragment>
					<ModalDialog>
						<form onSubmit={handleConfirmSubmit} autoComplete="off">
							<ModalDialogContent align="left">
								<h5>Confirm Subscription Cancellation</h5>
								<p>
									Type in the word CANCEL into the box below to confirm your subscription
									cancellation.
								</p>

								<input
									type="text"
									className="form-control form-control-lg"
									name="confirmCancellation"
									placeholder="Type CANCEL to confirm"
									value={confirmCancellation}
									disabled={isLoading}
									onChange={handleConfirmChange}
								/>
							</ModalDialogContent>
							<ModalDialogButtons shaded>
								<button type="submit" className="btn btn-danger" disabled={isLoading}>
									{!isLoading ? (
										<Fragment>
											<i class="fas fa-power-off" />
											<span>Cancel Subscription</span>
										</Fragment>
									) : (
										<Fragment>
											<Spinner />
											<span>Cancelling...</span>
										</Fragment>
									)}
								</button>

								<button
									type="button"
									className="btn btn-outline-primary"
									disabled={isLoading}
									onClick={() => setShowCancelConfirmation(false)}
								>
									<i className="fas fa-chevron-left" />
									<span>Back</span>
								</button>
							</ModalDialogButtons>
						</form>
					</ModalDialog>
				</Fragment>
			)}
		</div>
	);
};

export default AccountCancel;
