import { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
	CreateInvoiceAdmin,
	GetInvoiceProducts,
	GetUserDetailsAdmin,
	UpdateInvoiceAdmin,
	GetUserInvoice
} from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import CenteredContainer from '../../../centeredContainer/CenteredContainer';
import NoDataMessage from '../../../noDataMessage/NoDataMessage';
import './CreateInvoice.css';
import CreateInvoiceItem from './createInvoiceItem/CreateInvoiceItem';
import { v4 as uuidv4 } from 'uuid';
import ActionsBar from '../../../actionsBar/ActionsBar';

const CreateInvoice = () => {
	const { userGuid, invoiceGuid } = useParams();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ userDetails, setUserDetails ] = useState(null);
	const [ invoiceDetails, setInvoiceDetails ] = useState(null);
	const [ isInvoicePaid, setIsInvoicePaid ] = useState(false);
	const [ invoiceProducts, setInvoiceProducts ] = useState([]);
	const [ invoiceItems, setInvoiceItems ] = useState([]);
	const [ invoiceName, setInvoiceName ] = useState('');
	const [ discount, setDiscount ] = useState(0);
	const [ subtotal, setSubtotal ] = useState(0);
	const [ total, setTotal ] = useState(0);
	const [ showSubscriptionDetails, setShowSubscriptionDetails ] = useState(
		false
	);
	const [ subscriptionDetails, setSubscriptionDetails ] = useState(null);
	const history = useHistory();
	const emptyItem = {
		name        : '',
		description : '',
		price       : 0
	};

	const subscriptionPresets = [
		{
			name           : 'Monthly',
			trial_days     : 30,
			amount         : 27,
			recurring_days : 30,
			tagToApply     : 'Monthly'
		},
		{
			name           : 'Annual',
			trial_days     : 365,
			amount         : 197,
			recurring_days : 365,
			tagToApply     : 'Annual'
		},
		{
			name           : 'Annual Monthly',
			trial_days     : 365,
			amount         : 27,
			recurring_days : 30,
			tagToApply     : 'Annual Monthly'
		},
		{
			name           : 'Quarterly',
			trial_days     : 91,
			amount         : 79,
			recurring_days : 91,
			tagToApply     : 'Quarterly'
		}
	];

	useEffect(
		() => {
			if (userGuid || invoiceGuid) {
				// getUserDetails();
				getInvoiceProducts();

				if (invoiceGuid) {
					getInvoiceDetails();
				}

				setInvoiceItems([ { ...emptyItem, id: uuidv4() } ]);
			}
		},
		[ userGuid, invoiceGuid ]
	);

	useEffect(
		() => {
			if ((userGuid || invoiceGuid) && invoiceProducts.length > 0) {
				if (!invoiceGuid || (invoiceGuid && invoiceDetails)) {
					setIsLoading(false);
				}
			}
		},
		[ userGuid, invoiceGuid, invoiceDetails, invoiceProducts ]
	);

	const getUserDetails = async () => {
		let data = {
			UserGuid : userGuid
		};

		const response = await GetUserDetailsAdmin(data);

		if (response.IsSuccessful) {
			setUserDetails(JSON.parse(response.Data));
		} else {
			toast.error(response.Message);
			history.goBack();
		}
	};

	const getInvoiceDetails = async () => {
		let data = {
			Id : invoiceGuid
		};

		const response = await GetUserInvoice(data);

		if (response.IsSuccessful) {
			const tempInvoiceDetails = JSON.parse(response.Data);

			setInvoiceName(tempInvoiceDetails.InvoiceTitle);
			setDiscount(tempInvoiceDetails.Discount);

			const invoiceItemsAndSubscriptionDetails = JSON.parse(
				tempInvoiceDetails.InvoiceItemsJson
			);

			if (Array.isArray(invoiceItemsAndSubscriptionDetails)) {
				// this is a legacy invoice that did not have support for subscriptions

				for (
					let i = 0;
					i < invoiceItemsAndSubscriptionDetails.length;
					i++
				) {
					invoiceItemsAndSubscriptionDetails[i] = {
						name  : invoiceItemsAndSubscriptionDetails[i].Name,
						price : invoiceItemsAndSubscriptionDetails[i].Price
					};
				}

				setInvoiceItems(invoiceItemsAndSubscriptionDetails);
			} else {
				if (invoiceItemsAndSubscriptionDetails.InvoiceItems) {
					setInvoiceItems(
						invoiceItemsAndSubscriptionDetails.InvoiceItems
					);
				}

				if (invoiceItemsAndSubscriptionDetails.HasSubscription) {
					setShowSubscriptionDetails(
						invoiceItemsAndSubscriptionDetails.HasSubscription
					);
				}

				if (invoiceItemsAndSubscriptionDetails.SubscriptionDetails) {
					setSubscriptionDetails(
						invoiceItemsAndSubscriptionDetails.SubscriptionDetails
					);
				}
			}

			setInvoiceDetails(tempInvoiceDetails);
			setIsInvoicePaid(tempInvoiceDetails.IsPaid);
		} else {
			toast.error(response.Message);
			history.goBack();
		}
	};

	const getInvoiceProducts = async () => {
		const productsJson = await GetInvoiceProducts();

		// console.log('products:', productsJson, productsJson.products);

		if (productsJson && Array.isArray(productsJson.products)) {
			productsJson.products.splice(0, 0, {
				name        : '',
				description : '',
				price       : 0
			});

			setInvoiceProducts(productsJson.products);
		} else {
			toast.error('Could not load the products list');
			history.goBack();
		}
	};

	const handleItemUpdate = (oldItem, newItem) => {
		//
		// console.log('handle change:', oldItem, newItem);

		let itemIndex = invoiceItems.findIndex((x) => x === oldItem);

		if (itemIndex > -1) {
			let tempArray = invoiceItems.slice();

			tempArray.splice(itemIndex, 1, newItem);

			setInvoiceItems(tempArray);
		}
	};

	const handleAddItem = () => {
		let tempArray = invoiceItems.slice();

		tempArray.push({ ...emptyItem, id: uuidv4() });

		setInvoiceItems(tempArray);
	};

	const handleDeleteItem = (item) => {
		let itemIndex = invoiceItems.findIndex((x) => x === item);

		if (itemIndex > -1) {
			let tempArray = invoiceItems.slice();

			tempArray.splice(itemIndex, 1);

			setInvoiceItems(tempArray);
		}
	};

	useEffect(
		() => {
			if (invoiceItems && invoiceItems.length > 0) {
				let tempSubtotal = 0;
				let tempTotal = 0;
				let tempDiscount = discount;

				for (let i = 0; i < invoiceItems.length; i++) {
					let invoiceItem = invoiceItems[i];

					if (invoiceItem.price && invoiceItem.price > 0) {
						tempSubtotal += invoiceItem.price;
					}
				}

				if (tempSubtotal > 0) {
					if (tempDiscount > 0) {
						tempTotal =
							tempSubtotal - tempSubtotal / 100 * tempDiscount;
					} else {
						tempTotal = tempSubtotal;
					}
				}

				setSubtotal(tempSubtotal.toFixed(2));
				setTotal(tempTotal);
			} else {
				setSubtotal(0);
				setTotal(0);
			}
		},
		[ invoiceItems, discount ]
	);

	// once attach subscription checkbox is toggled we need to
	// set predefined values for the subscription
	useEffect(
		() => {
			if (!isLoading) {
				if (showSubscriptionDetails) {
					setSubscriptionDetails({
						trial_days     : 30,
						amount         : 27,
						recurring_days : 30,
						tagToApply     : 'Monthly'
					});
				} else {
					setSubscriptionDetails(null);
				}
			}
		},
		[ showSubscriptionDetails ]
	);

	const handleSubscriptionChange = (e) => {
		setSubscriptionDetails({
			...subscriptionDetails,
			[e.target.name]: e.target.value
		});
	};

	const handlePresetSelect = (preset) => {
		if (!isSaving && !isInvoicePaid) {
			setSubscriptionDetails({
				...subscriptionDetails,
				...preset
			});
		}
	};

	const handleSubmit = async () => {
		if (!invoiceItems || invoiceItems.length === 0) {
			toast.error('At least a single invoice item is required');
			return;
		}

		let tempInvoiceItems = invoiceItems.filter(
			(x) => x.name && x.name !== ''
		);

		if (!tempInvoiceItems || tempInvoiceItems.length === 0) {
			toast.error('At least a single invoice item is required');
			return;
		}

		setIsSaving(true);

		let invoiceData = {
			InvoiceItems        : tempInvoiceItems,
			HasSubscription     : showSubscriptionDetails,
			SubscriptionDetails : subscriptionDetails
		};

		let data = {
			UserId        : userGuid,
			ItemsJson     : JSON.stringify(invoiceData),
			InvoiceAmount : total,
			Discount      : discount,
			Description   : invoiceName
		};

		if (invoiceGuid) {
			data.Id = invoiceGuid;
			data.UserId = invoiceDetails.UserGuid;
		}

		console.log(data);

		if (userGuid) {
			const response = await CreateInvoiceAdmin(data);

			if (response.IsSuccessful) {
				toast.success(response.Message);

				// redirect to the newly created invoice for editing
				history.push(`/administration/invoices/edit/${response.Data}`);
			} else {
				toast.error(response.Message);
			}
		} else if (invoiceGuid) {
			const response = await UpdateInvoiceAdmin(data);

			if (response.IsSuccessful) {
				toast.success(response.Message);
			} else {
				toast.error(response.Message);
			}
		} else {
			toast.error('Invalid request');
		}

		setIsSaving(false);
	};

	const handleBackButton = () => {
		if (userGuid) {
			history.push(`/administration/edit/${userGuid}`);
		} else if (invoiceDetails) {
			history.push(`/administration/edit/${invoiceDetails.UserGuid}`);
		}
	};

	return (
		<Fragment>
			{!isLoading ? (
				<Fragment>
					<div className="app-create-invoice">
						<div className="container">
							<ActionsBar>
								<button
									type="button"
									className="btn btn-outline-primary"
									onClick={handleBackButton}
									disabled={isSaving}
								>
									<i className="fas fa-chevron-left" />
									<span>Back to User Details</span>
								</button>
							</ActionsBar>

							<h5 className="rw-container-title">
								<i className="fas fa-receipt" />
								<span>Invoice Description (Name)</span>
							</h5>

							<textarea
								className="form-control form-control-lg"
								rows={3}
								placeholder="Leave empty to use a default invoice name"
								value={invoiceName}
								onChange={(e) => setInvoiceName(e.target.value)}
								disabled={isInvoicePaid}
							/>

							<h5 className="rw-container-title">
								<i className="fas fa-receipt" />
								<span>Invoice Items</span>
							</h5>

							<div className="rw-container shadow-large invoice-items">
								{invoiceItems && invoiceItems.length > 0 ? (
									<Fragment>
										<div className="form-inputs">
											<div className="invoice-item-container invoice-item-header">
												<div className="invoice-item-details">
													<div className="invoice-item">
														<strong>Item</strong>
													</div>
													<div className="invoice-item-price">
														<strong>Price</strong>
													</div>
													<div className="invoice-item-action" />
												</div>
											</div>

											{invoiceItems.map((item) => (
												<CreateInvoiceItem
													key={item.id}
													item={item}
													products={invoiceProducts}
													onUpdate={handleItemUpdate}
													onDelete={handleDeleteItem}
													isDisabled={
														isSaving ||
														isInvoicePaid
													}
												/>
											))}
										</div>

										<div className="total-line-item">
											<div className="total-line-item-details">
												<strong>Subtotal:</strong>

												<span>${subtotal}</span>
											</div>
										</div>
										<div className="total-line-item">
											<div className="total-line-item-details">
												<strong>Discount:</strong>

												<div className="input-group">
													<input
														type="number"
														className="form-control"
														value={discount}
														onChange={(e) =>
															setDiscount(
																parseFloat(
																	e.target
																		.value
																)
															)}
														disabled={
															isInvoicePaid ||
															isSaving
														}
													/>
													<div class="input-group-append">
														<div class="input-group-text">
															%
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="total-line-item">
											<div className="total-line-item-details">
												<strong>Total:</strong>

												<span>${total.toFixed(2)}</span>
											</div>
										</div>
									</Fragment>
								) : (
									<Fragment>
										<NoDataMessage message="No items" />
									</Fragment>
								)}

								<div className="rw-container-buttons">
									<button
										type="button"
										className="btn btn-outline-primary"
										onClick={handleAddItem}
										disabled={isInvoicePaid || isSaving}
									>
										<i className="fas fa-plus-circle" />
										<span>Add New Item</span>
									</button>
								</div>
							</div>

							<div className="attach-subscription-toggle mt-4">
								<input
									id="chkAttachSubscription"
									type="checkbox"
									className="rw-checkbox-switch"
									defaultChecked={showSubscriptionDetails}
									onChange={() =>
										setShowSubscriptionDetails(
											(prev) => !prev
										)}
									disabled={isInvoicePaid || isSaving}
								/>
								<label htmlFor="chkAttachSubscription">
									Attach subscription to this invoice
								</label>
							</div>

							{showSubscriptionDetails && (
								<Fragment>
									<div className="rw-container shadow-large subscription-details">
										<div className="presets-container">
											<div>
												<strong>Presets:</strong>

												{subscriptionPresets.map(
													(preset) => (
														<a
															className="preset"
															onClick={() =>
																handlePresetSelect(
																	preset
																)}
														>
															{preset.name}
														</a>
													)
												)}
											</div>
										</div>

										{subscriptionDetails && (
											<Fragment>
												<div className="container-fluid">
													<div className="row">
														<div className="col-lg-4 col-sm-12">
															<label htmlFor="trialDays">
																Trial Days:
															</label>
															<div className="input-group">
																<input
																	id="trialDays"
																	name="trial_days"
																	type="number"
																	className="form-control"
																	value={
																		subscriptionDetails.trial_days
																	}
																	onChange={
																		handleSubscriptionChange
																	}
																	disabled={
																		isInvoicePaid ||
																		isSaving
																	}
																/>
																<div
																	class="input-group-append"
																	title="The number of days before we first bill the customer for this subscription"
																>
																	<div class="input-group-text">
																		<i className="fas fa-question" />
																	</div>
																</div>
															</div>
														</div>

														<div className="col-lg-4 col-sm-12">
															<label htmlFor="amount">
																Amount:
															</label>
															<div className="input-group">
																<div class="input-group-prepend">
																	<div class="input-group-text">
																		$
																	</div>
																</div>
																<input
																	id="amount"
																	name="amount"
																	type="number"
																	className="form-control"
																	value={
																		subscriptionDetails.amount
																	}
																	onChange={
																		handleSubscriptionChange
																	}
																	disabled={
																		isInvoicePaid ||
																		isSaving
																	}
																/>
																<div
																	class="input-group-append"
																	title="The amount to bill for during this subscription"
																>
																	<div class="input-group-text">
																		<i className="fas fa-question" />
																	</div>
																</div>
															</div>
														</div>

														<div className="col-lg-4 col-sm-12">
															<label htmlFor="recurringDays">
																Recurring Days:
															</label>
															<div className="input-group">
																<input
																	id="recurringDays"
																	name="recurring_days"
																	type="number"
																	className="form-control"
																	value={
																		subscriptionDetails.recurring_days
																	}
																	onChange={
																		handleSubscriptionChange
																	}
																	disabled={
																		isInvoicePaid ||
																		isSaving
																	}
																/>
																<div
																	class="input-group-append"
																	title="The number of days between each subscription payment"
																>
																	<div class="input-group-text">
																		<i className="fas fa-question" />
																	</div>
																</div>
															</div>
														</div>
													</div>

													<div className="row mt-3">
														<div className="col-sm-12">
															<label htmlFor="tagToApply">
																Tag to Apply:
															</label>
															<div className="input-group">
																<input
																	id="tagToApply"
																	name="tagToApply"
																	type="text"
																	className="form-control"
																	value={
																		subscriptionDetails.tagToApply
																	}
																	onChange={
																		handleSubscriptionChange
																	}
																	disabled={
																		isInvoicePaid ||
																		isSaving
																	}
																/>
																<div
																	class="input-group-append"
																	title="Enter a tag you wish to be applied to the customer account when this invoice is paid for"
																>
																	<div class="input-group-text">
																		<i className="fas fa-question" />
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</Fragment>
										)}
									</div>
								</Fragment>
							)}

							<div className="text-center mt-4">
								{!invoiceGuid ||
								(invoiceDetails && !invoiceDetails.IsPaid) ? (
									<Fragment>
										<button
											type="button"
											className="btn btn-primary btn-lg"
											onClick={handleSubmit}
											disabled={isLoading || isSaving}
										>
											{!isSaving ? (
												<Fragment>
													<i className="fas fa-receipt" />
													{!invoiceGuid ? (
														<Fragment>
															<span>
																Create Invoice
															</span>
														</Fragment>
													) : (
														<Fragment>
															<span>
																Update Invoice
															</span>
														</Fragment>
													)}
												</Fragment>
											) : (
												<Fragment>
													<Spinner />
													{!invoiceGuid ? (
														<Fragment>
															<span>
																Creating
																Invoice...
															</span>
														</Fragment>
													) : (
														<Fragment>
															<span>
																Updating
																Invoice...
															</span>
														</Fragment>
													)}
												</Fragment>
											)}
										</button>
									</Fragment>
								) : (
									<Fragment>
										<div className="alert alert-success text-center">
											<span>
												This invoice has been paid
											</span>
										</div>
									</Fragment>
								)}
							</div>
						</div>
					</div>
				</Fragment>
			) : (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			)}
		</Fragment>
	);
};

export default CreateInvoice;
