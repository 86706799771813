import { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { DeleteContact } from '../../../../../../Services';
import Spinner from '../../../../../../spinner/Spinner';
import ModalDialog from '../../../../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../../../../modalDialog/modalDialogContent/ModalDialogContent';
import './DeleteLeadsDialog.css';

const DeleteLeadsDialog = ({ onDelete, onCancel }) => {
	const handleDelete = async () => {
		if (onDelete) {
			onDelete();
		}
	};

	const handleOnCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	return (
		<ModalDialog>
			<ModalDialogContent>
				<h5>Delete Selected Leads?</h5>
				<p>Are you sure you want to delete all selected leads?</p>
			</ModalDialogContent>
			<ModalDialogButtons shaded align="left">
				<button
					type="button"
					className="btn btn-danger"
					onClick={handleDelete}
				>
					<i className="far fa-trash-alt" />
					<span>Delete Leads</span>
				</button>

				<button
					type="button"
					className="btn btn-outline-primary"
					onClick={handleOnCancel}
				>
					<span>Cancel</span>
				</button>
			</ModalDialogButtons>
		</ModalDialog>
	);
};

export default DeleteLeadsDialog;
