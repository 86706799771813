import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { RefundPayment } from '../../../../../../Services';
import Spinner from '../../../../../../spinner/Spinner';
import ModalDialog from '../../../../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../../../../modalDialog/modalDialogContent/ModalDialogContent';
import './RefundDialog.css';

const RefundDialog = ({ payment, onRefund, onCancel, userDetails }) => {
	const [ isRefunding, setIsRefunding ] = useState(false);
	const [ isValid, setIsValid ] = useState(false);
	const [ refundText, setRefundText ] = useState('');
	const [ refundReason, setRefundReason ] = useState('');

	const handleChange = (e) => {
		if (e.target.name === 'refundText') {
			setRefundText(e.target.value);
		} else {
			setRefundReason(e.target.value);
		}
	};

	useEffect(
		() => {
			if (refundText === 'refund') {
				if (refundReason !== '') {
					setIsValid(true);
				} else {
					setIsValid(false);
				}
			} else {
				setIsValid(false);
			}

			// console.log(refundText, refundReason);
		},
		[ refundText, refundReason ]
	);

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsRefunding(true);

		let data = {
			UserGuid     : userDetails.Guid,
			Id           : payment.id,
			Amount       : payment.amount,
			RefundReason : refundReason
		};

		const response = await RefundPayment(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (onRefund) {
				onRefund();
			}
		} else {
			toast.error(response.Message);
		}

		setIsRefunding(false);
	};

	const handleCancelClick = () => {
		if (onCancel) {
			onCancel();
		}
	};

	return (
		<Fragment>
			<ModalDialog>
				<form onSubmit={handleSubmit} autoComplete="off">
					<ModalDialogContent>
						<h5>Choose a reason for this refund:</h5>

						<div class="form-check mt-2">
							<input
								class="form-check-input"
								type="radio"
								name="refundReason"
								id="refundReason1"
								value="duplicate"
								onChange={handleChange}
							/>
							<label class="form-check-label" for="refundReason1">
								Duplicate Charge
							</label>
						</div>

						<div class="form-check mt-2">
							<input
								class="form-check-input"
								type="radio"
								name="refundReason"
								id="refundReason2"
								value="requested_by_customer"
								onChange={handleChange}
							/>
							<label class="form-check-label" for="refundReason2">
								Requested By Customer
							</label>
						</div>

						<small className="text-muted d-block mt-3">
							Type in the word <strong>refund</strong> into the
							text box below to confirm this refund
						</small>

						<input
							type="text"
							name="refundText"
							className="form-control mt-3"
							placeholder="Type in the word refund to confirm"
							disabled={isRefunding}
							value={refundText}
							onChange={handleChange}
						/>
					</ModalDialogContent>
					<ModalDialogButtons shaded>
						<button
							type="submit"
							className="btn btn-danger"
							disabled={!isValid || isRefunding}
						>
							{!isRefunding ? (
								<Fragment>
									<span>Refund Payment</span>
								</Fragment>
							) : (
								<Fragment>
									<Spinner />
									<span>Refunding...</span>
								</Fragment>
							)}
						</button>

						<button
							type="button"
							className="btn btn-outline-primary"
							disabled={isRefunding}
							onClick={handleCancelClick}
						>
							<span>Cancel</span>
						</button>
					</ModalDialogButtons>
				</form>
			</ModalDialog>
		</Fragment>
	);
};

export default RefundDialog;
