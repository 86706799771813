import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { actionTypes } from '../../../../../../reducer';
import { useStateValue } from '../../../../../../StateProvider';

const WebsiteWizardSetupWizardBusinessAddress = () => {
	const [ formData, setFormData ] = useState({
		businessAddress : '',
		businessCity    : '',
		businessState   : '',
		businessZIP     : ''
	});
	const [ { websiteWizardSetupData }, dispatch ] = useStateValue();
	const { preconfigId, websiteId } = useParams();
	const history = useHistory();
	const inputRef = useRef();

	useEffect(
		() => {
			if (inputRef.current) {
				inputRef.current.focus();
			}
		},
		[ inputRef ]
	);

	useEffect(
		() => {
			if (websiteWizardSetupData) {
				let tempFormData = {};

				if (websiteWizardSetupData.businessAddress) {
					tempFormData.businessAddress =
						websiteWizardSetupData.businessAddress;
				}

				if (websiteWizardSetupData.businessCity) {
					tempFormData.businessCity =
						websiteWizardSetupData.businessCity;
				}

				if (websiteWizardSetupData.businessState) {
					tempFormData.businessState =
						websiteWizardSetupData.businessState;
				}

				if (websiteWizardSetupData.businessZIP) {
					tempFormData.businessZIP =
						websiteWizardSetupData.businessZIP;
				}

				setFormData(tempFormData);
			}
		},
		[ websiteWizardSetupData ]
	);

	const handleChange = (e) => {
		const target = e.target.name;
		const value =
			e.target.type == 'checkbox' ? e.target.checked : e.target.value;

		setFormData({
			...formData,
			[target] : value
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		dispatch({
			type                   : actionTypes.SET_WEBSITE_WIZARD_SETUP_DATA,
			websiteWizardSetupData : {
				...websiteWizardSetupData,
				...formData
			}
		});

		if (preconfigId) {
			history.push(`/website-setup-wizard/${preconfigId}/complete`);
		} else {
			history.push(`/configure-website/${websiteId}/complete`);
		}
	};

	return (
		<div className="container setup-wizard-step xyz-in">
			<h5 class="rw-container-title">
				<span>Enter Your Business Address</span>
			</h5>

			<form onSubmit={handleSubmit}>
				<div className="form-inputs">
					<div className="row mb-3">
						<div className="col-sm-12">
							<input
								ref={inputRef}
								onChange={handleChange}
								value={formData.businessAddress}
								name="businessAddress"
								type="text"
								className="form-control form-control-lg"
								placeholder="Enter your company address"
							/>
						</div>
					</div>
					<div className="row mb-3">
						<div className="col-sm-12 col-md-4">
							<input
								onChange={handleChange}
								value={formData.businessCity}
								name="businessCity"
								type="text"
								className="form-control form-control-lg"
								placeholder="City"
							/>
						</div>
						<div className="col-sm-12 col-md-4">
							<input
								onChange={handleChange}
								value={formData.businessState}
								name="businessState"
								type="text"
								className="form-control form-control-lg"
								placeholder="State"
							/>
						</div>
						<div className="col-sm-12 col-md-4">
							<input
								onChange={handleChange}
								value={formData.businessZIP}
								name="businessZIP"
								type="text"
								className="form-control form-control-lg"
								placeholder="ZIP"
							/>
						</div>
					</div>
				</div>

				<button type="submit" className="btn btn-primary">
					<span>Continue</span>
				</button>

				<Link onClick={() => history.goBack()} className="btn btn-link">
					<span>Back</span>
				</Link>
			</form>
		</div>
	);
};

export default WebsiteWizardSetupWizardBusinessAddress;
