import { Fragment, useEffect, useRef, useState } from 'react';
import './WebsiteV2.css';
import noImage from '../../../../../img/no-image-avail.png';
import WebsiteActions from './websiteActions/WebsiteActions';
import { useHistory } from 'react-router-dom';
import { useStateValue } from '../../../../../StateProvider';
import {
	RemoveDomain,
	RefreshWebsiteData,
	UpdateWebsiteTitle
} from '../../../../../Services';
import ModalDialog from '../../../../modalDialog/ModalDialog';
import ModalDialogContent from '../../../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import Spinner from '../../../../../spinner/Spinner';
import underConstructionImage from '../../../../../img/under-construction.jpg';
import { toast } from 'react-toastify';

const WebsiteV2 = ({
	website,
	screenshotUrl,
	saveAsPreconfigured,
	deleteWebsite,
	cloneWebsite,
	toggleTrialDialog,
	refreshWebsites,
	index
}) => {
	const [ { user, guidedTourSteps }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isInstalling, setIsInstalling ] = useState(false);
	const [ showDomainRemove, setShowDomainRemove ] = useState(false);
	const [ isRefreshingData, setIsRefreshingData ] = useState(false);
	const [ websiteName, setWebsiteName ] = useState('');
	const [ tourClass, setTourClass ] = useState('');
	const screenshotRef = useRef(null);
	const history = useHistory();

	useEffect(
		() => {
			if (website) {
				setWebsiteName(website.WebsiteName);

				if (website.Status && website.Status === 'Draft') {
					setIsInstalling(true);
				} else {
					setIsInstalling(false);
					setScreenshotUrl();
				}
			}
		},
		[ website ]
	);

	useEffect(
		() => {
			if (index === 0 && guidedTourSteps && guidedTourSteps.length > 0) {
				setTourClass('tour-active');
			} else {
				setTourClass('');
			}
		},
		[ guidedTourSteps ]
	);

	const setScreenshotUrl = (isReload = false) => {
		let tempScreenshotUrl = '';

		if (screenshotUrl) {
			if (screenshotUrl.startsWith('https://')) {
				tempScreenshotUrl = `${screenshotUrl}`;
			} else {
				tempScreenshotUrl = `/${screenshotUrl}`;
			}
		} else {
			tempScreenshotUrl = `${noImage}`;
		}

		if (website.UnderConstruction) {
			tempScreenshotUrl = `${underConstructionImage}`;
		}

		if (isReload) {
			tempScreenshotUrl = `url(${tempScreenshotUrl}?v=${parseInt(
				new Date() / 1000
			)})`;
		} else {
			tempScreenshotUrl = `url(${tempScreenshotUrl})`;
		}

		screenshotRef.current.style.backgroundImage = tempScreenshotUrl;
	};

	const handleSaveAsPreconfigured = () => {
		saveAsPreconfigured(website);
	};

	const handleDeleteWebsite = () => {
		deleteWebsite(website);
	};

	const handleCloneWebsite = () => {
		cloneWebsite(website);
	};

	const handleMakeItLive = () => {
		history.push(`/make-it-live/${website.Guid}`);
	};

	const toggleRemoveDomain = () => {
		setShowDomainRemove((prev) => !prev);
	};

	const handleRemoveDomain = async () => {
		setIsLoading(true);

		const data = {
			WebsiteId : website.Guid
		};

		const response = await RemoveDomain(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (refreshWebsites) {
				refreshWebsites();
			}
		} else {
			toast.error(response.Message);
		}

		toggleRemoveDomain();
		setIsLoading(false);
	};

	const handleRefreshWebsiteData = async () => {
		setIsLoading(true);
		setIsRefreshingData(true);

		const data = {
			Guid : website.Guid
		};

		const response = await RefreshWebsiteData(data);

		if (response.IsSuccessful) {
			// toast.success(response.Message);
			toast.success('Refresh completed successfully.');

			setScreenshotUrl(true);
		} else {
			toast.error(response.Message);
		}

		setIsRefreshingData(false);
		setIsLoading(false);
	};

	const handleTrialClick = () => {
		if (toggleTrialDialog) {
			toggleTrialDialog();
		}
	};

	const handleWebsiteNameChange = (e) => {
		const value = e.target.value;

		setWebsiteName(value);
	};

	const handleWebsiteNameUpdate = async (e) => {
		e.preventDefault();

		if (
			websiteName.trim() !== '' &&
			websiteName.trim() !== website.WebsiteName
		) {
			// we need to send a name update
			let data = {
				Guid  : website.Guid,
				Title : websiteName
			};

			const response = await UpdateWebsiteTitle(data);

			if (response.IsSuccessful) {
				toast.success(response.Message);
			} else {
				toast.error(response.Message);
			}
		}
	};

	return (
		<Fragment>
			<div className={`website-container ${tourClass}`}>
				{!isInstalling ? (
					<Fragment>
						<div ref={screenshotRef} className="screenshot" />

						<div className="website-info-container">
							<div className="website-name">
								<form onSubmit={handleWebsiteNameUpdate}>
									<input
										type="text"
										className="website-name-input"
										value={websiteName}
										onChange={handleWebsiteNameChange}
										onBlur={handleWebsiteNameUpdate}
									/>
								</form>
								{/* <span className="text-truncate">
							{website.WebsiteName}
						</span> */}
							</div>
							<div className="website-url">
								<a
									href={website.Url}
									className="text-truncate"
									target="_blank"
									title="Opens in a new tab"
								>
									{website.Url
										.replace('https://', '')
										.replace('http://', '')
										.replace('/', '')}
									<i className="fas fa-external-link-alt" />
								</a>
							</div>
							<div className="website-actions-list">
								<WebsiteActions
									saveAsPreconfigured={
										handleSaveAsPreconfigured
									}
									deleteWebsite={handleDeleteWebsite}
									cloneWebsite={handleCloneWebsite}
									refreshWebsiteData={
										handleRefreshWebsiteData
									}
									toggleTrialDialog={handleTrialClick}
									isRefreshingData={isRefreshingData}
									isLoading={isLoading}
								/>
							</div>
							{!website.UnderConstruction && (
								<Fragment>
									<div className="website-edit-buttons">
										<div className="view-edit">
											{user &&
											user.Status === 'Active' && (
												<Fragment>
													{/* <a
												href={`../EditWebsite.aspx?guid=${website.Guid}&redirect=_wp-admin_`}
												className="btn btn-primary btn-lg btn-edit desktop-only"
												target="_blank"
											>
												<i className="fa-solid fa-pencil" />
												<span>Edit</span>
											</a> */}
													<a
														href={`../EditWebsite.aspx?guid=${website.Guid}&redirect=_`}
														className="btn btn-primary btn-lg btn-view"
														target="_blank"
													>
														<i className="fa-solid fa-pencil" />
														<span>View & Edit</span>
													</a>
												</Fragment>
											)}

											{user &&
											user.Status === 'Trial' && (
												<Fragment>
													{/* <a
												onClick={handleTrialClick}
												className="btn btn-primary btn-lg btn-edit desktop-only"
												target="_blank"
											>
												<i className="fa-solid fa-pencil" />
												<span>Edit</span>
											</a> */}
													<a
														href={`../EditWebsite.aspx?guid=${website.Guid}&redirect=_`}
														className="btn btn-primary btn-lg btn-view"
														target="_blank"
													>
														<i className="fa-solid fa-pencil" />
														<span>View & Edit</span>
													</a>
												</Fragment>
											)}

											{/* <a
										href={`../EditWebsite.aspx?guid=${website.Guid}&redirect=_`}
										className="btn btn-primary btn-lg"
										target="_blank"
									>
										<i className="fas fa-external-link-alt" />
										<span>View</span>
									</a> */}
										</div>

										<div className="domain-actions">
											{user &&
											user.Status === 'Active' && (
												<Fragment>
													{!website.DomainName ? (
														<button
															type="button"
															className="btn btn-lg btn-orange btn-apply-domain"
															onClick={
																handleMakeItLive
															}
														>
															<i className="fas fa-rocket" />
															<span>
																Apply Domain
															</span>
														</button>
													) : (
														<button
															type="button"
															className="btn btn-danger btn-lg d-block btn-apply-domain"
															onClick={
																toggleRemoveDomain
															}
														>
															<i className="fas fa-unlink" />
															<span>
																Remove Domain
															</span>
														</button>
													)}
												</Fragment>
											)}

											{user &&
											user.Status === 'Trial' && (
												<Fragment>
													{!website.DomainName && (
														<button
															type="button"
															className="btn btn-orange btn-apply-domain"
															onClick={
																handleTrialClick
															}
														>
															<i className="fas fa-rocket" />
															<span>
																Apply Domain
															</span>
														</button>
													)}
												</Fragment>
											)}
										</div>
									</div>
								</Fragment>
							)}
						</div>
						<div className="highlight" />
					</Fragment>
				) : (
					<Fragment>
						<div className="website-installing-notice shadow">
							<Spinner />
							<div className="text-center">
								This website is installing...
							</div>
						</div>
					</Fragment>
				)}
			</div>

			{showDomainRemove && (
				<ModalDialog>
					<ModalDialogContent>
						<h5>Remove Domain Confirmation</h5>
						<p className="m-0">
							Are you sure you want to remove the domain from this
							website?
						</p>
					</ModalDialogContent>
					<ModalDialogButtons shaded>
						<button
							type="button"
							className="btn btn-danger"
							onClick={handleRemoveDomain}
							disabled={isLoading}
						>
							{!isLoading ? (
								<Fragment>
									<i className="fas fa-check" />
									<span>Yes</span>
								</Fragment>
							) : (
								<Fragment>
									<Spinner />
									<span>Removing Domain...</span>
								</Fragment>
							)}
						</button>

						<button
							type="button"
							className="btn btn-outline-primary"
							onClick={toggleRemoveDomain}
							disabled={isLoading}
						>
							<i className="fas fa-chevron-left" />
							<span>Cancel</span>
						</button>
					</ModalDialogButtons>
				</ModalDialog>
			)}
		</Fragment>
	);
};

export default WebsiteV2;
