import { Fragment } from 'react';
import ModalDialog from '../modalDialog/ModalDialog';
import ModalDialogButtons from '../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../modalDialog/modalDialogContent/ModalDialogContent';
import TermsAndConditions from '../termsAndConditions/TermsAndConditions';
import './AppTermsAndConditions.css';

const AppTermsAndConditions = ({ onClose }) => {
	const handleClose = () => {
		if (onClose) {
			onClose();
		}
	};

	return (
		<Fragment>
			<ModalDialog>
				<ModalDialogContent>
					<div className="terms-and-conditions-container">
						<TermsAndConditions />
					</div>
				</ModalDialogContent>
				<ModalDialogButtons shaded>
					<button type="button" className="btn btn-primary" onClick={handleClose}>
						<i className="far fa-thumbs-up" />
						<span>OK</span>
					</button>
				</ModalDialogButtons>
			</ModalDialog>
		</Fragment>
	);
};

export default AppTermsAndConditions;
