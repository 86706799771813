import { Fragment, useEffect, useState } from 'react';
import { actionTypes } from '../../reducer';
import { useStateValue } from '../../StateProvider';
import AppSidePanel from '../appSidePanel/AppSidePanel';
import './ScheduleCall.css';

const ScheduleCall = () => {
	const [ { user, showScheduleCall }, dispatch ] = useStateValue();
	const [ showSidebar, setShowSidebar ] = useState(false);

	useEffect(
		() => {
			if (user && window.Calendly && showSidebar) {
				window.Calendly.initInlineWidget({
					url           : 'https://calendly.com/realwebsite1/30min',
					parentElement : document.getElementById('calendar'),
					prefill       : {
						name      : user.FirstName + ' ' + user.LastName,
						firstName : user.FirstName,
						lastName  : user.LastName,
						email     : user.Email
					},
					utm           : {}
				});
			}
		},
		[ user, window.Calendly, showSidebar ]
	);

	useEffect(
		() => {
			if (showScheduleCall) {
				setShowSidebar(true);
			} else {
				setShowSidebar(false);
			}
		},
		[ showScheduleCall ]
	);

	const handleOnClose = () => {
		dispatch({
			type             : actionTypes.SET_SHOW_SCHEDULE_CALL,
			showScheduleCall : false
		});
	};

	return (
		<Fragment>
			{showSidebar && (
				<Fragment>
					<AppSidePanel show={true} onClose={handleOnClose}>
						<div id="calendar" className="m-0" />
					</AppSidePanel>
				</Fragment>
			)}
		</Fragment>
	);
};

export default ScheduleCall;
