import { Fragment, useEffect, useState } from 'react';
import { actionTypes } from '../../../reducer';
import { GetBrand } from '../../../Services';
import Spinner from '../../../spinner/Spinner';
import { useStateValue } from '../../../StateProvider';
import './BrandSettings.css';

const BrandSettings = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(true);
    const [formData, setFormData] = useState({});
    const [{ user }, dispatch] = useStateValue();

    useEffect(() => {
        // get brand details
        getBrandDetails();
    }, []);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;

        if (name !== 'BrandName' && e.target.type !== 'checkbox') {
            value = value.trim();
        }

        setFormData((prev) => {
            return {
                ...prev,
                [name]: value
            }
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log(formData);
    }

    const getBrandDetails = async () => {
        const response = await GetBrand();

        if (response.IsSuccessful) {
            let storageData = JSON.parse(response.Data);
            storageData.origin = window.location.origin;

            setFormData(storageData);

            sessionStorage.setItem('rwBrand', JSON.stringify(storageData));

            addBrandInformation(storageData);

            dispatch({
                type: actionTypes.SET_BRAND,
                brand: storageData
            });
        }

        setIsLoading(false);
    }

    const addBrandInformation = (brand) => {
        if (brand.CustomStylesheetUrl) {
            var head = document.head;
            var link = document.createElement('link');

            link.type = 'text/css';
            link.rel = 'stylesheet';
            link.href = brand.CustomStylesheetUrl;

            head.appendChild(link);
        }

        if (brand.FaviconUrl) {
            const favicon = document.getElementById('favicon');
            favicon.href = brand.FaviconUrl;
        }

        document.title = brand.BrandName;
    };

    return (
        <div className="brand-settings panel">
            <div className="container">
                <h5 className="rw-container-title">
                    <i className="fas fa-sliders-h" />
                    <span>Settings</span>
                </h5>

                <div className="rw-container shadow-large settings">
                    {!isLoading ? (
                        <form onSubmit={handleSubmit}>
                            <div className="form-inputs">
                                <div className="form-group">
                                    <label htmlFor="supportEmail">Support Email</label>
                                    <input
                                        type="email"
                                        id="supportEmail"
                                        name="BrandSupportEmail"
                                        className="form-control form-control-lg"
                                        disabled={isLoading || isSaving}
                                        value={formData.BrandSupportEmail}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="supportUrl">Support Url</label>
                                    <input
                                        type="text"
                                        id="supportUrl"
                                        name="BrandSupportUrl"
                                        className="form-control form-control-lg"
                                        disabled={isLoading || isSaving}
                                        value={formData.BrandSupportUrl}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="ns1">NS1</label>
                                    <input
                                        type="text"
                                        id="ns1"
                                        name="NS1"
                                        className="form-control form-control-lg"
                                        disabled={isLoading || isSaving}
                                        value={formData.NS1}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="ns2">NS2</label>
                                    <input
                                        type="text"
                                        id="ns2"
                                        name="NS2"
                                        className="form-control form-control-lg"
                                        disabled={isLoading || isSaving}
                                        value={formData.NS2}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="customStylesheetUrl">Custom CSS Url</label>
                                    <input
                                        type="text"
                                        id="customStylesheetUrl"
                                        name="CustomStylesheetUrl"
                                        className="form-control form-control-lg"
                                        disabled={isLoading || isSaving}
                                        value={formData.CustomStylesheetUrl}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            {/* <div className="rw-container-buttons">
                                <button type="submit" className="btn btn-primary" disabled={isLoading || isSaving}>
                                    {!isSaving ? (
                                        <Fragment>
                                            <i class="fas fa-cloud-upload-alt" />
                                            <span>Save Changes</span>
                                        </Fragment>
                                    ) : (
                                        <Fragment>
                                            <Spinner />
                                            <span>Saving...</span>
                                        </Fragment>
                                    )}
                                </button>
                            </div> */}
                        </form>
                    ) : (
                        <div className="text-center p-3">
                            <Spinner />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default BrandSettings;