import React, { useState, useEffect } from 'react';
import { usePaginationRange, DOTS } from './usePaginationRange';
import './Pagination.css';

const Pagination = ({
	data,
	onPageNumberChanged,
	totalDataCount,
	pageNumber,
	buttonConst,
	itemsPerPage,
	siblingCount
}) => {
	//const [ totalPageCount ] = useState(Math.ceil(totalDataCount / itemsPerPage));
	const [ currentPage, setCurrentPage ] = useState(pageNumber || 1);
	let totalPageCount = Math.ceil(totalDataCount / itemsPerPage);

	const paginationRange = usePaginationRange({
		totalPageCount,
		itemsPerPage,
		buttonConst,
		siblingCount,
		currentPage
	});

	useEffect(
		() => {
			window.scrollTo({
				behavior : 'smooth',
				top      : '0px'
			});
		},
		[ currentPage ]
	);

	function goToNextPage() {
		let tempCurrentPage = currentPage + 1;
		setCurrentPage(tempCurrentPage);

		if (onPageNumberChanged) {
			onPageNumberChanged(tempCurrentPage);
		}
	}

	function goToPreviousPage() {
		let tempCurrentPage = currentPage - 1;
		setCurrentPage(tempCurrentPage);

		if (onPageNumberChanged) {
			onPageNumberChanged(tempCurrentPage);
		}
	}

	function changePage(event) {
		const pageNumber = Number(event.target.textContent);
		setCurrentPage(pageNumber);

		if (onPageNumberChanged) {
			onPageNumberChanged(pageNumber);
		}
	}

	return (
		<div>
			<div className="pagination">
				<button
					onClick={goToPreviousPage}
					className={`pagination-item prev ${currentPage === 1 ? 'disabled' : ''}`}
				>
					previous
				</button>

				{paginationRange.map((item, index) => {
					if (item === DOTS) {
						return (
							<button key={index} className={`pagination-item disabled`}>
								&#8230;
							</button>
						);
					}
					return (
						<button
							key={index}
							onClick={changePage}
							className={`pagination-item ${currentPage === item ? 'active' : ''}`}
						>
							<span>{item}</span>
						</button>
					);
				})}

				<button
					onClick={goToNextPage}
					className={`pagination-item next ${currentPage === totalPageCount ? 'disabled' : ''}`}
				>
					next
				</button>
			</div>
		</div>
	);
};

export default Pagination;
