import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import './TinymceEditor.css';

const TinymceEditor = ({ onValueChange, initialValue = '', height = 500, showMenubar = false }) => {
	const editorRef = useRef(null);
	// const [ value, setValue ] = useState('');

	const handleValueChange = (newValue) => {
		if (onValueChange) {
			onValueChange(newValue);
		}
	};

	return (
		<Editor
			apiKey="wi1lao33ed5u2op9p024nsxdc3yvuvb2qpmu5diz4jc1cxtu"
			onInit={(evt, editor) => (editorRef.current = editor)}
			initialValue={initialValue}
			init={{
				height        : height,
				menubar       : showMenubar,
				plugins       : [
					'advlist autolink lists link image charmap print preview anchor',
					'searchreplace visualblocks code fullscreen',
					'insertdatetime media table paste code help wordcount'
				],
				toolbar       :
					'undo redo | formatselect | ' +
					'bold italic backcolor | alignleft aligncenter ' +
					'alignright alignjustify | bullist numlist outdent indent | ' +
					'removeformat | help',
				content_style : 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
			}}
			onEditorChange={(newValue, editor) => {
				//setValue(newValue);
				handleValueChange(newValue);
			}}
		/>
	);
};

export default TinymceEditor;
