import { useEffect, useState } from 'react';
import './CreateUserTag.css';

const CreateUserTag = ({ onCreate, tagsList = null }) => {
	const [ tagName, setTagName ] = useState('');
	const [ autocompleteMatch, setAutocompleteMatch ] = useState('');
	const [ placeholderText, setPlaceholderText ] = useState(
		'Click to add a tag...'
	);
	const [ cssClass, setCssClass ] = useState('blur');

	const handleChange = (e) => {
		setTagName(e.target.value);
	};

	// on text change check for auto-complete suggestions
	useEffect(
		() => {
			if (!tagName || tagName.trim() === '') {
				setAutocompleteMatch('');
				return;
			}

			if (tagsList && tagsList.length > 0) {
				let matchingTag = tagsList.find(
					(x) => x.indexOf(tagName) === 0
				);

				if (matchingTag) {
					setAutocompleteMatch(matchingTag);
				} else {
					setAutocompleteMatch('');
				}
			} else {
				setAutocompleteMatch('');
			}
		},
		[ tagName ]
	);

	const handleBlurEvent = (e) => {
		setPlaceholderText('Click to add a tag...');
		setCssClass('blur');
		setTagName('');
	};

	const handleFocusEvent = (e) => {
		setPlaceholderText('');
		setCssClass('focus');
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		let finalTag = tagName.trim();

		if (autocompleteMatch && autocompleteMatch !== '') {
			setTagName(autocompleteMatch);
			console.log('tag submitted', autocompleteMatch);
			finalTag = autocompleteMatch;
		} else {
			console.log('tag submitted', tagName.trim());
		}

		if (onCreate) {
			onCreate(finalTag);
			setTagName('');
		}
	};

	return (
		<div className={`user-create-tag ${cssClass}`}>
			<form className="user-create-tag-form" onSubmit={handleSubmit}>
				<input
					type="text"
					className="form-create-user-input"
					value={tagName}
					onChange={handleChange}
					onBlur={handleBlurEvent}
					onFocus={handleFocusEvent}
					placeholder={placeholderText}
				/>
			</form>

			<input
				type="text"
				className="form-create-user-autocomplete"
				value={autocompleteMatch}
			/>
		</div>
	);
};

export default CreateUserTag;
