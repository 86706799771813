import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
	GetUserDetailsAdmin,
	SaveUserSettingsAdmin,
	SendPasswordResetAdmin,
	LoginIntoCustomerAccount
} from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import CenteredContainer from '../../../centeredContainer/CenteredContainer';
import LottiePlayer from '../../../lottiePlayer/LottiePlayer';
import emptyDeskAnimation from '../../../lottiePlayer/animations/empty-desk.json';
import './EditUser.css';
import EditUserAccountSettings from './accountSettings/EditUserAccountSettings';
import EditUserCompanyInformation from './companyInformation/EditUserCompanyInformation';
import EditUserAccountInvoices from './userInvoices/EditUserAccountInvoices';
import EditUserBillingHistory from './billingHistory/EditUserBillingHistory';
import { toast } from 'react-toastify';
import ModalDialog from '../../../modalDialog/ModalDialog';
import ModalDialogContent from '../../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import UserWebsitesList from '../userListView/userWebsitesList/UserWebsitesList';
import { useStateValue } from '../../../../StateProvider';
import { actionTypes } from '../../../../reducer';
import PreconfigCopy from './preconfigCopy/PreconfigCopy';
import UpdateSubscriptionId from './updateSubscriptionId/UpdateSubscriptionId';

const EditUser = () => {
	const [ { adminUser, brand }, dispatch ] = useStateValue();
	const { userGuid } = useParams();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isLoggingIn, setIsLoggingIn ] = useState(false);
	const [ userDetails, setUserDetails ] = useState(null);
	const [ sendingPasswordResetEmail, setSendingPasswordResetEmail ] = useState(false);
	const [ cancelingUser, setCancelingUser ] = useState(false);
	const [ markingForDelete, setMarkingForDelete ] = useState(false);
	const [ showDeleteConfirmDialog, setShowDeleteConfirmDialog ] = useState(false);
	const [ showCopyPreconfigDialog, setShowCopyPreconfigDialog ] = useState(false);
	const [ showUpdateCustomerSubscriptionIdDialog, setShowUpdateCustomerSubscriptionIdDialog ] = useState(false);
	const history = useHistory();

	useEffect(
		() => {
			if (userGuid) {
				getUserDetails();
			}
		},
		[ userGuid ]
	);

	const getUserDetails = async () => {
		let data = {
			UserGuid : userGuid
		};

		const response = await GetUserDetailsAdmin(data);

		if (response.IsSuccessful) {
			let tempUserDetails = JSON.parse(response.Data);

			if (!tempUserDetails.ForDelete) {
				tempUserDetails.ForDelete = false;
			}

			if (tempUserDetails.Phone) {
				tempUserDetails.Phone = tempUserDetails.Phone.replace(/\s/gi, '');
			}

			if (tempUserDetails.CompanyPhone) {
				tempUserDetails.CompanyPhone = tempUserDetails.CompanyPhone.replace(/\s/gi, '');
			}

			// console.log(tempUserDetails);

			setUserDetails(tempUserDetails);
		}

		setIsLoading(false);
	};

	const handleOnUpdate = (user) => {
		setUserDetails({ ...user });
	};

	const handleSendPasswordResetClick = async () => {
		setSendingPasswordResetEmail(true);

		const data = {
			UserGuid : userDetails.Guid
		};

		const response = await SendPasswordResetAdmin(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);
		} else {
			toast.error(response.Message);
		}

		setSendingPasswordResetEmail(false);
	};

	const toggleShowDeleteConfirmDialog = () => {
		setShowDeleteConfirmDialog((prev) => !prev);
	};

	const handleMarkForDeletion = async () => {
		setMarkingForDelete(true);

		let data = {
			...userDetails,
			UserGuid     : userDetails.Guid,
			EmailAddress : userDetails.Email,
			ForDelete    : true
		};

		const response = await SaveUserSettingsAdmin(data);

		if (response.IsSuccessful) {
			toast.success('User marked for deletion successfully');
			history.push('/administration');
		} else {
			toast.error(response.Message);
		}

		setMarkingForDelete(false);
	};

	const handleLoginAs = async () => {
		setIsLoggingIn(true);

		let data = {
			UserGuid : userGuid
		};

		const response = await LoginIntoCustomerAccount(data);

		if (response.IsSuccessful) {
			let newUser = JSON.parse(response.Data);

			dispatch({
				type : actionTypes.SET_USER,
				user : newUser
			});

			toast.success(response.Message);

			history.push('/');
		} else {
			toast.error(response.Message);
		}

		setIsLoggingIn(false);
	};

	const toggleShowCopyPreconfigDialog = () => {
		setShowCopyPreconfigDialog((prev) => !prev);
	};

	const toggleShowUpdateCustomerSubscriptionIdDialog = () => {
		setShowUpdateCustomerSubscriptionIdDialog((prev) => !prev);
	};

	const handleSubscriptionUpdate = async () => {
		await getUserDetails();
	};

	return (
		<div className="app-edit-user">
			{!isLoading ? (
				<Fragment>
					{userDetails ? (
						<Fragment>
							<div className="container">
								<div className="admin-actions-container">
									<div className="admin-actions-left">
										<button
											type="button"
											className="btn btn-outline-primary back-to-administration"
											onClick={() => history.push('/administration')}
											title="Back to Administration"
										>
											<i className="fas fa-chevron-left" />
											<span>Back to Administration</span>
										</button>
									</div>

									<div className="admin-actions-right">
										<button
											type="button"
											className="btn btn-outline-primary mr-3"
											disabled={
												sendingPasswordResetEmail ||
												markingForDelete ||
												isLoggingIn ||
												cancelingUser
											}
											onClick={handleLoginAs}
											title="Login As"
										>
											{!isLoggingIn ? (
												<Fragment>
													<i className="fas fa-user-circle" />
													<span>Login As</span>
												</Fragment>
											) : (
												<Fragment>
													<Spinner />
													<span>Logging in...</span>
												</Fragment>
											)}
										</button>

										<button
											type="button"
											className="btn btn-outline-primary mr-3"
											disabled={
												sendingPasswordResetEmail ||
												markingForDelete ||
												isLoggingIn ||
												cancelingUser
											}
											onClick={toggleShowCopyPreconfigDialog}
											title="Copy Preconfig"
										>
											<i className="fa-solid fa-copy m-0" />
											{/* <span>Copy Preconfig</span> */}
										</button>

										<button
											type="button"
											className="btn btn-outline-secondary mr-3"
											disabled={
												sendingPasswordResetEmail ||
												markingForDelete ||
												isLoggingIn ||
												cancelingUser
											}
											onClick={toggleShowUpdateCustomerSubscriptionIdDialog}
											title="Update Customer Subscription Id"
										>
											<i className="fa-solid fa-money-check-dollar m-0" />
											{/* <span>Copy Preconfig</span> */}
										</button>

										<button
											type="button"
											className="btn btn-outline-danger mr-3"
											disabled={
												sendingPasswordResetEmail ||
												markingForDelete ||
												isLoggingIn ||
												cancelingUser
											}
											onClick={toggleShowDeleteConfirmDialog}
											title="Mark for Deletion"
										>
											<i className="far fa-trash-alt" />
											<span>Mark for Deletion</span>
										</button>

										<button
											type="button"
											className="btn btn-outline-primary"
											onClick={handleSendPasswordResetClick}
											disabled={
												sendingPasswordResetEmail ||
												markingForDelete ||
												isLoggingIn ||
												cancelingUser
											}
											title="Send Password Reset"
										>
											{!sendingPasswordResetEmail ? (
												<Fragment>
													<i className="far fa-envelope" />
													<span>Send Password Reset</span>
												</Fragment>
											) : (
												<Fragment>
													<Spinner />
													<span>Sending...</span>
												</Fragment>
											)}
										</button>

										{brand.Id !== 1 && (
											<button
												type="button"
												className="btn btn-outline-primary ml-3"
												onClick={() => history.push('/administration/users/cancel/' + userGuid)}
												disabled={
													sendingPasswordResetEmail ||
													markingForDelete ||
													isLoggingIn ||
													cancelingUser
												}
												title="Cancel"
											>
												{!cancelingUser ? (
													<Fragment>
														<i className="fas fa-power-off" />
														<span>Cancel</span>
													</Fragment>
												) : (
													<Fragment>
														<Spinner />
														<span>Canceling...</span>
													</Fragment>
												)}
											</button>
										)}
									</div>
								</div>

								<h5 className="rw-container-title">
									<i className="fas fa-sliders-h" />
									<span>{`${userDetails.FirstName} ${userDetails.LastName} - User Settings`}</span>
								</h5>

								<EditUserAccountSettings userDetails={userDetails} onUpdate={handleOnUpdate} />

								<h5 className="rw-container-title">
									<i className="far fa-building" />
									<span>Company Information</span>
								</h5>

								<EditUserCompanyInformation userDetails={userDetails} onUpdate={handleOnUpdate} />

								<h5 className="rw-container-title">
									<i className="fas fa-receipt" />
									<span>Invoices</span>
								</h5>

								<EditUserAccountInvoices userDetails={userDetails} />

								<h5 className="rw-container-title">
									<i className="fas fa-coins" />
									<span>Billing History</span>
								</h5>

								<EditUserBillingHistory userDetails={userDetails} />

								<h5 className="rw-container-title">
									<i className="fas fa-database" />
									<span>Websites</span>
								</h5>

								<UserWebsitesList user={userDetails} />

								{/* <div className="rw-container-buttons mt-3">
									<Link
										to={`/administration/websites/create/${userDetails.Guid}`}
										className="btn btn-primary"
										disabled={
											sendingPasswordResetEmail ||
											markingForDelete ||
											isLoggingIn
										}
									>
										<i className="fas fa-database" />
										<span>Create New Website</span>
									</Link>
								</div> */}
							</div>

							{showDeleteConfirmDialog && (
								<Fragment>
									<ModalDialog>
										<ModalDialogContent>
											<h5>Are you sure you want to mark this user for deletion?</h5>
										</ModalDialogContent>
										<ModalDialogButtons shaded>
											<button
												type="button"
												className="btn btn-danger"
												disabled={sendingPasswordResetEmail || markingForDelete}
												onClick={handleMarkForDeletion}
											>
												{!markingForDelete ? (
													<Fragment>
														<i className="far fa-trash-alt" />
														<span>Mark for Deletion</span>
													</Fragment>
												) : (
													<Fragment>
														<Spinner />
														<span>Marking for deletion...</span>
													</Fragment>
												)}
											</button>

											<button
												type="button"
												className="btn btn-outline-primary"
												onClick={toggleShowDeleteConfirmDialog}
												disabled={sendingPasswordResetEmail || markingForDelete}
											>
												<span>Cancel</span>
											</button>
										</ModalDialogButtons>
									</ModalDialog>
								</Fragment>
							)}

							{showCopyPreconfigDialog && (
								<PreconfigCopy onClose={toggleShowCopyPreconfigDialog} targetAccountId={userGuid} />
							)}

							{showUpdateCustomerSubscriptionIdDialog && (
								<UpdateSubscriptionId
									onClose={toggleShowUpdateCustomerSubscriptionIdDialog}
									onUpdate={handleSubscriptionUpdate}
									userGuid={userGuid}
								/>
							)}
						</Fragment>
					) : (
						<Fragment>
							<CenteredContainer>
								<h5>Looks like there's no one here?</h5>
								<p className="text-muted">User details could not be loaded</p>
								<LottiePlayer animation={emptyDeskAnimation} width={250} height={250} loop={true} />
							</CenteredContainer>
						</Fragment>
					)}
				</Fragment>
			) : (
				<Fragment>
					<CenteredContainer>
						<Spinner />
					</CenteredContainer>
				</Fragment>
			)}
		</div>
	);
};

export default EditUser;
