import { Fragment, useEffect, useState } from 'react';
import CreateUserTag from './createUserTag/CreateUserTag';
import UserTag from './userTag/UserTag';
import './UserTags.css';

const UserTags = ({ tags, onCreate, onDelete, tagsList = null }) => {
	return (
		<Fragment>
			<div className="user-tags-list">
				<CreateUserTag tagsList={tagsList} onCreate={onCreate} />

				{tags &&
				tags.length > 0 && (
					<Fragment>
						{tags.map((tag) => (
							<UserTag key={tag} tag={tag} onDelete={onDelete} />
						))}
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};

export default UserTags;
