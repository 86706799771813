import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GetAccountCards } from '../../Services';
import Spinner from '../../spinner/Spinner';
import CenteredContainer from '../centeredContainer/CenteredContainer';
import NoDataMessage from '../noDataMessage/NoDataMessage';
import CardInput from './cardInput/CardInput';
import CreditCard from './creditCard/CreditCard';
import './SelectCreditCard.css';

const SelectCreditCard = ({ onSelect, onEmptyAutoRedirect }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ showAddNewCard, setShowAddNewCard ] = useState(false);
	const [ cards, setCards ] = useState([]);
	const [ selectedCard, setSelectedCard ] = useState(null);

	useEffect(() => {
		getUserCards();
	}, []);

	const getUserCards = async () => {
		const response = await GetAccountCards();

		if (response.IsSuccessful) {
			let tempCards = JSON.parse(response.Data);

			setCards(tempCards);

			if (!tempCards || tempCards.length === 0) {
				if (onEmptyAutoRedirect) {
					setShowAddNewCard(true);
				}
			} else {
				setSelectedCard(tempCards[0]);
			}
		} else {
			toast.error(response.Message);
		}

		setIsLoading(false);
	};

	const handleSelectCard = (selectedCard) => {
		setSelectedCard(selectedCard);
	};

	const handleContinue = () => {
		if (onSelect && selectedCard) {
			onSelect(selectedCard);
		}
	};

	const handleAddNewCard = (card) => {
		setSelectedCard(card);

		if (onSelect) {
			onSelect(card);
		}
	};

	const toggleShowAddNew = () => {
		setShowAddNewCard((prev) => !prev);
	};

	return (
		<Fragment>
			{!showAddNewCard ? (
				<Fragment>
					<div className="app-card-select">
						{/* <div className="rw-container"> */}
						<div className="app-cards-container">
							{!isLoading ? (
								<Fragment>
									{cards && cards.length > 0 ? (
										<Fragment>
											{cards.map((card) => (
												<CreditCard
													key={card.Guid}
													creditCard={card}
													onSelect={handleSelectCard}
													selectedCard={selectedCard}
												/>
											))}
										</Fragment>
									) : (
										<Fragment>
											<NoDataMessage message="You don't have any cards stored. Click on the Add New button below to add a new credit card." />
										</Fragment>
									)}
								</Fragment>
							) : (
								<CenteredContainer>
									<Spinner />
								</CenteredContainer>
							)}
						</div>
						<div className="buttons-container">
							<button
								type="button"
								className="btn btn-primary"
								disabled={!selectedCard}
								onClick={handleContinue}
							>
								<i className="fas fa-chevron-right" />
								<span>Continue</span>
							</button>
							<button type="button" className="btn btn-outline-primary" onClick={toggleShowAddNew}>
								<i className="fas fa-plus" />
								<span>Add New Credit Card</span>
							</button>
						</div>
						{/* </div> */}
					</div>
				</Fragment>
			) : (
				<Fragment>
					<div className="app-card-add-new">
						<div className="rw-container">
							<CardInput
								onCancel={toggleShowAddNew}
								onSave={handleAddNewCard}
								hideBackButton={cards.length === 0}
							/>
						</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default SelectCreditCard;
