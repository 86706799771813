import { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { actionTypes } from '../../../../reducer';
import Spinner from '../../../../spinner/Spinner';
import { useStateValue } from '../../../../StateProvider';
import AppTermsAndConditions from '../../../appTermsAndConditions/AppTermsAndConditions';
import ModalDialog from '../../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../../modalDialog/modalDialogContent/ModalDialogContent';
import MakeItLiveSelectedCard from './stepComponents/MakeItLiveSelectedCard';

const MakeItLiveSummary = () => {
	const [ { user, makeItLiveSettings }, dispatch ] = useStateValue();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isAutoRenew, setIsAutoRenew ] = useState(true);
	const [ balanceDue, setBalanceDue ] = useState(0);
	const [ total, setTotal ] = useState(0);
	const [ showDomainPolicyDialog, setShowDomainPolicyDialog ] = useState(
		false
	);
	const [ showTermsDialog, setShowTermsDialog ] = useState(false);
	const [
		requireSubscriptionActivation,
		setRequireSubscriptionActivation
	] = useState(false);
	const [
		showSubscriptionActivationDialog,
		setShowSubscriptionActivationDialog
	] = useState(false);
	const { guid } = useParams();
	const history = useHistory();

	useEffect(
		() => {
			if (user) {
				if (
					user.IsPaidSubscriber !== null &&
					!user.IsPaidSubscriber &&
					user.PriceId &&
					user.PriceId !== ''
				) {
					setRequireSubscriptionActivation(true);
				} else {
					setRequireSubscriptionActivation(false);
				}
			}
		},
		[ user ]
	);

	useEffect(
		() => {
			if (user && makeItLiveSettings.domain) {
				let price = makeItLiveSettings.domain.Price;

				if (makeItLiveSettings.IsPrivateRegistration) {
					price += 10;
				}

				if (user.FreeDomains && user.FreeDomains > 0) {
					price -= makeItLiveSettings.domain.Price;
				}

				setTotal(price);

				if (user.CreditsBalance && user.CreditsBalance > 0) {
					if (user.CreditsBalance >= price) {
						setBalanceDue(0);
					} else {
						setBalanceDue(price - user.CreditsBalance);
					}
				} else {
					setBalanceDue(price);
				}
			} else {
				if (makeItLiveSettings && makeItLiveSettings.domain) {
					if (makeItLiveSettings.IsPrivateRegistration) {
						setBalanceDue(makeItLiveSettings.domain.Price + 10);
						setTotal(makeItLiveSettings.domain.Price + 10);
					} else {
						setBalanceDue(makeItLiveSettings.domain.Price);
						setTotal(makeItLiveSettings.domain.Price);
					}
				}
			}

			if (makeItLiveSettings.IsAutoRenew) {
				setIsAutoRenew(true);
			} else {
				setIsAutoRenew(false);
			}
		},
		[ user, makeItLiveSettings ]
	);

	const handleAutoRenewChange = (e) => {
		setIsAutoRenew(e.target.checked);

		dispatch({
			type               : actionTypes.SET_MAKEITLIVE_SETTINGS,
			makeItLiveSettings : {
				...makeItLiveSettings,
				IsAutoRenew : e.target.checked
			}
		});
	};

	const handleChangeCard = () => {
		history.push(`/make-it-live/${guid}/card-select`);
	};

	const toggleDomainPolicyDialog = () => {
		setShowDomainPolicyDialog((prev) => !prev);
	};

	const toggleTermsDialog = () => {
		setShowTermsDialog((prev) => !prev);
	};

	const toggleSubscriptionActivationDialog = () => {
		setShowSubscriptionActivationDialog((prev) => !prev);
	};

	const handlePayNow = async () => {
		if (requireSubscriptionActivation) {
			toggleSubscriptionActivationDialog();
			return;
		}

		handleSubmit();
	};

	const handleConfirmSubscriptionActivation = async () => {
		handleSubmit();
	};

	const handleSubmit = () => {
		setIsSaving(true);

		history.push(`/make-it-live/${guid}/apply-domain`);
	};

	return (
		<div className="wizard-step wizard-summary">
			{makeItLiveSettings &&
			makeItLiveSettings.domain && (
				<Fragment>
					<h3>Great! Let's register your domain!</h3>
					<p className="mt-5">
						You've found a GREAT domain! Click the "Register Now"
						button to register it for 1 year.
					</p>

					<p className="auto-renew-disclaimer">
						{makeItLiveSettings.card ? (
							<Fragment>
								<strong>AUTO RENEW:</strong> You will need to
								renew this domain every year if you want to keep
								it. If you do not want us to automatically renew
								your domain at the end of the year, then
								unselect the auto renew option below. If you
								leave it selected,{' '}
								<span className="text-highlight">
									we will <u>renew the domain</u> for you
									using the credit card you have selected
									below.
								</span>
							</Fragment>
						) : (
							<Fragment>
								<strong>AUTO RENEW:</strong> You will need to
								renew this domain every year if you want to keep
								it. If you do not want us to automatically renew
								your domain at the end of the year, then
								unselect the auto renew option below. If you
								leave it selected,{' '}
								<span className="text-highlight">
									we will <u>renew the domain</u> for you
									using the credit card you have setup for
									your hosting.
								</span>
							</Fragment>
						)}
					</p>

					<div className="domain-information mt-5">
						<small className="text-muted">
							We're registering this domain for 1 year:
						</small>
						<h3 className="domain-name">
							{makeItLiveSettings.domain.DomainName}
						</h3>
					</div>

					<div className="registration-price-container">
						<div className="balance-due">
							{makeItLiveSettings.card ? (
								<Fragment>
									<span>
										Domain Registration Fee: ${balanceDue.toFixed(2)}
									</span>
								</Fragment>
							) : (
								<Fragment>
									<span>
										Domain Registration Fee: (Free Domain
										Credit)
									</span>
								</Fragment>
							)}
						</div>

						{makeItLiveSettings.card && (
							<Fragment>
								<div className="selected-card">
									<MakeItLiveSelectedCard
										card={makeItLiveSettings.card}
										onChangeClick={handleChangeCard}
									/>
								</div>
							</Fragment>
						)}

						<div className="auto-renew-toggle">
							<span>Auto Renew:</span>
							<input
								id="chkAutoRenew"
								type="checkbox"
								className="rw-checkbox-switch"
								defaultChecked={isAutoRenew}
								onChange={handleAutoRenewChange}
							/>
							<label htmlFor="chkAutoRenew" />
						</div>
					</div>

					<div className="buttons-container">
						<button
							type="button"
							className="btn btn-primary"
							onClick={handlePayNow}
						>
							{!isSaving ? (
								<Fragment>
									<i className="far fa-credit-card" />
									<span>Register Now</span>
								</Fragment>
							) : (
								<Fragment>
									<Spinner />
									<span>Registering...</span>
								</Fragment>
							)}
						</button>
					</div>

					<div className="terms-container mt-3">
						<small>
							By clicking on "Register Now" you agree to our{' '}
							<a className="app-link" onClick={toggleTermsDialog}>
								Terms and Conditions
							</a>{' '}
							and{' '}
							<a
								className="app-link"
								onClick={toggleDomainPolicyDialog}
							>
								Domain Registration Policy
							</a>
						</small>
					</div>

					{showTermsDialog && (
						<Fragment>
							<AppTermsAndConditions
								onClose={toggleTermsDialog}
							/>
						</Fragment>
					)}

					{showDomainPolicyDialog && (
						<Fragment>
							<ModalDialog>
								<ModalDialogContent>
									<h5 className="rw-container-title mt-0">
										<span>Domain Registration Policy</span>
									</h5>

									<div className="alert alert-secondary m-0">
										<p className="m-0">
											<strong>
												NOTE: This order is a
												non-refundable purchase.
											</strong>
											<br />You are purchasing products or
											services that are provided by one of
											our partner companies. Immediately
											after you complete the payment these
											orders are routed and fulfilled by
											those partner companies. We will not
											be able to process refunds or
											cancellations on these purchases
											once they have been routed and
											completed.
										</p>
									</div>
								</ModalDialogContent>
								<ModalDialogButtons shaded>
									<button
										type="button"
										className="btn btn-primary"
										onClick={toggleDomainPolicyDialog}
									>
										<i className="far fa-thumbs-up" />
										<span>OK</span>
									</button>
								</ModalDialogButtons>
							</ModalDialog>
						</Fragment>
					)}

					{showSubscriptionActivationDialog && (
						<Fragment>
							<ModalDialog>
								<ModalDialogContent>
									<h5 className="rw-container-title mt-0">
										<span>
											Great! You found a domain for your
											website!
										</span>
									</h5>

									<p className="m-0">
										You are currently signed up for our
										trial offer, however we require you to
										have an active paid subscription to
										register domains.
									</p>

									<p className="m-0">
										Just click the YES button below and we
										will end your trial and activate your
										monthly subscription and then
										immediately register your domain.
									</p>
								</ModalDialogContent>
								<ModalDialogButtons shaded>
									<button
										type="button"
										className="btn btn-primary"
										onClick={
											handleConfirmSubscriptionActivation
										}
										disabled={isSaving}
									>
										{!isSaving ? (
											<Fragment>
												{/* <i className="far fa-credit-card" /> */}
												<span>YES</span>
											</Fragment>
										) : (
											<Fragment>
												<Spinner />
												<span>Activating...</span>
											</Fragment>
										)}
									</button>
									<button
										type="button"
										className="btn btn-outline-primary"
										onClick={
											toggleSubscriptionActivationDialog
										}
										disabled={isSaving}
									>
										<span>No</span>
									</button>
								</ModalDialogButtons>
							</ModalDialog>
						</Fragment>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default MakeItLiveSummary;
