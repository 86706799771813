import { Link, useParams } from 'react-router-dom';

const MakeItLiveHaveDomain = () => {
	const { guid } = useParams();

	return (
		<div className="wizard-have-domain wizard-step">
			<h3>Do you have a domain for your website?</h3>
			<p className="text-muted">
				Do you already have a domain that you have previously registered that you would like to use for your
				website?
			</p>

			<div className="buttons-container">
				<Link to={`/make-it-live/${guid}/add-domain`} className="btn btn-primary">
					<i className="fas fa-check" />
					<span>Yes</span>
				</Link>

				<Link to={`/make-it-live/${guid}/find-domain`} className="btn btn-primary">
					<i className="fas fa-times" />
					<span>No</span>
				</Link>
			</div>
		</div>
	);
};

export default MakeItLiveHaveDomain;
