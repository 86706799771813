import { Fragment } from 'react';
import './Testimonial.css';

const Testimonial = ({ picture, name, location, testimonial }) => {
	return (
		<div className="testimonial">
			{picture && (
				<div className="testimonial-picture-container">
					<img src={picture} alt={name} />
				</div>
			)}

			<div className="testimonial-text">
				&quot;
				<Fragment>{testimonial}</Fragment>
				&quot;
			</div>

			<div className="testimonial-name">{name}</div>
			<div className="testimonial-location">{location}</div>

			<div className="testimonial-rating">
				<i className="fas fa-star" />
				<i className="fas fa-star" />
				<i className="fas fa-star" />
				<i className="fas fa-star" />
				<i className="fas fa-star" />
			</div>
		</div>
	);
};

export default Testimonial;
