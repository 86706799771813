import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GetDomainSubdomains } from '../../../../../Services';
import Spinner from '../../../../../spinner/Spinner';
import CenteredContainer from '../../../../centeredContainer/CenteredContainer';
import NoDataMessage from '../../../../noDataMessage/NoDataMessage';
import AddSubdomain from './addSubdomain/AddSubdomain';
import Subdomain from './subdomain/Subdomain';
import './SubdomainsList.css';

const SubdomainsList = ({ domain }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isInAddMode, setIsInAddMode ] = useState(false);
	const [ subdomainsList, setSubdomainsList ] = useState([]);

	useEffect(
		() => {
			if (domain) {
				getSubdomainsList();
			} else {
				setSubdomainsList([]);
			}
		},
		[ domain ]
	);

	const getSubdomainsList = async () => {
		let data = {
			Guid : domain.Guid
		};

		const response = await GetDomainSubdomains(data);

		if (response.IsSuccessful) {
			let tempSubdomainsList = JSON.parse(response.Data);
			console.log(tempSubdomainsList);

			setSubdomainsList(tempSubdomainsList);
		} else {
			toast.error(response.Message);
		}

		setIsLoading(false);
	};

	const toggleAddMode = () => {
		setIsInAddMode((prev) => !prev);
	};

	const handleSubdomainAdd = () => {
		toggleAddMode();
		getSubdomainsList();
	};

	return (
		<div className="rw-container">
			{!isLoading ? (
				<Fragment>
					{subdomainsList && subdomainsList.length > 0 ? (
						<Fragment>
							<div className="subdomains-list-container">
								<div className="rw-table">
									<div className="rw-table-header">
										<div className="rw-th name">
											<span>Subdomain</span>
										</div>
										<div className="rw-th action" />
									</div>

									{subdomainsList.map((subdomain) => (
										<Subdomain key={subdomain.Guid} subdomain={subdomain} domain={domain} />
									))}
								</div>
							</div>
						</Fragment>
					) : (
						<NoDataMessage message="No subdomains found" />
					)}
				</Fragment>
			) : (
				<div className="text-center p-3">
					<Spinner />
				</div>
			)}

			{isInAddMode && <AddSubdomain domain={domain} onSave={handleSubdomainAdd} onCancel={toggleAddMode} />}

			<div className="rw-container-buttons">
				<button type="button" className="btn btn-primary" disabled={isLoading} onClick={toggleAddMode}>
					<i className="fas fa-plus" />
					<span>Add New</span>
				</button>
			</div>
		</div>
	);
};

export default SubdomainsList;
