import { Fragment } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { SendFeedback } from '../../../Services';
import Spinner from '../../../spinner/Spinner';
import LottiePlayer from '../../lottiePlayer/LottiePlayer';
import letterAnimation from '../../lottiePlayer/animations/letter2.json';
import './FeedbackForm.css';
import CenteredContainer from '../../centeredContainer/CenteredContainer';

const FeedbackForm = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ isSuccess, setIsSuccess ] = useState(false);
	const [ message, setMessage ] = useState('');

	const handleChange = (e) => {
		const value = e.target.value;

		setMessage(value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (message.trim() === '') {
			toast.error('Message is required to submit the feedback form');
			return;
		}

		setIsLoading(true);

		let data = {
			Message : message
		};

		const response = await SendFeedback(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			setIsSuccess(true);
		} else {
			toast.error(response.Message);
		}

		setIsLoading(false);
	};

	return (
		<div className="app-feedback panel">
			{!isSuccess ? (
				<Fragment>
					<div className="container">
						<h5 className="rw-container-title mb-1">
							<i className="far fa-comment-alt" />
							<span>Let us know what you think!</span>
						</h5>
						<div className="text-muted mb-3">
							If you have anything you'd like to share with us such as the experience of using the
							software, suggestions, anything that comes to mind really feel free to let us know via the
							feedback form below.
						</div>

						<div className="rw-container shadow-large feedback-form">
							<form onSubmit={handleSubmit}>
								<div className="form-inputs">
									<div className="form-group">
										<label htmlFor="message">Your feedback</label>
										<textarea
											id="message"
											className="form-control form-control-lg"
											value={message}
											onChange={handleChange}
											rows={6}
											placeholder="Enter your feedback here..."
											disabled={isLoading}
										/>
									</div>
								</div>

								<div className="rw-container-buttons">
									<button type="submit" className="btn btn-primary" disabled={isLoading}>
										{!isLoading ? (
											<Fragment>
												<i className="far fa-comment-alt" />
												<span>Send Feedback</span>
											</Fragment>
										) : (
											<Fragment>
												<Spinner />
												<span>Sending...</span>
											</Fragment>
										)}
									</button>
								</div>
							</form>
						</div>
					</div>
				</Fragment>
			) : (
				<Fragment>
					<CenteredContainer>
						<LottiePlayer animation={letterAnimation} width={260} height={260} loop={true} />
						<h3>Thank You! Your feedback has been received!</h3>
					</CenteredContainer>
				</Fragment>
			)}
		</div>
	);
};

export default FeedbackForm;
