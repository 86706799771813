import { useEffect, useState } from 'react';
import { actionTypes } from '../../../../reducer';
import { useStateValue } from '../../../../StateProvider';
import Search from '../../../search/Search';
import './DomainSearch.css';
import DomainSearchList from './domainSearchList/DomainSearchList';

const DomainSearch = () => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ { domainSearchTerm }, dispatch ] = useStateValue();
	const [ tempSearchTerm, setTempSearchTerm ] = useState('');
	const [ searchTerm, setSearchTerm ] = useState('');

	useEffect(
		() => {
			if (domainSearchTerm) {
				let finalSearchTerm = domainSearchTerm.trim();
				if (
					finalSearchTerm &&
					finalSearchTerm !== '' &&
					finalSearchTerm.indexOf(' ') == -1 &&
					finalSearchTerm.indexOf('.') == -1
				) {
					finalSearchTerm = `${finalSearchTerm}.com`;
				}

				setSearchTerm(finalSearchTerm);
				setTempSearchTerm(finalSearchTerm);

				// reset the search term
				dispatch({
					type             : actionTypes.SET_DOMAIN_SEARCH_TERM,
					domainSearchTerm : null
				});
			}
		},
		[ domainSearchTerm ]
	);

	const handleSearchTermChange = (e) => {
		const term = e.target.value;

		setTempSearchTerm(term);
	};

	const runDomainSearch = async () => {
		let finalSearchTerm = tempSearchTerm.trim();

		if (finalSearchTerm && finalSearchTerm.length > 3) {
			if (
				finalSearchTerm.indexOf(' ') == -1 &&
				finalSearchTerm.indexOf('.') == -1
			) {
				finalSearchTerm = `${finalSearchTerm}.com`;
			}

			setSearchTerm(finalSearchTerm);
			setTempSearchTerm(finalSearchTerm);
		}
	};

	return (
		<div className="app-domain-search panel">
			<div className="domain-search-container">
				<div className="container text-left">
					<h5>
						<i class="fas fa-globe-americas" />
						<span>Search for a new domain</span>
					</h5>
					<div className="search-bar">
						<Search
							searchTerm={tempSearchTerm}
							handleChange={handleSearchTermChange}
							submitEvent={runDomainSearch}
							isDisabled={isLoading}
							buttonText="Search"
							placeholder="Enter a search term"
						/>
					</div>

					<div className="rw-search-results mt-4">
						<DomainSearchList
							searchTerm={searchTerm}
							setLoading={setIsLoading}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DomainSearch;
