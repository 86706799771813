import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { GetUserWebhooks } from '../../../../Services';
import './CrmWebhooks.css';
import CrmNavigation from '../crmNavigation/CrmNavigation';
import CrmWebhooksList from './crmWebhooksList/CrmWebhooksList';
import { Link } from 'react-router-dom';
import { useStateValue } from '../../../../StateProvider';

const CrmWebhooks = () => {
	const [ { brand }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ webhooks, setWebhooks ] = useState();
	const [ leadCreatedWebhooks, setLeadCreatedWebhooks ] = useState();
	const [ leadUpdatedWebhooks, setLeadUpdatedWebhooks ] = useState();
	const [ leadDeletedWebhooks, setLeadDeletedWebhooks ] = useState();

	useEffect(() => {
		// get user webhooks
		getUserWebhooks();
	}, []);

	useEffect(
		() => {
			// every time the webhooks list updates, update the individual lists
			if (webhooks && webhooks.length > 0) {
				setLeadCreatedWebhooks(
					webhooks.filter((x) => x.WebhookType === 'OnLeadCreated')
				);
				setLeadUpdatedWebhooks(
					webhooks.filter((x) => x.WebhookType === 'OnLeadUpdated')
				);
				setLeadDeletedWebhooks(
					webhooks.filter((x) => x.WebhookType === 'OnLeadDeleted')
				);
			} else {
				setLeadCreatedWebhooks();
				setLeadUpdatedWebhooks();
				setLeadDeletedWebhooks();
			}
		},
		[ webhooks ]
	);

	const getUserWebhooks = async () => {
		const response = await GetUserWebhooks();

		if (response.Success) {
			setWebhooks(response.Data);
		} else {
			toast.error(response.Message);
		}

		setIsLoading(false);
	};

	const handleUpdate = () => {
		setIsLoading(true);
		getUserWebhooks();
	};

	return (
		<div className="crm-webhooks panel">
			<CrmNavigation />

			<div className="container">
				<div className="alert alert-secondary">
					<p
					>{`Check out the documentation on how to submit leads from 3rd party applications into your ${brand.BrandName} account by clicking the link below.`}</p>

					<Link
						to="/crm/webhook-instructions"
						className="btn btn-primary"
					>
						Submit Leads Instructions
					</Link>
				</div>

				<h5 className="rw-container-title">
					<i className="fa-solid fa-route" />
					<span>Lead Created</span>
				</h5>
				<div className="alert alert-secondary">
					Triggers when a new lead is created.
				</div>

				{/* OnLeadCreated */}
				<CrmWebhooksList
					isLoading={isLoading}
					webhookType="OnLeadCreated"
					webhooks={leadCreatedWebhooks}
					onUpdate={handleUpdate}
				/>

				<h5 className="rw-container-title">
					<i className="fa-solid fa-route" />
					<span>Lead Updated</span>
				</h5>
				<div className="alert alert-secondary">
					Triggers when a lead is updated.
				</div>

				{/* OnLeadUpdated */}
				<CrmWebhooksList
					isLoading={isLoading}
					webhookType="OnLeadUpdated"
					webhooks={leadUpdatedWebhooks}
					onUpdate={handleUpdate}
				/>

				<h5 className="rw-container-title">
					<i className="fa-solid fa-route" />
					<span>Lead Deleted</span>
				</h5>
				<div className="alert alert-secondary">
					Triggers when a lead is deleted.
				</div>

				{/* OnLeadDeleted */}
				<CrmWebhooksList
					isLoading={isLoading}
					webhookType="OnLeadDeleted"
					webhooks={leadDeletedWebhooks}
					onUpdate={handleUpdate}
				/>
			</div>
		</div>
	);
};

export default CrmWebhooks;
