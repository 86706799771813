import { Fragment, useEffect, useState } from 'react';
import {
	GetUserInvoicesAdmin,
	DeleteInvoiceAdmin
} from '../../../../../Services';
import Spinner from '../../../../../spinner/Spinner';
import CenteredContainer from '../../../../centeredContainer/CenteredContainer';
import NoDataMessage from '../../../../noDataMessage/NoDataMessage';
import ModalDialog from '../../../../modalDialog/ModalDialog';
import ModalDialogContent from '../../../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogButtons from '../../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import './EditUserAccountInvoices.css';
import { toast } from 'react-toastify';
import EditUserInvoiceItem from './userInvoiceItem/EditUserInvoiceItem';
import { Link } from 'react-router-dom';

const EditUserAccountInvoices = ({ userDetails }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ invoices, setInvoices ] = useState([]);
	const [ invoiceToDelete, setInvoiceToDelete ] = useState(null);
	const [
		showDeleteConfirmationDialog,
		setShowDeleteConfirmationDialog
	] = useState(false);
	const [ isDeleting, setIsDeleting ] = useState(false);

	useEffect(() => {
		getAccInvoices();
	}, []);

	const getAccInvoices = async () => {
		let data = {
			UserGuid : userDetails.Guid
		};

		const response = await GetUserInvoicesAdmin(data);

		if (response.IsSuccessful) {
			setInvoices(JSON.parse(response.Data));
		}

		setIsLoading(false);
	};

	const handleDeleteClick = (invoice) => {
		setInvoiceToDelete(invoice);
	};

	const toggleShowDeleteConfirmationDialog = () => {
		setShowDeleteConfirmationDialog((prev) => !prev);
	};

	const handleCancelDelete = () => {
		setInvoiceToDelete(null);
		toggleShowDeleteConfirmationDialog();
	};

	useEffect(
		() => {
			if (invoiceToDelete) {
				setShowDeleteConfirmationDialog(true);
			}
		},
		[ invoiceToDelete ]
	);

	const handleDeleteInvoice = async () => {
		if (invoiceToDelete) {
			setIsDeleting(true);

			let data = {
				InvoiceId : invoiceToDelete.Guid
			};

			const response = await DeleteInvoiceAdmin(data);

			if (response.IsSuccessful) {
				setInvoiceToDelete(null);
				toggleShowDeleteConfirmationDialog();

				toast.success(response.Message);
				setIsLoading(true);
				await getAccInvoices();
			} else {
				toast.error(response.Message);
			}

			setIsDeleting(false);
		}
	};

	return (
		<div className="rw-container shadow-large acc-invoices">
			{!isLoading ? (
				<Fragment>
					{invoices && invoices.length > 0 ? (
						<Fragment>
							<div className="acc-invoices-list">
								<div className="rw-table">
									<div className="rw-table-header">
										<div className="rw-th invoice-description">
											Invoice Description
										</div>
										<div className="rw-th invoice-status">
											Status
										</div>
										<div className="rw-th invoice-amount">
											Amount
										</div>
										<div className="rw-th action" />
									</div>

									{/* invoices list */}
									{invoices.map((invoice) => (
										<EditUserInvoiceItem
											key={invoice.Guid}
											invoice={invoice}
											onDelete={handleDeleteClick}
										/>
									))}
								</div>
							</div>
						</Fragment>
					) : (
						<NoDataMessage message="No invoices found" />
					)}

					{showDeleteConfirmationDialog && (
						<ModalDialog>
							<ModalDialogContent>
								<h5>
									Are you sure you want to delete this
									invoice?
								</h5>
							</ModalDialogContent>
							<ModalDialogButtons shaded>
								<button
									type="button"
									className="btn btn-danger"
									onClick={handleDeleteInvoice}
									disabled={isDeleting}
								>
									{!isDeleting ? (
										<Fragment>
											<span>Delete Invoice</span>
										</Fragment>
									) : (
										<Fragment>
											<span>Deleting...</span>
										</Fragment>
									)}
								</button>
								<button
									type="button"
									className="btn btn-outline-primary"
									onClick={handleCancelDelete}
									disabled={isDeleting}
								>
									<span>Cancel</span>
								</button>
							</ModalDialogButtons>
						</ModalDialog>
					)}

					<div className="rw-container-buttons">
						<Link
							to={`/administration/invoices/create/${userDetails.Guid}`}
							className="btn btn-primary"
						>
							<i className="fas fa-receipt" />
							<span>Add New Invoice</span>
						</Link>
					</div>
				</Fragment>
			) : (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			)}
		</div>
	);
};

export default EditUserAccountInvoices;
