import { useEffect } from 'react';
import { Fragment } from 'react';
import { useState } from 'react';
import { GetCalendlyEvents } from '../../../../../Services';
import './CalendlyBookingsList.css';

const CalendlyBookingsList = ({ bookingsList }) => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ bookings, setBookings ] = useState(null);
	const [ isCalendlyConnected, setIsCalendlyConnected ] = useState(false);

	useEffect(() => {
		if (bookingsList && bookingsList.length > 0) {
			setBookings(bookingsList);
		} else {
			listEvents();
		}
	}, []);

	const listEvents = async () => {
		const response = await GetCalendlyEvents();

		setIsCalendlyConnected(response.IsCalendlyConnected);

		setIsLoading(false);
		console.log(response.Data);
	};

	return (
		<div>
			{bookings &&
			bookings.length > 0 && (
				<Fragment>
					<span>{bookings.length} total records</span>
				</Fragment>
			)}
		</div>
	);
};

export default CalendlyBookingsList;
