import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { actionTypes } from '../../../../reducer';
import { FindDomainSuggestions } from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import { useStateValue } from '../../../../StateProvider';
import MakeItLiveDomainSuggestion from './MakeItLiveDomainSuggestion';

const MakeItLiveFindDomain = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ { user, makeItLiveSettings }, dispatch ] = useStateValue();
	const [ domains, setDomains ] = useState([]);
	const [ selectedDomain, setSelectedDomain ] = useState(null);
	const { guid } = useParams();
	const history = useHistory();

	useEffect(() => {
		dispatch({
			type               : actionTypes.SET_MAKEITLIVE_SETTINGS,
			makeItLiveSettings : null
		});

		getDomainSuggestions();
	}, []);

	const getDomainSuggestions = async () => {
		const response = await FindDomainSuggestions();

		if (response.IsSuccessful) {
			let tempDomains = JSON.parse(response.Data);
			// console.table(tempDomains);

			setDomains(tempDomains);

			setSelectedDomain(tempDomains[0]);
		} else {
			toast.error(response.Message);
			history.push(`/make-it-live/${guid}/domain-search`);
		}

		setIsLoading(false);
	};

	const handleSelectedDomain = (domain) => {
		setSelectedDomain(domain);
	};

	const handleContinue = () => {
		let tempSettings = {
			domain                : selectedDomain,
			IsPrivateRegistration : false,
			IsAutoRenew           : true,
			card                  : null
		};

		dispatch({
			type               : actionTypes.SET_MAKEITLIVE_SETTINGS,
			makeItLiveSettings : { ...tempSettings }
		});

		if (user.FreeDomains && user.FreeDomains > 0) {
			history.push(`/make-it-live/${guid}/summary`);
		} else {
			// history.push(`/make-it-live/${guid}/privacy`);
			history.push(`/make-it-live/${guid}/card-select`);
		}
	};

	return (
		<div className="wizard-domain-suggestions wizard-step">
			{!isLoading ? (
				<Fragment>
					{domains && domains.length > 0 ? (
						<Fragment>
							<h3>
								We found some domains that you might like...
							</h3>
							<p className="text-muted">
								Select one of the suggested domains below and
								click continue, or {' '}
								<Link
									to={`/make-it-live/${guid}/domain-search`}
									className="app-link"
								>
									click here
								</Link>{' '}
								to search for more domain names
							</p>

							<div className="domain-suggestions-container">
								{domains.map((domain) => {
									if (domain.IsAvailable) {
										return (
											<MakeItLiveDomainSuggestion
												key={domain.DomainName}
												domain={domain}
												selectedDomain={selectedDomain}
												onSelect={handleSelectedDomain}
											/>
										);
									}
								})}
							</div>

							<div className="buttons-container">
								<button
									type="button"
									className="btn btn-primary"
									disabled={!selectedDomain}
									onClick={handleContinue}
								>
									<i className="fas fa-chevron-right" />
									<span>Continue</span>
								</button>
							</div>
							<div className="buttons-container mt-2">
								<Link
									to={`/make-it-live/${guid}/domain-search`}
									className="btn btn-link"
								>
									<span>
										<u>Click here</u> to start a new domain
										search
									</span>
								</Link>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<h3>
								Unfortunately we couldn't find any domains that
								match your company details
							</h3>
							<p className="text-muted">
								Click on the button below to open domain search
								and manually search for a domain
							</p>

							<div className="buttons-container">
								<Link
									to={`/make-it-live/${guid}/domain-search`}
									className="btn btn-primary"
								>
									<i className="fas fa-chevron-right" />
									<span>Continue</span>
								</Link>
							</div>
						</Fragment>
					)}
				</Fragment>
			) : (
				<Fragment>
					<h3>Please wait while we do a quick domain search...</h3>
					<p className="text-muted mb-3">
						We're checking for available domain names based on your
						company details
					</p>

					<Spinner />
				</Fragment>
			)}
		</div>
	);
};

export default MakeItLiveFindDomain;
