import { Fragment, useEffect } from 'react';
import { useStateValue } from '../../StateProvider';
import ModalDialog from '../modalDialog/ModalDialog';
import ModalDialogButtons from '../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../modalDialog/modalDialogContent/ModalDialogContent';
import './TrialDialog.css';

const TrialDialog = ({ closeDialog }) => {
	const [ { brand, user }, dispatch ] = useStateValue();

	const handleClose = () => {
		if (closeDialog) {
			closeDialog();
		}
	};

	useEffect(
		() => {
			setChatDetails();
		},
		[ user ]
	);

	const setChatDetails = () => {
		if (window.LC_API) {
			if (user && (user.Email || user.EmailAddress)) {
				let name = user.FirstName + ' ' + user.LastName;
				let email = user.Email
					? user.Email.toLowerCase()
					: user.EmailAddress.toLowerCase();

				try {
					window.LC_API.set_visitor_name(name);
					window.LC_API.set_visitor_email(email);
				} catch (e) {}
			}
		}
	};

	const handleChatOpen = () => {
		if (window.LC_API) {
			setChatDetails();

			setTimeout(
				() => {
					window.LC_API.open_chat_window();
				},
				[ 100 ]
			);
		}
	};

	return (
		<Fragment>
			<ModalDialog>
				<ModalDialogContent align="center">
					<h2>Start Your 30 Day Trial</h2>
					<p className="trial-text">
						The feature you're trying to use is available only to
						active subscribers. Let's start your 30 day trial so you
						can unlock all the features and benefits of an active
						subscriber.
					</p>

					<a className="btn-trial-dialog-close" onClick={handleClose}>
						<i className="fas fa-times" />
					</a>
				</ModalDialogContent>
				<ModalDialogButtons align="center">
					<a
						href={`https://app.${brand.BrandUrl}/r/activate`}
						className="btn btn-primary btn-lg btn-trial"
					>
						<i className="fas fa-chevron-right" />
						<span>Learn More</span>
					</a>

					<button
						type="button"
						className="btn btn-link d-block mt-3 btn-trial-chat"
						onClick={handleChatOpen}
					>
						<span>Need Help or Have Questions? (click here)</span>
					</button>
				</ModalDialogButtons>
			</ModalDialog>
		</Fragment>
	);
};

export default TrialDialog;
