import { Fragment, useContext, useEffect } from 'react';
import { ShepherdTourContext } from 'react-shepherd';
import { actionTypes } from '../../../reducer';
import { useStateValue } from '../../../StateProvider';
import TrialBanner from '../../trialBanner/TrialBanner';
import CancelledBanner from '../cancelledBanner/CancelledBanner';
import CartIcon from '../cart/CartIcon';
import ChatButton from './chatButton/ChatButton';
import LegacyButton from './legacyButton/LegacyButton';
import './MainAppHeader.css';
import ProfileInfo from './profileInfo/ProfileInfo';

const MainAppHeader = ({
	hideProfileInfo = false,
	hideCart = false,
	hideBanners = false,
	chatButtonText
}) => {
	const [ { user, brand, guidedTourSteps }, dispatch ] = useStateValue();
	const tour = useContext(ShepherdTourContext);

	useEffect(
		() => {
			if (tour && guidedTourSteps && guidedTourSteps.length > 0) {
				if (!tour.isActive()) {
					tour.start();
					tour.on('complete', handleTourComplete);
				}
			}

			return () => {
				if (tour) {
					tour.off('complete', handleTourComplete);
				}
			};
		},
		[ guidedTourSteps, tour ]
	);

	const handleTourComplete = () => {
		dispatch({
			type            : actionTypes.SET_GUIDED_TOUR_STEPS,
			guidedTourSteps : []
		});
		console.log('tour complete');
		localStorage.removeItem('rw-gtour');
	};

	return (
		<Fragment>
			{!hideBanners && (
				<Fragment>
					{user && (
						<Fragment>
							{user.Status === 'Canceled' && <CancelledBanner />}
							{user.Status === 'Trial' && <TrialBanner />}

							{user.IsLegacyUser &&
							user.Status === 'Active' && (
								<Fragment>
									<div className="container-fluid p-0">
										<LegacyButton />
									</div>
								</Fragment>
							)}
						</Fragment>
					)}
				</Fragment>
			)}

			<div className="app-header">
				<div className="app-logo">
					<img src={brand.BrandLogo} alt="Logo" />
				</div>
				{!hideCart && (
					<div className="app-header-cart">
						<CartIcon />
					</div>
				)}
				<div className="app-header-chat">
					<ChatButton chatButtonText={chatButtonText} />
				</div>
				{!hideProfileInfo && (
					<div className="app-profile-information">
						<ProfileInfo />
					</div>
				)}
			</div>
		</Fragment>
	);
};

export default MainAppHeader;
