import { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Spinner from '../../spinner/Spinner';
import CenteredContainer from '../centeredContainer/CenteredContainer';
import { countriesList } from '../../countries';
import cc from '../../img/cc.jpg';
import photoMatt from '../../img/matt-headshot.jpg';
import photoErin from '../../img/erin-headshot.jpg';
import photoMike from '../../img/mike-headshot-1.jpg';
import './CustomSetupOrderPage.css';
import { toast } from 'react-toastify';
import Testimonial from './testimonial/Testimonial';
import { useStateValue } from '../../StateProvider';
import { PayCustomSetup } from '../../Services';

const CustomSetupOrderPage = () => {
	const [ { brand }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const { userGuid } = useParams();
	const [ years, setYears ] = useState([]);
	const [ formData, setFormData ] = useState({});
	const [ agreeTerms, setAgreeTerms ] = useState(false);
	const [ agreeHostingTerms, setAgreeHostingTerms ] = useState(false);
	const [ animClass, setAnimClass ] = useState('xyz-in');
	const ccRegex = /^\d{16}$/g;
	const numbersOnlyRegexReplace = /[^0-9]/g;
	const [ isValid, setIsValid ] = useState(false);
	const history = useHistory();

	useEffect(() => {
		// tracking code
		window.trackEvent('PageView', 'Custom Setup Order Page');
		window.gtag('event', 'page_view', {
			page_title : 'Custom Setup Order Page',
			page_path  : '/custom-setup-order'
		});

		let currentYear = new Date().getFullYear();
		let tempYears = new Array();

		for (let i = currentYear; i < currentYear + 11; i++) {
			tempYears.push(i);
		}

		setYears(tempYears);

		setFormData({
			...formData,
			['expiryMonth'] : '1',
			['expiryYear']  : tempYears[0],
			firstName       : '',
			lastName        : '',
			email           : '',
			phone           : '',
			cardNumbers     : '',
			cvc             : '',
			zip             : '',
			userGuid        : userGuid
		});

		setIsLoading(false);
	}, []);

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.value;
		const checked = e.target.checked;

		console.log(target, value, checked);

		if (e.target.type !== 'checkbox') {
			setFormData({
				...formData,
				[target] : value
			});
		} else {
			setFormData({
				...formData,
				[target] : checked
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let data = {
			...formData,
			BrandId : brand.Id
		};

		console.log(data);

		if (!data.agreeTerms || !data.agreeHostingTerms) {
			toast.error('You must agree to all terms to continue');
			return;
		}

		if (!userGuid) {
			if (data.firstName.trim() === '' || data.lastName.trim() === '') {
				toast.error('First and Last name are required');
				return;
			}

			if (data.email.trim() === '') {
				toast.error('Email is required');
				return;
			}

			if (data.phone.trim() === '') {
				toast.error('Phone is required');
				return;
			}
		} else {
			data.firstName = '';
			data.lastName = '';
			data.email = '';
			data.country = '';
			data.phone = '';
		}

		if (data.cardNumbers.trim() === '') {
			toast.error('Credit card numbers are required');
			return;
		}

		if (data.cvc.trim() === '') {
			toast.error('CVC is required');
			return;
		}

		if (data.zip.trim() === '') {
			toast.error('Billing ZIP is required');
			return;
		}

		setIsSaving(true);

		const response = await PayCustomSetup(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);
		} else {
			toast.error(response.Message);

			if (response.Data && response.Data.trim() !== '') {
				let tempGuid = response.Data;

				if (tempGuid !== userGuid) {
					history.push(`/custom-setup/order/${tempGuid}`);
				}
			}
		}

		setIsSaving(false);
	};

	return (
		<div className="app-custom-setup-order panel">
			{!isLoading ? (
				<Fragment>
					<div
						className={`container container-order wizard-step wizard-activate-subscription ${animClass}`}
						xyz="fade perspective-4 down-4 origin-bottom small-3"
					>
						<h2 className="mb-0 font-weight-bold text-center display-title">
							Activate Your Subscription!
						</h2>

						<div className="container-fluid mt-5 how-it-works">
							<h3 className="text-left">
								Here's How It Works...
							</h3>
							<p>
								You only pay $1 for the first month of your
								website hosting subscription. If you're not
								completely satisfied with this service, simply
								email us within the next 30 days and we'll
								cancel your subscription on the spot. NO
								questions asked, and NO further obligations on
								your part and you get to keep the{' '}
								<u>FREE Gifts</u>*.
							</p>
							<p className="text-highlight">
								You will only be charged $1 today. After the
								first 30 days you will be automatically billed
								on a recurring monthly basis (every 30 days) for
								only $27. You can cancel at any time.
							</p>
						</div>

						<div className="alert alert-success mt-2 balance-due">
							<h3 className="m-0">Total Due Today is Just...</h3>
							<h3 className="balance-due-amount">$1.00</h3>
						</div>

						<form onSubmit={handleSubmit}>
							{/* user guid not specific, display customer detail inputs */}
							{!userGuid && (
								<Fragment>
									<h3 className="mt-4">
										Your Contact Details
									</h3>
									<hr />
									<div className="container-fluid">
										<div className="row mb-3">
											<div className="col-sm-12 col-lg-6">
												<div className="form-group">
													<label htmlFor="firstName">
														First Name:
													</label>
													<input
														type="text"
														className="form-control form-control-lg"
														id="firstName"
														name="firstName"
														placeholder="First Name"
														disabled={
															isLoading ||
															isSaving
														}
														value={
															formData.firstName
														}
														onChange={handleChange}
													/>
												</div>
											</div>

											<div className="col-sm-12 col-lg-6">
												<div className="form-group">
													<label htmlFor="lastName">
														Last Name:
													</label>
													<input
														type="text"
														className="form-control form-control-lg"
														id="lastName"
														name="lastName"
														placeholder="Last Name"
														disabled={
															isLoading ||
															isSaving
														}
														value={
															formData.lastName
														}
														onChange={handleChange}
													/>
												</div>
											</div>
										</div>

										<div className="row mb-3">
											<div className="col-sm-12">
												<div className="form-group">
													<label htmlFor="email">
														Email:
													</label>
													<input
														type="email"
														className="form-control form-control-lg"
														id="email"
														name="email"
														placeholder="Email"
														disabled={
															isLoading ||
															isSaving
														}
														value={formData.email}
														onChange={handleChange}
													/>
												</div>
											</div>
										</div>

										<div className="row mb-3">
											<div className="col-sm-12">
												<div className="form-group">
													<label htmlFor="phone">
														Phone:
													</label>
													<div className="input-group">
														<select
															className="form-control form-control-lg"
															style={{
																width : '30%'
															}}
															name="country"
															disabled={
																isLoading ||
																isSaving
															}
															value={
																formData.country
															}
															onChange={
																handleChange
															}
														>
															{countriesList.map(
																(country) => (
																	<option
																		key={
																			country.name
																		}
																		value={
																			country.name
																		}
																	>
																		{country.name}{' '}
																		({country.dial_code})
																	</option>
																)
															)}
														</select>
														<input
															onChange={
																handleChange
															}
															id="phone"
															name="phone"
															type="tel"
															disabled={
																isLoading ||
																isSaving
															}
															value={
																formData.phone
															}
															className="form-control form-control-lg"
															placeholder="Phone (format: 8005130456)"
															style={{
																width : '70%'
															}}
														/>
													</div>
												</div>
											</div>
										</div>
									</div>
								</Fragment>
							)}

							<h3>Billing Information</h3>

							<div className="text-center">
								<img
									src={cc}
									className="img-cc"
									alt="Credit Cards"
								/>
							</div>

							<div className="container-fluid">
								<div className="row">
									<div className="col-sm-12">
										<div className="form-group">
											<label
												htmlFor="cardNumbers"
												className="col-form-label"
											>
												Card Numbers:
											</label>
											<input
												onChange={handleChange}
												value={formData.cardNumbers}
												id="cardNumbers"
												name="cardNumbers"
												type="text"
												className="form-control form-control-lg"
												placeholder="XXXX XXXX XXXX XXXX"
												disabled={isSaving}
											/>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-sm-6">
										<div className="form-group">
											<label
												htmlFor="expiryMonth"
												className="col-form-label"
											>
												Expiry Month:
											</label>
											<select
												onChange={handleChange}
												value={formData.expiryMonth}
												id="expiryMonth"
												name="expiryMonth"
												className="form-control form-control-lg"
												disabled={isSaving}
											>
												<option value="1">
													01 - January
												</option>
												<option value="2">
													02 - February
												</option>
												<option value="3">
													03 - March
												</option>
												<option value="4">
													04 - April
												</option>
												<option value="5">
													05 - May
												</option>
												<option value="6">
													06 - June
												</option>
												<option value="7">
													07 - July
												</option>
												<option value="8">
													08 - August
												</option>
												<option value="9">
													09 - September
												</option>
												<option value="10">
													10 - October
												</option>
												<option value="11">
													11 - November
												</option>
												<option value="12">
													12 - December
												</option>
											</select>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="form-group">
											<label
												htmlFor="expiryYear"
												className="col-form-label"
											>
												Expiry Year:
											</label>
											<select
												onChange={handleChange}
												value={formData.expiryYear}
												id="expiryYear"
												name="expiryYear"
												className="form-control form-control-lg"
												disabled={isSaving}
											>
												{years.length > 0 && (
													<Fragment>
														{years.map((year) => (
															<option
																key={year}
																value={year}
															>
																{year}
															</option>
														))}
													</Fragment>
												)}
											</select>
										</div>
									</div>
								</div>
								<div className="row mb-3">
									<div className="col-sm-6">
										<div className="form-group">
											<label
												htmlFor="cvc"
												className="col-form-label"
											>
												CVC:
											</label>
											<input
												onChange={handleChange}
												value={formData.cvc}
												id="cvc"
												name="cvc"
												type="text"
												className="form-control form-control-lg"
												placeholder="CVC"
												disabled={isSaving}
											/>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="form-group">
											<label
												htmlFor="zip"
												className="col-form-label"
											>
												Billing ZIP:
											</label>
											<input
												onChange={handleChange}
												value={formData.zip}
												id="zip"
												name="zip"
												type="text"
												className="form-control form-control-lg"
												placeholder="Billing ZIP"
												disabled={isSaving}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="container-fluid">
								<div className="terms-agreement mt-4 mb-4">
									<div className="form-check mb-3">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="chkAgreeTerms"
											name="agreeTerms"
											onChange={handleChange}
										/>
										<label
											className="form-check-label"
											htmlFor="chkAgreeTerms"
										>
											<strong>I agree</strong> to the
											terms of the $1 offer as explained
											in the how it works section above
										</label>
									</div>
									<div className="form-check">
										<input
											className="form-check-input"
											type="checkbox"
											value=""
											id="chkAgreeHostingTerms"
											name="agreeHostingTerms"
											onChange={handleChange}
										/>
										<label
											className="form-check-label"
											htmlFor="chkAgreeHostingTerms"
										>
											<strong>I agree</strong> to the{' '}
											<a
												className="app-link"
												href="/terms"
												target="_blank"
											>
												hosting agreement
											</a>{' '}
											terms and conditions
										</label>
									</div>
								</div>
							</div>

							<div className="buttons-container">
								<button
									type="submit"
									className="btn-pay"
									disabled={isLoading || isSaving}
								>
									{!isSaving ? (
										<Fragment>
											<span>Buy Now</span>
										</Fragment>
									) : (
										<Fragment>
											<Spinner />
											<span>Buying...</span>
										</Fragment>
									)}
								</button>
							</div>

							<div className="text-center mt-4 order-secure">
								<i className="fas fa-lock" />
								<span>Your order is 100% safe and secure</span>
							</div>
						</form>
					</div>

					<div className="container container-testimonials mt-5">
						<h3 className="text-center mb-5">
							What People Are Saying
						</h3>

						<div className="testimonials-wrapper">
							<Testimonial
								picture={photoMike}
								name="Mike Sylvan"
								location="Las Vegas, NV"
								testimonial="I had my site up and ready for business in less than 15 minutes... and I don't know anything about websites!"
							/>

							<Testimonial
								picture={photoMatt}
								name="Matt Gerchow"
								location="Austin, TX"
								testimonial="Super easy to do, and the live chat support is awesome! I love my website!"
							/>

							<Testimonial
								picture={photoErin}
								name="Ein Melia"
								location="Sarasota, FL"
								testimonial="I've wasted hours on others so called easy website builders, and they never look good. My RealWebsite site looks great!"
							/>
						</div>
					</div>

					<div className="container container-security pt-5 text-left">
						<div className="row">
							<div className="col-md-6 col-sm-12">
								<p>
									<strong>Privacy</strong>
								</p>
								<p className="mt-2">
									We will not share or trade online
									information that you provide us (including
									e-mail addresses).
								</p>
							</div>
							<div className="col-md-6 col-sm-12">
								<p>
									<strong>Security</strong>
								</p>
								<p className="mt-2">
									All personal information you submit is
									encrypted and secure. This website utilizes
									some of the most advanced techniques to
									protect your information and personal data
									including technical, administrative and even
									physical safeguards against unauthorized
									access, misuse and improper disclosure.
								</p>
							</div>
						</div>
					</div>

					<div className="container container-asterix pt-5 text-left">
						<p>
							* With your subscription you have access to our
							training library of videos, and our live chat
							support and support tickets. If you need help with
							customizing your site or adding content you can ask
							our support team and they will gladly help. Any
							complex coding or web development requests can be
							completed via projects at our standard hourly
							development rate of $75/hr.
						</p>
						<p>
							** If you take advantage of this offer you'll
							receive order credits to purchase a domain for your
							website if you need one. You can also transfer and
							apply your own domain. If you decide to cancel your
							account within 30 days before you first monthly
							payment of $27, you still get to keep the domain
							however you cannot transfer it out of your account
							with us for 90 days as per domain registration
							restrictions.
						</p>
						<p>
							*** When you start your 30 day trial you will
							receive 3 gifts; 1) a free domain 2) our quick start
							marketing guide 3) a free 30 minute consultation
							with one of our marketing experts. You can cancel
							your trial or subscription at any time and you get
							to keep the gifts. However, the domain cannot be
							transferred to another web hosting provider for at
							least 60 days. During that 60 days you are not able
							to change the name servers, but you CAN create host
							records and point the domain to another website.
						</p>
					</div>
				</Fragment>
			) : (
				<Fragment>
					<CenteredContainer>
						<Spinner />
					</CenteredContainer>
				</Fragment>
			)}
		</div>
	);
};

export default CustomSetupOrderPage;
