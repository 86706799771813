import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { actionTypes } from '../../../../../../reducer';
import { useStateValue } from '../../../../../../StateProvider';

const WebsiteWizardSetupWizardBusinessPhone = () => {
	const [ formData, setFormData ] = useState({
		businessPhone : ''
	});
	const [ { websiteWizardSetupData }, dispatch ] = useStateValue();
	const { preconfigId, websiteId } = useParams();
	const history = useHistory();
	const inputRef = useRef();

	useEffect(
		() => {
			if (inputRef && inputRef.current) {
				inputRef.current.focus();
			}
		},
		[ inputRef ]
	);

	useEffect(
		() => {
			if (
				websiteWizardSetupData &&
				websiteWizardSetupData.businessPhone
			) {
				setFormData({
					...formData,
					businessPhone : websiteWizardSetupData.businessPhone
				});
			}
		},
		[ websiteWizardSetupData ]
	);

	const handleChange = (e) => {
		const target = e.target.name;
		const value =
			e.target.type == 'checkbox' ? e.target.checked : e.target.value;

		setFormData({
			...formData,
			businessPhone : value
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		dispatch({
			type                   : actionTypes.SET_WEBSITE_WIZARD_SETUP_DATA,
			websiteWizardSetupData : {
				...websiteWizardSetupData,
				...formData
			}
		});

		if (preconfigId) {
			history.push(
				`/website-setup-wizard/${preconfigId}/business-address`
			);
		} else {
			history.push(`/configure-website/${websiteId}/business-address`);
		}
	};

	return (
		<div className="container setup-wizard-step xyz-in">
			<h5 class="rw-container-title">
				<span>Enter Your Business Phone</span>
			</h5>

			<form onSubmit={handleSubmit}>
				<div className="form-inputs">
					<div className="input-group">
						<InputMask
							mask="(999) 999-9999"
							value={formData.businessPhone}
							onChange={handleChange}
						>
							<input
								ref={inputRef}
								type="tel"
								className="form-control form-control-lg"
								name="businessPhone"
								placeholder="Example: (800) 555-1234"
							/>
						</InputMask>
					</div>
				</div>

				<button type="submit" className="btn btn-primary">
					<span>Continue</span>
				</button>

				<Link onClick={() => history.goBack()} className="btn btn-link">
					<span>Back</span>
				</Link>
			</form>
		</div>
	);
};

export default WebsiteWizardSetupWizardBusinessPhone;
