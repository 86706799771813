import { Fragment, useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GetUserDetails, GetWebsiteDetails } from '../../../Services';
import Spinner from '../../../spinner/Spinner';
import CenteredContainer from '../../centeredContainer/CenteredContainer';
import ModalDialogContent from '../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogFullScreen from '../../modalDialog/ModalDialogFullScreen';
import MakeItLiveStart from './wizardSteps/MakeItLiveStart';
import './MakeItLiveWizard.css';
import MakeItLiveHaveDomain from './wizardSteps/MakeItLiveHaveDomain';
import MakeItLiveFindDomain from './wizardSteps/MakeItLiveFindDomain';
import { useStateValue } from '../../../StateProvider';
import { actionTypes } from '../../../reducer';
import MakeItLivePrivacy from './wizardSteps/MakeItLivePrivacy';
import MakeItLiveAutoRenew from './wizardSteps/MakeItLiveAutoRenew';
import MakeItLiveExistingDomain from './wizardSteps/MakeItLiveExistingDomain';
import MakeItLiveApplyDomain from './wizardSteps/MakeItLiveApplyDomain';
import MakeitLiveSelectCard from './wizardSteps/MakeItLiveSelectCard';
import MakeItLiveSummary from './wizardSteps/MakeItLiveSummary';
import MakeItLiveDomainSearch from './wizardSteps/MakeItLiveDomainSearch';
import MakeItLiveDomainSearchResults from './wizardSteps/MakeItLiveDomainSearchResults';

const MakeItLiveWizard = () => {
	const [ { user, makeitLiveSettings }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ website, setWebsite ] = useState(null);
	const match = useRouteMatch();
	const history = useHistory();

	useEffect(() => {
		dispatch({
			type               : actionTypes.SET_MAKEITLIVE_SETTINGS,
			makeitLiveSettings : null
		});

		getWebsiteDetails();
		getUserDetails();
	}, []);

	const getWebsiteDetails = async () => {
		const guid = match.params.guid;

		let data = {
			Guid : guid
		};

		const response = await GetWebsiteDetails(data);

		if (response.IsSuccessful) {
			let tempWebsite = JSON.parse(response.Data);
			console.log(tempWebsite);

			setWebsite(tempWebsite);

			dispatch({
				type               : actionTypes.SET_MAKEITLIVE_SETTINGS,
				makeitLiveSettings : {
					website : tempWebsite
				}
			});
		} else {
			toast.error(response.Message);
		}

		setIsLoading(false);
	};

	const getUserDetails = async () => {
		const tempUser = await GetUserDetails();

		if (!tempUser.IsSuccessful) {
			history.push('/login');
		} else {
			dispatch({
				type : actionTypes.SET_USER,
				user : JSON.parse(tempUser.Data)
			});
		}
	};

	const handleChatOpen = () => {
		if (window.LC_API) {
			if (user && user.Email) {
				let name = user.FirstName + ' ' + user.LastName;
				let email = user.Email.toLowerCase();

				window.LC_API.set_visitor_name(name);
				window.LC_API.set_visitor_email(email);
			}
			window.LC_API.open_chat_window();
		}
	};

	return (
		<Fragment>
			<ModalDialogFullScreen>
				{/* <ModalDialogContent> */}
				<div className="domain-apply-wizard">
					{!isLoading ? (
						<Fragment>
							{website ? (
								<Fragment>
									<div className="btn-back-container">
										<button
											type="button"
											className="btn btn-outline-primary btn-back"
											onClick={() => history.goBack()}
										>
											<i
												className="fas fa-chevron-left"
												aria-hidden="true"
											/>
											<span>Go Back</span>
										</button>
									</div>

									<div className="wizard-menu">
										<button
											className="btn btn-link btn-app-chat"
											onClick={handleChatOpen}
										>
											<span>
												Need help? <u>Click here</u>
											</span>
										</button>

										<button
											type="button"
											className="btn btn-outline-secondary btn-close-wizard"
											onClick={() => history.push('/')}
										>
											<i className="fas fa-times m-0" />
										</button>
									</div>

									<div className="app-make-it-live-wizard wizard-container">
										<Switch>
											<Route
												path={`${match.path}/apply-domain`}
											>
												<MakeItLiveApplyDomain />
											</Route>
											<Route
												path={`${match.path}/summary`}
											>
												<MakeItLiveSummary />
											</Route>
											<Route
												path={`${match.path}/card-select`}
											>
												<MakeitLiveSelectCard />
											</Route>
											<Route
												path={`${match.path}/auto-renew`}
											>
												<MakeItLiveAutoRenew />
											</Route>
											<Route
												path={`${match.path}/privacy`}
											>
												<MakeItLivePrivacy />
											</Route>
											<Route
												path={`${match.path}/search-results`}
											>
												<MakeItLiveDomainSearchResults
												/>
											</Route>
											<Route
												path={`${match.path}/domain-search`}
											>
												<MakeItLiveDomainSearch />
											</Route>
											<Route
												path={`${match.path}/find-domain`}
											>
												<MakeItLiveFindDomain />
											</Route>
											<Route
												path={`${match.path}/add-domain`}
											>
												<MakeItLiveExistingDomain />
											</Route>
											<Route
												path={`${match.path}/have-domain`}
											>
												<MakeItLiveHaveDomain />
											</Route>
											<Route
												path={`${match.path}`}
												exact={true}
											>
												<MakeItLiveStart />
											</Route>
										</Switch>
									</div>
								</Fragment>
							) : (
								<Fragment>
									<CenteredContainer>
										<div className="mb-2">
											Website not found
										</div>
										<Link
											to="/"
											className="btn btn-primary"
										>
											<span>Close Wizard</span>
										</Link>
									</CenteredContainer>
								</Fragment>
							)}
						</Fragment>
					) : (
						<CenteredContainer>
							<Spinner />
						</CenteredContainer>
					)}
				</div>
				{/* </ModalDialogContent> */}
			</ModalDialogFullScreen>
		</Fragment>
	);
};

export default MakeItLiveWizard;
