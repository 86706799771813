import { Fragment, useEffect, useState } from 'react';
import ModalDialog from '../modalDialog/ModalDialog';
import ModalDialogButtons from '../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../modalDialog/modalDialogContent/ModalDialogContent';
import './ConfirmationDialog.css';

const ConfirmationDialog = ({ children, onConfirm, onCancel, confirmBtnClassName }) => {
	const [ confirmClassName, setConfirmClassName ] = useState('primary');

	useEffect(
		() => {
			if (confirmBtnClassName && confirmBtnClassName.trim() !== '') {
				setConfirmClassName(confirmBtnClassName);
			}
		},
		[ confirmBtnClassName ]
	);

	const handleConfirm = () => {
		if (onConfirm) {
			onConfirm();
		}
	};

	const handleCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	return (
		<Fragment>
			<ModalDialog>
				<ModalDialogContent>{children}</ModalDialogContent>
				<ModalDialogButtons shaded>
					<button type="button" className={`btn btn-${confirmClassName}`} onClick={handleConfirm}>
						<i className="fas fa-check" />
						<span>Yes</span>
					</button>
					<button type="button" className="btn btn-outline-primary" onClick={handleCancel}>
						<i className="fas fa-chevron-left" />
						<span>Cancel</span>
					</button>
				</ModalDialogButtons>
			</ModalDialog>
		</Fragment>
	);
};

export default ConfirmationDialog;
