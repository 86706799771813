import { Fragment } from 'react';
import Lottie from 'react-lottie';

const LottiePlayer = ({ animation, width = 150, height = 150, loop = false }) => {
	return (
		<Fragment>
			<Lottie
				options={{
					loop          : loop,
					autoplay      : true,
					animationData : animation
				}}
				width={width}
				height={height}
			/>
		</Fragment>
	);
};

export default LottiePlayer;
