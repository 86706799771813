const TermsAndConditions = () => {
	return (
		<div className="terms-and-conditions">
			<p>
				<strong>WEB HOSTING AGREEMENT</strong>
			</p>
			<p>
				This Hosting Agreement (this “Agreement”) is made and entered into by and between 375 LLC D/B/A
				RealWebsite, a Florida corporation (the “Host”), and You, Your heirs, agents, successors and assigns
				(“Client”) (each being referred to individually as a “Party” and collectively as the “Parties”), and is
				made effective as of the date of electronic execution.
			</p>
			<p>
				<strong>RECITALS</strong>
				{` `}
				A. Host is in the business of offering Internet services relating to, among other things, hosting of
				sites on the World Wide Web portion of the Internet, and Host is willing to provide services to Client
				on the terms and subject to the conditions set forth below; and B. Client desires to engage Host, and
				Host desires to be engaged by Client, to provide Internet services on the terms and subject to the
				conditions set forth below.
			</p>
			<p>
				<strong>NOW, THEREFORE</strong>, in consideration of the mutual promises set forth herein, and for other
				good and valuable consideration, the receipt and sufficiency of which is hereby acknowledged, Host and
				Client hereby agree as follows:
			</p>
			<p>
				<strong>TERMS</strong>
				{` `}
				This Agreement shall be effective when signed by both Parties (the "Effective Date") and thereafter
				shall remain in effect for one (1) year, unless earlier terminated as otherwise provided in this
				Agreement (the “Initial Term”). This Agreement shall automatically be renewed beyond the Initial Term
				for additional one (1) year terms (each a “Renewal Term”) unless earlier terminated as otherwise
				provided in this Agreement.
			</p>
			<p>
				<strong>SERVICES Hosting Services</strong>
				{` `}
				1. Host agrees to provide Client with services for hosting of a website on the World Wide Web portion of
				the Internet (the “Website”) as set forth or described in SERVICES TO BE PROVIDED hereto (the “Hosting
				Services”). Host shall provide the Hosting Services so that the Website is accessible to third Parties
				via the World Wide Web portion of the Internet as specified in this Agreement. 2. Except as expressly
				provided in this Agreement, Client agrees that Host is responsible only for providing the Hosting
				Services, and Host is not responsible for providing any other services or tasks not specifically set
				forth in SERVICES TO BE PROVIDED. 3. To the extent that Client wishes to receive from Host, and Host
				wishes to provide to Client, services other than the Hosting Services (collectively the “Additional
				Services”), such Additional Services and the arrangements for their provision shall be set forth in a
				separate addendum to this Agreement which is duly executed by the Parties (the “Services Addendum”), and
				the Services Addendum shall be incorporated into, and become a part of this Agreement. (The Hosting
				Services and the Additional Services will hereinafter be referred to collectively as the “Services”).
			</p>
			<p>
				<strong>Availability of Website</strong>
				{` `}
				Unless otherwise expressly indicated on SERVICES TO BE PROVIDED hereto, the Website shall be accessible
				to third Parties via the World Wide Web portion of the Internet twenty-four (24) hours a day, seven (7)
				days a week, except for scheduled maintenance and required repairs, and except for any loss or
				interruption of Hosting Services due to causes beyond the control of Host or which are not reasonably
				foreseeable by Host, including, but not limited to, interruption or failure of telecommunication or
				digital transmission links and Internet slow-downs or failures.{' '}
			</p>
			<p>
				<strong>Additional Storage and Transfer</strong>
				{` `}
				In the event that the Website requires storage and transfer on the Host Server which exceeds the amount
				of storage included in the Hosting Services, Client may request that Host: 1. Upgrade the level of
				Hosting Services; or 2. Acquire additional incremental storage to be included in the Hosting Services,
				on a time and materials basis and in accordance with the fee and payment schedule in SERVICES TO BE
				PROVIDED. Host shall review all such requests and determine, in consultation with Client, whether it can
				reasonably comply with such requests, and if so, and at its option, Host shall propose a procedure and
				budget for complying with such request.
			</p>
			<p>
				<strong>FEEES AND TAXES Hosting Services Fees</strong>
				{` `}
				Client shall pay Host all fees for the Hosting Services in accordance with the applicable fee and
				payment schedule set forth in SERVICES TO BE PROVIDED. Host expressly reserves the right to change its
				rates charged hereunder for the Services during any Renewal Term (as defined herein).
			</p>
			<p>
				<strong>Additional Services Fees</strong>
				{` `}
				Unless otherwise agreed in writing, Client shall pay to Host all fees for Additional Services on a time
				and materials basis as invoiced by Host.
			</p>
			<p>
				<strong>WARRANTIES Host Warranties</strong>
				{` `}
				Hosts represents and warrants that: 1. Host has the power and authority to enter into and perform its
				obligations under this Agreement; and 2. Host’s services under this Agreement shall be performed in a
				workmanlike manner.
			</p>
			<p>
				<strong>Client Warranties</strong>
				{` `}
				Client represents and warrants that: 1. Client has the power and authority to enter into and perform its
				obligations under this Agreement; 2. Client Content does not and shall not contain any content,
				materials, advertising or services that are inaccurate or that infringe on or violate any applicable
				law, regulation or right of a third Party, including, without limitation, export laws, or any
				proprietary, contract, moral, or privacy right or any other third Party right, and that Client owns the
				Client Content or otherwise has the right to place the Client Content on the Website; 3. Client’s use of
				its Website will not violate any law or regulation of any governmental or regulatory/administrative
				entity; and, 4. Client has obtained any authorization(s) necessary for hypertext links from the Website
				to other third Party Websites. Should Client receive notice of a claim regarding the Website, Client
				shall promptly provide Host with written notice of such claim.
			</p>
			<p>
				<strong>Client Restrictions</strong>
				{` `}
				Client represents and warrants that it shall not use the Website or the Services provided to Client to:
				1. Send unsolicited mail messages, including the sending of "junk mail" or other advertising material to
				individuals who did not specifically request such material, who were not previous customers of CLIENT or
				with whom CLIENT does not have an existing business relationship ("E-mail spam"); 2. Engage in harassing
				behavior, whether through language, frequency or size of email message; 3. Use without authorization or
				forge e-mail header information; 4. Solicit mail for any other e-mail address other than that of the
				Client’s account with the intent to harass or to collect replies; 5. Create or forwarding "chain
				letters" or other "pyramid schemes" of any type; and/or, 6. Use unsolicited e-mail originating from
				within Host’s network or the networks of other Internet Service Providers.
			</p>
			<p>
				<strong>INDEMNIFICATION Client</strong>
				{` `}
				Client agrees to indemnify, defend, and hold harmless Host, its directors, officers, employees and
				agents, and defend any action brought against same with respect to any claim, demand, cause of action,
				debt or liability, including reasonable attorneys' fees, at trial and on appeal, to the extent that such
				action is based upon a claim that: 1. If true, would constitute a breach of any of Client’s
				representations, warranties, or agreements hereunder; 2. Arises out of the negligence or willful
				misconduct of Client; or, 3. Any of the Client Content to be provided by Client hereunder or other
				material on the Website infringes or violates any rights of third Parties; including, without
				limitation, rights of publicity, rights of privacy, patents, copyrights, trademarks, trade secrets,
				and/or licenses.
			</p>
			<p>
				<strong>Host</strong>
				{` `}
				Host agrees to indemnify, defend, and hold harmless Client, its directors, officers, employees and
				agents, and defend any action brought against same with respect to any claim, demand, cause of action,
				debt or liability, including reasonable attorneys' fees, at trial and on appeal, to the extent that such
				action arises out of the gross negligence or willful misconduct of Host.
			</p>
			<p>
				<strong>Notice</strong>
				{` `}
				In claiming any indemnification hereunder, the indemnified Party shall promptly provide the in
				indemnifying Party with written notice of any claim which the indemnified Party believes falls within
				the scope of the foregoing paragraphs. The indemnified Party may, at its own expense, assist in the
				defense if it so chooses, provided that the indemnifying Party shall control such defense and all
				negotiations relative to the settlement of any such claim and further provided that any settlement
				intended to bind the indemnified Party shall not be final without the indemnified Party’s written
				consent, which shall not be unreasonably withheld.
			</p>
			<p>
				<strong>LIMITATION OF LIABILITY</strong>
				{` `}
				HOST SHALL HAVE NO LIABILITY FOR UNAUTHORIZED ACCESS TO, OR ALTERATION, THEFT OR DESTRUCTION OF, THE
				WEBSITE OR CLIENT’S DATA FILES, PROGRAMS OR INFORMATION THROUGH ACCIDENT, FRAUDULENT OR UNAUTHORIZED
				MEANS OR DEVICES. HOST SHALL HAVE NO LIABILITY WITH RESPECT TO HOST’S OBLIGATIONS UNDER THIS AGREEMENT
				OR OTHERWISE FOR CONSEQUENTIAL, EXEMPLARY, SPECIAL, INCIDENTAL, OR PUNITIVE DAMAGES EVEN IF HOST HAS
				BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN ANY EVENT, THE LIABILITY OF HOST TO CLIENT FOR ANY
				REASON AND UPON ANY CAUSE OF ACTION SHALL BE LIMITED TO THE AMOUNT ACTUALLY PAID TO HOST BY CLIENT UNDER
				THIS AGREEMENT. THIS LIMITATION APPLIES TO ALL CAUSES OF ACTION IN THE AGGREGATE, INCLUDING, WITHOUT
				LIMITATION, TO BREACH OF CONTRACT, BREACH OF WARRANTY, NEGLIGENCE, STRICT LIABILITY, MISREPRESENTATIONS,
				AND OTHER TORTS.
			</p>
			<p>
				<strong>AMENDMANT</strong>
				{` `}
				No amendment, waiver, or modification of this Agreement or any provision of this Agreement shall be
				valid unless in writing, stating with specificity the particular amendment or modification to be made,
				and duly executed by the Host and Client.
			</p>
			<p>
				<strong>ENFORCEABILITY</strong>
				{` `}
				If any provision of this Agreement shall be held invalid or unenforceable by any court of competent
				jurisdiction, administrative agency or as a result of future legislative action, such holding or action
				shall be strictly construed and the unenforceable provision shall be deemed severable from the remainder
				of the Agreement to the extent permitted by law, and the validity or effect of any other provision of
				this Agreement shall remain binding with the same effect as though the void parts were deleted.
			</p>
			<p>
				<strong>PRIVACY</strong>
				<br />
				<strong>Client Data Privacy</strong>
				{` `}
				All data stored on our servers by the client, or by clients site visitors, for example via feedback
				forms, is strictly confidential and is NOT shared, harvested, or made available to ANY third party for
				any reason.
			</p>
		</div>
	);
};

export default TermsAndConditions;
