import { Fragment, useEffect, useState } from 'react';
import Spinner from '../../../../../../spinner/Spinner';
import './Subdomain.css';

const Subdomain = ({ subdomain, domain }) => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isEditMode, setIsEditMode ] = useState(false);
	const [ editAllowed, setEditAllowed ] = useState(false);
	const [ subdomainDetails, setSubdomainDetails ] = useState(null);

	useEffect(
		() => {
			if (subdomain && domain) {
				setSubdomainDetails({
					...subdomain,
					Name : subdomain.DomainName.replace('.' + domain.DomainName, '')
				});
			}
		},
		[ subdomain, domain ]
	);

	const toggleEditMode = () => {
		setIsEditMode((prev) => !prev);
	};

	const handleCancel = () => {
		setSubdomainDetails({
			...subdomain,
			Name : subdomain.DomainName.replace('.' + domain.DomainName, '')
		});
		toggleEditMode();
	};

	const handleChange = (e) => {
		setSubdomainDetails({
			...subdomainDetails,
			Name : e.target.value.trim()
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);
	};

	return (
		<Fragment>
			{subdomainDetails && (
				<Fragment>
					<div className="rw-row">
						<div className="rw-td name">
							<span>
								{subdomainDetails.Name}.{domain.DomainName}
							</span>
						</div>

						{editAllowed && (
							<div className="rw-td action">
								{!isEditMode && (
									<button type="button" className="btn btn-primary" onClick={toggleEditMode}>
										<i className="fas fa-pencil-alt" />
										<span>Edit</span>
									</button>
								)}
							</div>
						)}
					</div>
				</Fragment>
			)}

			{isEditMode && (
				<Fragment>
					<div className="edit-subdomain-record">
						<form onSubmit={handleSubmit} autoComplete="off">
							<label htmlFor="subdomainName">Subdomain Name</label>
							<div className="input-group">
								<input
									name="Name"
									type="text"
									id="subdomainName"
									className="form-control form-control-lg"
									placeholder="Example: sales"
									disabled={isSaving}
									value={subdomainDetails.Name}
									onChange={handleChange}
								/>
								<div class="input-group-append">
									<span className="input-group-text">.{domain.DomainName.toLowerCase()}</span>
								</div>
							</div>
							<div className="buttons-container">
								<button type="submit" className="btn btn-primary" disabled={isSaving}>
									{!isSaving ? (
										<Fragment>
											<i className="fas fa-save" />
											<span>Save</span>
										</Fragment>
									) : (
										<Fragment>
											<Spinner />
											<span>Saving Subdomain...</span>
										</Fragment>
									)}
								</button>

								<button
									type="button"
									className="btn btn-outline-primary"
									disabled={isSaving}
									onClick={handleCancel}
								>
									<i className="fas fa-chevron-left" />
									<span>Cancel</span>
								</button>
							</div>
						</form>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default Subdomain;
