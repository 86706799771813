import { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory, useParams, useRouteMatch } from 'react-router';
import { toast } from 'react-toastify';
import { actionTypes } from '../../../../../../reducer';
import {
	CreateWebsite,
	GetPreconfiguredWebsite,
	GetWebsiteDetails
} from '../../../../../../Services';
import Spinner from '../../../../../../spinner/Spinner';
import { useStateValue } from '../../../../../../StateProvider';

const WebsiteWizardSetupWizardComplete = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const { path, url } = useRouteMatch();
	const { preconfigId, websiteId } = useParams();
	const [ { websiteWizardSetupData }, dispatch ] = useStateValue();
	const screenshotRef = useRef();
	const [ screenshotStyle, setScreenshotStyle ] = useState('');
	const [ preconfigData, setPreconfigData ] = useState();
	const history = useHistory();

	useEffect(() => {
		if (preconfigId) {
			handlePreconfigCase();
		} else if (websiteId) {
			handleWebsiteCase();
		}
	}, []);

	useEffect(
		async () => {
			if (preconfigData) {
				setIsSaving(true);
				const createResponse = await CreateWebsite(preconfigData);

				if (createResponse.IsSuccessful) {
					history.push(
						`/website-setup-wizard/${preconfigId}/success`
					);
				} else {
					toast.error(createResponse.Message);
				}

				setIsSaving(false);
			}
		},
		[ preconfigData ]
	);

	const handlePreconfigCase = async () => {
		// get the information about the preconfig
		let data = {
			Guid : preconfigId
		};

		const response = await GetPreconfiguredWebsite(data);

		if (response.IsSuccessful) {
			// show image, install website, etc.
			const responseData = JSON.parse(response.Data);

			let screenshotUrl = responseData.ScreenshotUrl;

			if (screenshotUrl) {
				let tempScreenshotStyle = `url("/${screenshotUrl}")`;

				if (screenshotUrl.startsWith('https:')) {
					tempScreenshotStyle = `url("${screenshotUrl}")`;
				}

				setScreenshotStyle(tempScreenshotStyle);
			}

			let websiteData = {
				PreConfigId       : responseData.PreconfigId,
				WebsiteName       : websiteWizardSetupData.businessName,
				CompanyName       : websiteWizardSetupData.businessName,
				CompanyPhone      : websiteWizardSetupData.businessPhone
					? websiteWizardSetupData.businessPhone
					: '',
				CompanyAddress1   : websiteWizardSetupData.businessAddress
					? websiteWizardSetupData.businessAddress
					: '',
				CompanyCity       : websiteWizardSetupData.businessCity
					? websiteWizardSetupData.businessCity
					: '',
				CompanyState      : websiteWizardSetupData.businessState
					? websiteWizardSetupData.businessState
					: '',
				CompanyPostalCode : websiteWizardSetupData.businessZIP
					? websiteWizardSetupData.businessZIP
					: ''
			};

			setIsLoading(false);
			setPreconfigData(websiteData);
			return;
		} else {
			toast.error(response.Message);
		}

		setIsLoading(false);
	};

	const handleWebsiteCase = async () => {
		// get the information about the preconfig
		let data = {
			Guid : websiteId
		};

		const response = await GetWebsiteDetails(data);

		console.log('website response:', response);

		if (response.IsSuccessful) {
			// show image, install website, etc.
			const responseData = JSON.parse(response.Data);

			let screenshotUrl = responseData.ScreenshotUrl;

			if (screenshotUrl) {
				let tempScreenshotStyle = `url("/${screenshotUrl}")`;

				if (screenshotUrl.startsWith('https:')) {
					tempScreenshotStyle = `url("${screenshotUrl}")`;
				}

				setScreenshotStyle(tempScreenshotStyle);
			}
		} else {
			toast.error(response.Message);
		}

		setIsLoading(false);
	};

	return (
		<div className="container setup-wizard-step xyz-in">
			{isLoading && !isSaving ? (
				<Fragment>
					<Spinner />
				</Fragment>
			) : (
				<Fragment>
					{preconfigId && (
						<Fragment>
							<div className="website-screenshot-container">
								<div
									ref={screenshotRef}
									className="preconfig-screenshot website-screenshot"
									style={{ backgroundImage: screenshotStyle }}
								/>

								<div className="alert alert-secondary">
									<span>
										Please hold on while we're creating your
										new website...
									</span>
								</div>
							</div>
						</Fragment>
					)}

					{websiteId && (
						<Fragment>
							<div className="website-screenshot-container">
								<div
									ref={screenshotRef}
									className="website-screenshot"
									style={{ backgroundImage: screenshotStyle }}
								/>

								<div className="alert alert-secondary">
									<span>
										Please hold on while we're updating your
										website...
									</span>
								</div>
							</div>
						</Fragment>
					)}
				</Fragment>
			)}
		</div>
	);
};

export default WebsiteWizardSetupWizardComplete;
