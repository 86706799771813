import { Fragment, useEffect, useRef, useState } from 'react';
import { animationDefaults } from '../../../../../AnimConfig';
import { useStateValue } from '../../../../../StateProvider';
import CenteredContainer from '../../../../centeredContainer/CenteredContainer';
import Spinner from '../../../../../spinner/Spinner';
import './WebsiteWizardPreconfigCreate.css';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateWebsite, CreateWebsiteAdmin } from '../../../../../Services';
import { actionTypes } from '../../../../../reducer';
import ActionsBar from '../../../../actionsBar/ActionsBar';

const WebsiteWizardPreconfigCreate = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isComplete, setIsComplete ] = useState(false);
	const [ creatingClass, setCreatingClass ] = useState('');
	const [ { user, websiteWizardPreconfig }, dispatch ] = useStateValue();
	const screenshotRef = useRef(null);
	const [ screenshotStyle, setScreenshotStyle ] = useState('');
	const { userGuid, preconfigId } = useParams();
	const history = useHistory();

	useEffect(
		() => {
			if (websiteWizardPreconfig) {
				if (websiteWizardPreconfig.CompanyName) {
					createWebsite();
				}
			}
		},
		[ websiteWizardPreconfig ]
	);

	useEffect(
		() => {
			if (
				websiteWizardPreconfig &&
				websiteWizardPreconfig.ScreenshotUrl
			) {
				let screenshotUrl = websiteWizardPreconfig.ScreenshotUrl;

				if (screenshotUrl) {
					let tempScreenshotStyle = `url("/${screenshotUrl}")`;

					if (screenshotUrl.startsWith('https:')) {
						tempScreenshotStyle = `url("${screenshotUrl}")`;
					}

					setScreenshotStyle(tempScreenshotStyle);
				}
			}
		},
		[ websiteWizardPreconfig ]
	);

	useEffect(
		() => {
			// console.log('screenshot style set to:', screenshotStyle);
		},
		[ screenshotStyle ]
	);

	useEffect(
		() => {
			if (isSaving) {
				setCreatingClass('creating');
			} else {
				setCreatingClass('');
			}
		},
		[ isSaving ]
	);

	const createWebsite = async () => {
		setIsSaving(true);

		let websiteData = websiteWizardPreconfig;

		if (!userGuid) {
			const response = await CreateWebsite(websiteData);

			if (response.IsSuccessful) {
				dispatch({
					type                   :
						actionTypes.SET_WEBSITE_WIZARD_PRECONFIG,
					websiteWizardPreconfig : null
				});

				setIsComplete(true);
			} else {
				toast.error(response.Message);
			}
		} else {
			const response = await CreateWebsiteAdmin(websiteData);

			if (response.IsSuccessful) {
				dispatch({
					type                   :
						actionTypes.SET_WEBSITE_WIZARD_PRECONFIG,
					websiteWizardPreconfig : null
				});

				setIsComplete(true);
			} else {
				toast.error(response.Message);
			}
		}

		setIsSaving(false);
	};

	const handleContinueToWebsitesList = () => {
		if (!userGuid) {
			history.push('/');
		} else {
			history.push(`/administration/edit/${userGuid}`);
		}
	};

	const handleChatOpen = () => {
		if (window.LC_API) {
			if (user && user.Email) {
				let name = user.FirstName + ' ' + user.LastName;
				let email = user.Email.toLowerCase();

				window.LC_API.set_visitor_name(name);
				window.LC_API.set_visitor_email(email);
			}
			window.LC_API.open_chat_window();
		}
	};

	return (
		<Fragment>
			<ActionsBar className="website-wizard-actions-bar website-create">
				<div className="secondary-actions">
					<button
						className="btn btn-link btn-app-chat"
						onClick={handleChatOpen}
					>
						<span>
							Need help? <u>Click here</u>
						</span>
					</button>

					<NavLink
						className="btn btn-outline-secondary btn-close-wizard"
						activeClassName=""
						to="/"
					>
						<i className="fas fa-times m-0" />
					</NavLink>
				</div>
			</ActionsBar>

			<div className="website-wizard-website-selected-container">
				{websiteWizardPreconfig && isSaving ? (
					<div
						className={`create-website-container ${animationDefaults.animInClassName}`}
						xyz={animationDefaults.xyzDefaultSettings}
					>
						<div
							ref={screenshotRef}
							className={`website-wizard-preconfig-screenshot create-website ${creatingClass}`}
							style={{ backgroundImage: screenshotStyle }}
						/>

						{isSaving && (
							<div className="alert alert-secondary">
								<span>
									Please hold on while we're creating your new
									website...
								</span>
							</div>
						)}
					</div>
				) : (
					<Fragment>
						{isComplete ? (
							<div className="website-wizard-complete">
								<h3>Congratulations!</h3>
								<p className="text-muted">
									Your website has been created successfully.
								</p>
								<button
									type="button"
									className="btn btn-primary"
									onClick={handleContinueToWebsitesList}
								>
									{!userGuid ? (
										<Fragment>
											<i className="far fa-list-alt" />
											<span>See Websites List</span>
										</Fragment>
									) : (
										<Fragment>
											<i className="far fa-list-alt" />
											<span>Back to User Details</span>
										</Fragment>
									)}
								</button>
							</div>
						) : (
							<CenteredContainer>
								<Spinner />
							</CenteredContainer>
						)}
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};

export default WebsiteWizardPreconfigCreate;
