export const initialState = {
	brand                  : null,
	user                   : null,
	adminUser              : null,
	cart                   : null,
	cartItemsCount         : 0,
	selectedCartItems      : null,
	freeDomain             : null,
	cartSelectedCard       : null,
	cartItemsResponse      : null,
	makeItLiveSettings     : null,
	domainSearchTerm       : null,
	websiteWizardPreconfig : null,
	websiteWizardSetupData : null,
	showScheduleCall       : false,
	guidedTourSteps        : [],
	host                   : window.location.origin,
	apiHost                : window.location.origin + '/Services/',
	usersListSearchTerm    : ''
};

export const actionTypes = {
	SET_USER                      : 'SET_USER',
	SET_ADMIN_USER                : 'SET_ADMIN_USER',
	SET_CART                      : 'SET_CART',
	SET_SELECTED_CART_ITEMS       : 'SET_SELECTED_CART_ITEMS',
	SET_FREE_DOMAIN               : 'SET_FREE_DOMAIN',
	SET_CART_SELECTED_CARD        : 'SET_CART_SELECTED_CARD',
	SET_CART_ITEMS_COUNT          : 'SET_CART_ITEMS_COUNT',
	SET_CART_ITEMS_RESPONSE       : 'SET_CART_ITEMS_RESPONSE',
	SET_WEBSITE_WIZARD_PRECONFIG  : 'SET_WEBSITE_WIZARD_PRECONFIG',
	SET_WEBSITE_WIZARD_SETUP_DATA : 'SET_WEBSITE_WIZARD_SETUP_DATA',
	SET_SHOW_SCHEDULE_CALL        : 'SET_SHOW_SCHEDULE_CALL',
	SET_MAKEITLIVE_SETTINGS       : 'SET_MAKEITLIVE_SETTINGS',
	SET_BRAND                     : 'SET_BRAND',
	SET_SIGNUP_DETAILS            : 'SET_SIGNUP_DETAILS',
	SET_DOMAIN_SEARCH_TERM        : 'SET_DOMAIN_SEARCH_TERM',
	SET_GUIDED_TOUR_STEPS         : 'SET_GUIDED_TOUR_STEPS',
	SET_USERS_LIST_SEARCH_TERM    : 'SET_USERS_LIST_SEARCH_TERM'
};

const reducer = (state, action) => {
	console.log(action);
	switch (action.type) {
		case actionTypes.SET_BRAND:
			return {
				...state,
				brand : action.brand
			};
		case actionTypes.SET_USER:
			return {
				...state,
				user : action.user
			};
		case actionTypes.SET_ADMIN_USER:
			return {
				...state,
				adminUser : action.adminUser
			};
		case actionTypes.SET_SHOW_SCHEDULE_CALL:
			return {
				...state,
				showScheduleCall : action.showScheduleCall
			};
		case actionTypes.SET_CART:
			return {
				...state,
				cart : action.cart
			};
		case actionTypes.SET_SELECTED_CART_ITEMS:
			return {
				...state,
				selectedCartItems : action.selectedCartItems
			};
		case actionTypes.SET_FREE_DOMAIN:
			return {
				...state,
				freeDomain : action.freeDomain
			};
		case actionTypes.SET_CART_SELECTED_CARD:
			return {
				...state,
				cartSelectedCard : action.cartSelectedCard
			};
		case actionTypes.SET_CART_ITEMS_RESPONSE:
			return {
				...state,
				cartItemsResponse : action.cartItemsResponse
			};
		case actionTypes.SET_CART_ITEMS_COUNT:
			return {
				...state,
				cartItemsCount : action.cartItemsCount
			};
		case actionTypes.SET_MAKEITLIVE_SETTINGS:
			return {
				...state,
				makeItLiveSettings : action.makeItLiveSettings
			};
		case actionTypes.SET_WEBSITE_WIZARD_PRECONFIG:
			return {
				...state,
				websiteWizardPreconfig : action.websiteWizardPreconfig
			};
		case actionTypes.SET_WEBSITE_WIZARD_SETUP_DATA:
			return {
				...state,
				websiteWizardSetupData : action.websiteWizardSetupData
			};
		case actionTypes.SET_DOMAIN_SEARCH_TERM:
			return {
				...state,
				domainSearchTerm : action.domainSearchTerm
			};
		case actionTypes.SET_GUIDED_TOUR_STEPS:
			return {
				...state,
				guidedTourSteps : action.guidedTourSteps
			};
		case actionTypes.SET_USERS_LIST_SEARCH_TERM:
			return {
				...state,
				usersListSearchTerm : action.usersListSearchTerm
			};
		default:
			return state;
	}
};

export default reducer;
