import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './CrmLead.css';

const CrmLead = ({ lead, onSelect, disabled = false, selectedLeads = [] }) => {
	const [ selected, setSelected ] = useState(false);

	useEffect(
		() => {
			if ((!selectedLeads || selectedLeads.length === 0) && selected) {
				toggleSelected();
			}
		},
		[ selectedLeads ]
	);

	const handleClick = () => {
		if (disabled) return;

		if (onSelect) {
			let tempLead = {
				...lead,
				selected : !selected
			};

			toggleSelected();
			onSelect(tempLead);
		}
	};

	const toggleSelected = () => {
		setSelected((prev) => !prev);
	};

	return (
		<div
			className={`rw-row collapsable-parent lead-container ${selected
				? ' active'
				: ''}`}
			onClick={handleClick}
		>
			<div className="rw-td email">
				{lead.IsNew && (
					<small
						className="indicator-new-lead"
						title="This is a new lead"
					>
						New Lead
					</small>
				)}

				<div className="full-name">
					{lead.FullName && lead.FullName.trim() !== '' ? (
						<Fragment>{lead.FullName}</Fragment>
					) : (
						<Fragment>
							{lead.FirstName} {lead.LastName}
						</Fragment>
					)}
				</div>
				<div className="email">{lead.Email}</div>
			</div>
			<div className="rw-td phone">{lead.Phone ? lead.Phone : ''}</div>
			<div className="rw-td created">
				<div className="date">{lead.DateCreated}</div>
				<div className="time">
					<div className="time-container">
						{new Intl.DateTimeFormat('en-US', {
							hour   : 'numeric',
							minute : 'numeric',
							hour12 : true
						}).format(lead.DateCreatedUnix)}
					</div>
				</div>
			</div>
			<div className="rw-td action">
				<Link to={`/crm/lead/${lead.Guid}`} className="btn btn-primary">
					<i className="fas fa-pencil-alt" />
					<span>Details</span>
				</Link>
			</div>
		</div>
	);
};

export default CrmLead;
