import React, { Fragment, useEffect, useState } from 'react';
import Search from '../../search/Search';
import NoDataMessage from '../../noDataMessage/NoDataMessage';
import { GetUsersList } from '../../../Services';
import './UsersList.css';
import { toast } from 'react-toastify';
import Spinner from '../../../spinner/Spinner';
import { XyzTransition } from '@animxyz/react';
import { animationDefaults } from '../../../AnimConfig';
import UserListView from './userListView/UserListView';
import { useStateValue } from '../../../StateProvider';
import { useHistory } from 'react-router-dom';
import { actionTypes } from '../../../reducer';
import ActionsBar from '../../actionsBar/ActionsBar';
import Pagination from '../../pagination/Pagination';

const UsersList = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ { brand, usersListSearchTerm }, dispatch ] = useStateValue();
	const [ usersList, setUsersList ] = useState([]);
	const [ fullUsersList, setFullUsersList ] = useState([]);
	const [ tagsList, setTagsList ] = useState([]);
	// const [ searchTerm, setSearchTerm ] = useState('');
	const [ showDeleted, setShowDeleted ] = useState(false);
	const [ usersTotalCount, setUsersTotalCount ] = useState(0);
	const [ currentPageNumber, setCurrentPageNumber ] = useState(1);
	const [ itemsPerPage, setItemsPerPage ] = useState(20);
	const history = useHistory();

	useEffect(
		() => {
			loadUsersList();
		},
		[ showDeleted ]
	);

	const loadUsersList = async (pageNumber) => {
		if (pageNumber === undefined || pageNumber === null || pageNumber < 1) {
			pageNumber = 1;
			setIsLoading(true);
		}

		setCurrentPageNumber(pageNumber);

		let searchData = {
			SearchTerm  : usersListSearchTerm,
			ShowDeleted : showDeleted,
			PageNumber  : pageNumber,
			PageSize    : 20
		};

		const response = await GetUsersList(searchData);

		if (response.IsSuccessful) {
			let tempUsersList;
			let tempUsersData = JSON.parse(response.Data);

			if (tempUsersData && tempUsersData.Users) {
				tempUsersList = tempUsersData.Users;
			}

			setUsersTotalCount(tempUsersData.TotalCount);

			setFullUsersList(tempUsersList);
			setUsersList(tempUsersList);

			if (tempUsersList && tempUsersList.length > 0) {
				let newTagsList = new Array();

				for (let i = 0; i < tempUsersList.length; i++) {
					let user = tempUsersList[i];

					if (user.Tags) {
						for (let j = 0; j < user.Tags.length; j++) {
							if (newTagsList.indexOf(user.Tags[j]) === -1) {
								newTagsList.push(user.Tags[j]);
							}
						}
					}
				}

				setTagsList(newTagsList);
			}
		} else {
			toast.error(response.Message);
		}

		setIsLoading(false);
	};

	const handleSearchTermChange = (e) => {
		//setSearchTerm(e.target.value.trim());

		dispatch({
			type                : actionTypes.SET_USERS_LIST_SEARCH_TERM,
			usersListSearchTerm : e.target.value.trim()
		});
	};

	const handleBackToMainApp = () => {
		history.push('/');
	};

	const handleOnUserTagsUpdate = (user, tags, newTag) => {
		let fullUsersListIndex = fullUsersList.findIndex((x) => x.Guid === user.Guid);

		if (fullUsersListIndex > -1) {
			let tempList = fullUsersList.slice();
			tempList[fullUsersListIndex] = {
				...user,
				Tags : tags
			};

			setFullUsersList(tempList);
		}

		let usersListIndex = usersList.findIndex((x) => x.Guid === user.Guid);

		if (usersListIndex > -1) {
			let tempList = usersList.slice();
			tempList[usersListIndex] = {
				...user,
				Tags : tags
			};

			setUsersList(tempList);
		}

		if (tags && tags.length > 0) {
			let tempTags = tagsList;
			tempTags.push(newTag);

			setTagsList(tempTags);
		}
	};

	const handleOnUserNotesUpdate = (user, notes) => {
		let fullUsersListIndex = fullUsersList.findIndex((x) => x.Guid === user.Guid);

		if (fullUsersListIndex > -1) {
			let tempList = fullUsersList.slice();
			tempList[fullUsersListIndex] = {
				...user,
				Notes : notes
			};

			setFullUsersList(tempList);
		}

		let usersListIndex = usersList.findIndex((x) => x.Guid === user.Guid);

		if (usersListIndex > -1) {
			let tempList = usersList.slice();
			tempList[usersListIndex] = {
				...user,
				Notes : notes
			};

			setUsersList(tempList);
		}
	};

	const handleShowDeletedChange = (e) => {
		setShowDeleted(e.target.checked);
	};

	const handlePageNumberChanged = (pageNumber) => {
		loadUsersList(pageNumber);
	};

	// const getResultsMessage = () => {
	// 	let fromNumber = (currentPageNumber - 1) * itemsPerPage + 1;
	// 	let toNumber =
	// 		currentPageNumber * itemsPerPage > usersTotalCount ? usersTotalCount : currentPageNumber * itemsPerPage;

	// 	return `${fromNumber}-${toNumber} of ${usersTotalCount === 1 ? 'user' : 'users'}`;
	// };

	return (
		<Fragment>
			<div className="users-settings panel">
				<div className="container">
					<ActionsBar>
						<button
							type="button"
							className="btn btn-outline-primary back-to-mainapp"
							onClick={handleBackToMainApp}
						>
							<i className="fas fa-chevron-left" />
							<span>Back to {brand.BrandName}</span>
						</button>

						<div className="secondary-actions">
							<button
								type="button"
								className="btn btn-primary"
								onClick={() => history.push('/administration/users/create')}
							>
								<span>Create New User</span>
							</button>
						</div>
					</ActionsBar>

					<h5 className="rw-container-title">
						<i class="fas fa-user-group" />
						<span>Users</span>
					</h5>

					<div className="rw-container shadow-large users">
						<div className="search-container">
							<Search
								searchTerm={usersListSearchTerm}
								handleChange={handleSearchTermChange}
								submitEvent={() => loadUsersList()}
								placeholder="Search users"
								isDisabled={isLoading}
							/>
						</div>

						<div className="admin-actions-container filters">
							<div className="admin-actions-left">
								<div className="search-filters-container">
									<div className="filter filter-deleted-users">
										<input
											id="chkShowDeleted"
											type="checkbox"
											className="rw-checkbox-switch"
											defaultChecked={showDeleted}
											onChange={handleShowDeletedChange}
											disabled={isLoading}
										/>
										<label htmlFor="chkShowDeleted">Show Deleted Users</label>
									</div>
								</div>
							</div>

							{usersTotalCount > 0 && (
								<span className="text-muted">
									{`${(currentPageNumber - 1) * itemsPerPage + 1}-${currentPageNumber * itemsPerPage >
									usersTotalCount
										? usersTotalCount
										: currentPageNumber * itemsPerPage} of ${usersTotalCount} ${usersTotalCount ===
									1
										? 'user'
										: 'users'}`}
								</span>
							)}
						</div>

						{!isLoading ? (
							<Fragment>
								{usersList && usersList.length > 0 ? (
									<Fragment>
										<XyzTransition
											className="app-users-list item-group"
											xyz={animationDefaults.xyzBottomStagger}
										>
											<div className="app-users-list-container">
												{usersList.map((user) => (
													<UserListView
														key={user.Guid}
														customer={user}
														onTagsUpdate={handleOnUserTagsUpdate}
														onNotesUpdate={handleOnUserNotesUpdate}
														tagsList={tagsList}
													/>
												))}

												{usersTotalCount > itemsPerPage && (
													<div className="users-list-pagination">
														<Pagination
															onPageNumberChanged={handlePageNumberChanged}
															totalDataCount={usersTotalCount}
															pageNumber={currentPageNumber}
															buttonConst={3}
															itemsPerPage={itemsPerPage}
															siblingCount={1}
														/>
													</div>
												)}
											</div>
										</XyzTransition>
									</Fragment>
								) : (
									<div className="container-fluid text-center p-4">
										<NoDataMessage />
									</div>
								)}
							</Fragment>
						) : (
							<div className="container-fluid text-center mb-3">
								<Spinner />
							</div>
						)}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default UsersList;
