import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AddNewDomainRecord } from '../../../../../../Services';
import Spinner from '../../../../../../spinner/Spinner';
import './AddDomainRecord.css';

const AddDomainRecord = ({ domain, onSave, onCancel }) => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ record, setRecord ] = useState({});

	useEffect(() => {
		setRecord({
			Guid : domain.Guid,
			Type : 'A'
		});
	}, []);

	const handleChange = (e) => {
		setRecord({ ...record, [e.target.name]: e.target.value });
	};

	const handleCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let data = record;

		if (data.Value && data.Value !== '') {
			data.Value = data.Value.trim();
		}

		if (data.Host && data.Host !== '') {
			data.Host = data.Host.trim();
		}

		// cleanup the record before updating DB
		if (data.Type === 'A') {
			data.Priority = null;
			data.Service = null;
			data.Protocol = null;
			data.Weight = null;
			data.Port = null;
		} else if (data.Type === 'CNAME') {
			data.Priority = null;
			data.Service = null;
			data.Protocol = null;
			data.Weight = null;
			data.Port = null;
		} else if (data.Type === 'TXT') {
			// data.Host = '@';
			data.Priority = null;
			data.Service = null;
			data.Protocol = null;
			data.Weight = null;
			data.Port = null;
		} else if (data.Type === 'MX') {
			if (data.Host.trim() === '') data.Host = '@';
			data.Service = null;
			data.Protocol = null;
			data.Weight = null;
			data.Port = null;
		} else if (data.Type === 'SRV') {
			data.Host = '@';
		}

		const response = await AddNewDomainRecord(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);

			if (onSave) {
				onSave();
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<Fragment>
			<div className="add-zone-record">
				<div className="container-fluid">
					<div className="form-inputs">
						<form onSubmit={handleSubmit} autoComplete="off">
							<div className="row">
								<div className="col-sm-12 col-lg-6">
									<div className="form-group">
										<label htmlFor="recordType">
											Record Type
										</label>
										<select
											id="recordType"
											name="Type"
											className="form-control form-control-lg"
											disabled={isSaving}
											value={record.Type}
											onChange={handleChange}
										>
											<option value="A">A</option>
											<option value="MX">MX</option>
											<option value="TXT">TXT</option>
											<option value="CNAME">CNAME</option>
											<option value="SRV">SRV</option>
										</select>
									</div>
								</div>
							</div>
							<div className="row">
								{(record.Type === 'A' ||
									record.Type === 'CNAME') && (
									<Fragment>
										<div className="col-sm-12 col-lg-6">
											<div className="form-group">
												<label htmlFor="host">
													Host
												</label>
												<input
													type="text"
													id="host"
													name="Host"
													className="form-control form-control-lg"
													disabled={isSaving}
													value={record.Host}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="col-sm-12 col-lg-6">
											<div className="form-group">
												<label htmlFor="value">
													Value
												</label>
												<input
													type="text"
													id="value"
													name="Value"
													className="form-control form-control-lg"
													disabled={isSaving}
													value={record.Value}
													onChange={handleChange}
												/>
											</div>
										</div>
									</Fragment>
								)}

								{record.Type === 'TXT' && (
									<Fragment>
										<div className="col-sm-12 col-lg-6">
											<div className="form-group">
												<label htmlFor="host">
													Host
												</label>
												<input
													type="text"
													id="host"
													name="Host"
													className="form-control form-control-lg"
													disabled={isSaving}
													value={record.Host}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="col-sm-12 col-lg-6">
											<div className="form-group">
												<label htmlFor="value">
													Value
												</label>
												<input
													type="text"
													id="value"
													name="Value"
													className="form-control form-control-lg"
													disabled={isSaving}
													value={record.Value}
													onChange={handleChange}
												/>
											</div>
										</div>
									</Fragment>
								)}

								{record.Type === 'MX' && (
									<Fragment>
										<div className="col-sm-12 col-lg-4">
											<div className="form-group">
												<label htmlFor="host">
													Host
												</label>
												<input
													type="text"
													id="host"
													name="Host"
													className="form-control form-control-lg"
													disabled={isSaving}
													value={record.Host}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="col-sm-12 col-lg-4">
											<div className="form-group">
												<label htmlFor="value">
													Value
												</label>
												<input
													type="text"
													id="value"
													name="Value"
													className="form-control form-control-lg"
													disabled={isSaving}
													value={record.Value}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="col-sm-12 col-lg-4">
											<div className="form-group">
												<label htmlFor="priority">
													Priority
												</label>
												<input
													type="number"
													id="priority"
													name="Priority"
													className="form-control form-control-lg"
													disabled={isSaving}
													value={record.Priority}
													onChange={handleChange}
												/>
											</div>
										</div>
									</Fragment>
								)}

								{record.Type === 'SRV' && (
									<Fragment>
										<div className="col-sm-12 col-lg-4">
											<div className="form-group">
												<label htmlFor="value">
													Target
												</label>
												<input
													type="text"
													id="value"
													name="Value"
													className="form-control form-control-lg"
													disabled={isSaving}
													value={record.Value}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="col-sm-12 col-lg-4">
											<div className="form-group">
												<label htmlFor="protocol">
													Protocol
												</label>
												<input
													type="text"
													id="protocol"
													name="Protocol"
													className="form-control form-control-lg"
													disabled={isSaving}
													value={record.Protocol}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="col-sm-12 col-lg-4">
											<div className="form-group">
												<label htmlFor="service">
													Service
												</label>
												<input
													type="text"
													id="service"
													name="Service"
													className="form-control form-control-lg"
													disabled={isSaving}
													value={record.Service}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="col-sm-12 col-lg-4">
											<div className="form-group">
												<label htmlFor="priority">
													Priority
												</label>
												<input
													type="number"
													id="priority"
													name="Priority"
													className="form-control form-control-lg"
													disabled={isSaving}
													value={record.Priority}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="col-sm-12 col-lg-4">
											<div className="form-group">
												<label htmlFor="weight">
													Weight
												</label>
												<input
													type="number"
													id="weight"
													name="Weight"
													className="form-control form-control-lg"
													disabled={isSaving}
													value={record.Weight}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="col-sm-12 col-lg-4">
											<div className="form-group">
												<label htmlFor="port">
													Port
												</label>
												<input
													type="number"
													id="port"
													name="Port"
													className="form-control form-control-lg"
													disabled={isSaving}
													value={record.Port}
													onChange={handleChange}
												/>
											</div>
										</div>
									</Fragment>
								)}
							</div>

							<div className="buttons-container">
								<button
									type="submit"
									className="btn btn-primary"
									disabled={isSaving}
								>
									{!isSaving ? (
										<Fragment>
											<i className="fas fa-save" />
											<span>Save</span>
										</Fragment>
									) : (
										<Fragment>
											<Spinner />
											<span>Saving Record...</span>
										</Fragment>
									)}
								</button>

								<button
									type="button"
									className="btn btn-outline-primary"
									disabled={isSaving}
									onClick={handleCancel}
								>
									<i className="fas fa-chevron-left" />
									<span>Cancel</span>
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default AddDomainRecord;
