import { XyzTransitionGroup } from '@animxyz/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { animationDefaults } from '../../../../AnimConfig';
import { GetWebsiteWizardData } from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import { useStateValue } from '../../../../StateProvider';
import ActionsBar from '../../../actionsBar/ActionsBar';
import CenteredContainer from '../../../centeredContainer/CenteredContainer';
import ModalDialogContent from '../../../modalDialog/modalDialogContent/ModalDialogContent';
import ModalDialogFullScreen from '../../../modalDialog/ModalDialogFullScreen';
import Search from '../../../search/Search';
import './WebsiteWizard.css';
import WebsiteWizardCategoriesFilter from './websiteWizardCategoriesFilter/WebsiteWizardCategoriesFilter';
import WebsiteWizardWebsite from './websiteWizardWebsite/WebsiteWizardWebsite';
import Select from 'react-select';

const WebsiteWizard = () => {
	const [ { user, brand }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ websiteWizardData, setWebsiteWizardData ] = useState(null);
	const [ searchTerm, setSearchTerm ] = useState('');
	const [ wizardWebsites, setWizardWebsites ] = useState([]);
	const [ fullWizardWebsites, setFullWizardWebsites ] = useState([]);
	const [ categories, setCategories ] = useState([]);
	const [ otherCategorySitesCount, setOtherCategorySitesCount ] = useState(0);
	const [ selectedCategories, setSelectedCategories ] = useState();
	const history = useHistory();
	const categoriesDropdownRef = useRef();
	const [ categoriesDropdownSource, setCategoriesDropdownSource ] = useState();
	const btnBackStyle = `
		.website-wizard .btn-back {
			display: none;
		}`;

	useEffect(
		() => {
			if (brand) {
				getWebsiteWizardData();
			}
		},
		[ brand ]
	);

	const getWebsiteWizardData = async () => {
		let brandData = {
			BrandId : brand.Id
		};

		const response = await GetWebsiteWizardData(brandData);

		if (response.IsSuccessful) {
			const responseData = JSON.parse(response.Data);

			setCategories(responseData.Categories);

			console.log(responseData.Categories);

			let tempWizardWebsites = responseData.Websites;
			tempWizardWebsites.sort((a, b) => a.Name.localeCompare(b.Name));
			setWizardWebsites(tempWizardWebsites);
			setFullWizardWebsites(tempWizardWebsites);
		} else {
			// TODO: show some sort of error message that no websites exist for brand?
		}

		setIsLoading(false);
	};

	useEffect(
		() => {
			if (fullWizardWebsites) {
				let otherSites = fullWizardWebsites.filter((x) => x.Categories === null);

				// console.log('other sites:', otherSites);

				setOtherCategorySitesCount(otherSites ? otherSites.length : 0);
			}
		},
		[ fullWizardWebsites ]
	);

	const handleSearch = (e) => {
		let term = e.target.value;

		setSearchTerm(term ? term.toLowerCase() : '');
	};

	const handleCategoriesUpdate = (filter) => {
		setSelectedCategories(filter);
	};

	// handle the search term and categories update
	useEffect(
		() => {
			if (searchTerm.trim().length >= 3) {
				let filteredList = fullWizardWebsites.filter((x) => x.Name.toLowerCase().indexOf(searchTerm) > -1);

				if (!filteredList) {
					filteredList = [];
				} else {
					if (Array.isArray(selectedCategories)) {
						// if we have selected categories in the list we need to filter
						// by those too

						// if Show All category isn't selected parse results
						let showAll =
							selectedCategories.filter((x) => !x.isSelected).length === selectedCategories.length;
						if (selectedCategories.length > 0 && !showAll) {
							// if Other category is selected we need to include
							// results that aren't categorized yet
							let lastCategory = selectedCategories[selectedCategories.length - 1];
							if (lastCategory.name === 'Other' && lastCategory.isSelected) {
								let selectedNames = selectedCategories.map((category) => category.name);

								filteredList = filteredList.filter(
									(x) => x.Categories === null || selectedNames.some((s) => x.Categories.includes(s))
								);
							} else {
								let selectedNames = selectedCategories.map((category) => category.name);

								filteredList = filteredList.filter((x) =>
									selectedNames.some((s) => x.Categories.includes(s))
								);
							}
						}
					}
				}

				if (!Array.isArray(filteredList)) {
					let tempArray = new Array();
					tempArray.push(filteredList);

					filteredList = tempArray;
				}

				setWizardWebsites(filteredList);
			} else {
				if (Array.isArray(selectedCategories)) {
					// if we have selected categories in the list we need to filter
					// by those too

					// if Show All category isn't selected parse results
					let showAll = selectedCategories.filter((x) => !x.isSelected).length === selectedCategories.length;
					if (selectedCategories.length > 0 && !showAll) {
						// if Other category is selected we need to include
						// results that aren't categorized yet
						let lastCategory = selectedCategories[selectedCategories.length - 1];
						if (lastCategory.name === 'Other' && lastCategory.isSelected) {
							let selectedNames = selectedCategories
								.filter((x) => x.isSelected)
								.map((category) => category.name);

							let filteredList = fullWizardWebsites.filter(
								(x) => x.Categories === null || selectedNames.some((s) => x.Categories.includes(s))
							);

							// console.log('filtered list:', filteredList);

							setWizardWebsites(filteredList);
						} else {
							let selectedNames = selectedCategories
								.filter((x) => x.isSelected)
								.map((category) => category.name);

							let filteredList = fullWizardWebsites.filter(
								(x) => x.Categories !== null && selectedNames.some((s) => x.Categories.includes(s))
							);

							// console.log('filtered list:', filteredList);

							setWizardWebsites(filteredList);
						}
					} else {
						setWizardWebsites(fullWizardWebsites);
					}
				} else {
					setWizardWebsites(fullWizardWebsites);
				}
			}
		},
		[ searchTerm, selectedCategories ]
	);

	const handleGoBack = () => {
		history.goBack();
	};

	const handleChatOpen = () => {
		if (window.LC_API) {
			if (user && user.Email) {
				let name = user.FirstName + ' ' + user.LastName;
				let email = user.Email.toLowerCase();

				window.LC_API.set_visitor_name(name);
				window.LC_API.set_visitor_email(email);
			}
			window.LC_API.open_chat_window();
		}
	};

	useEffect(
		() => {
			if (categories) {
				let categoryKeys = Object.keys(categories);

				let tempCategoriesDropdownSource = new Array();
				for (let i = 0; i < categoryKeys.length; i++) {
					tempCategoriesDropdownSource.push({
						value : categoryKeys[i],
						label : categoryKeys[i]
					});
				}

				setCategoriesDropdownSource(tempCategoriesDropdownSource);
			}
		},
		[ categories ]
	);

	const handleCategoriesSelect = (selectedCategories) => {
		let tempCategories = [];

		if (selectedCategories && selectedCategories.length > 0) {
			for (let i = 0; i < selectedCategories.length; i++) {
				let foundCategoryKey = Object.keys(categories).find((key) => key === selectedCategories[i].value);

				if (foundCategoryKey) {
					tempCategories.push({
						name       : foundCategoryKey,
						sitesCount : categories[foundCategoryKey],
						isSelected : true
					});
				}
			}
		}

		setSelectedCategories(tempCategories);
	};

	return (
		<div className="website-wizard">
			{!isLoading ? (
				<Fragment>
					<ActionsBar>
						<div className="btn-back-container">
							<button type="button" className="btn btn-outline-primary btn-back" onClick={handleGoBack}>
								<i className="fas fa-chevron-left" />
								<span>Go Back</span>
							</button>
						</div>

						<div className="secondary-actions">
							<button className="btn btn-link btn-app-chat" onClick={handleChatOpen}>
								<span>
									Need help? <u>Click here</u>
								</span>
							</button>

							<NavLink className="btn btn-outline-secondary btn-close-wizard" activeClassName="" to="/">
								<i className="fas fa-times m-0" />
							</NavLink>
						</div>
					</ActionsBar>

					<div className="wizard-interface">
						<div className="wizard-left">
							<WebsiteWizardCategoriesFilter
								categories={categories}
								sitesCount={fullWizardWebsites.length}
								showOtherCategory={otherCategorySitesCount > 0}
								otherCategorySitesCount={otherCategorySitesCount}
								onUpdate={handleCategoriesUpdate}
							/>
						</div>
						<div className="wizard-right">
							<div className="website-wizard-controls categories-filter-mobile mb-0">
								<div className="categories-dropdown text-left">
									<Select
										ref={categoriesDropdownRef}
										options={categoriesDropdownSource}
										isMulti
										closeMenuOnSelect={false}
										openMenuOnClick
										onChange={handleCategoriesSelect}
										//isDisabled={isSaving}
										placeholder="Filter By Categories"
									/>
								</div>
							</div>
							<div className="website-wizard-controls">
								{/* TODO: Filter control */}
								<div className="search-bar">
									<Search placeholder="Search website templates" handleChange={handleSearch} />
								</div>
							</div>
							<XyzTransitionGroup
								className="website-wizard-list-container item-group"
								xyz={animationDefaults.xyzBottomStagger}
							>
								<div className="websites-list">
									{wizardWebsites.map((website) => <WebsiteWizardWebsite website={website} />)}
								</div>
							</XyzTransitionGroup>
						</div>
					</div>
				</Fragment>
			) : (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			)}

			<style type="text/css">{btnBackStyle}</style>
		</div>
	);
};

export default WebsiteWizard;
