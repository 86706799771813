import './WebsiteWizardCategory.css';

const WebsiteWizardCategory = ({ category, onChange }) => {
	const handleClick = (e) => {
		e.stopPropagation();

		if (onChange) {
			onChange(category);
		}
	};

	return (
		<div onClick={handleClick} className="category-container">
			<div className="category-check">
				<input
					id={`chkCategory${category.name}`}
					type="checkbox"
					className="rw-checkbox-switch"
					checked={category.isSelected}
				/>
				<label htmlFor={`chkCategory${category.name}`} />
			</div>
			<div className="category-name">{category.name}</div>
			<div className="category-count">{category.sitesCount}</div>
		</div>
	);
};

export default WebsiteWizardCategory;
