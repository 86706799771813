import { useEffect, useState } from 'react';
import { useStateValue } from '../../StateProvider';
import './AppLogin.css';
import LoginForm from './loginForm/LoginForm';
import { animationDefaults } from '../../AnimConfig';
import { ValidateUser } from '../../Services';
import { actionTypes } from '../../reducer';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';

const AppLogin = () => {
	const [ { brand }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ email, setEmail ] = useState('');
	const [ password, setPassword ] = useState('');
	const history = useHistory();

	useEffect(() => {
		localStorage.removeItem('rwau');
	}, []);

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.value;

		if (target === 'email') {
			setEmail(value);
		} else if (target === 'password') {
			setPassword(value);
		}
	};

	const loginCallback = async (e) => {
		e.preventDefault();

		setIsLoading(true);

		const response = await ValidateUser(email, password, brand.Id);

		if (response.IsSuccessful) {
			let tempUser = JSON.parse(response.Data);

			dispatch({
				type : actionTypes.SET_USER,
				user : tempUser
			});

			if (tempUser.IsAdmin) {
				dispatch({
					type      : actionTypes.SET_ADMIN_USER,
					adminUser : tempUser
				});

				localStorage.setItem('rwau', response.Data);
			}

			if (tempUser.IsFirstLogin) {
				localStorage.setItem('rw-gtour', '1');
			}

			toast.success(response.Message);

			if (tempUser.LegacyData) {
				// push to the upgrade offer
				window.location.href = `https://app.${brand.BrandUrl}/legacy/offer/`;
			} else {
				history.push('/');
			}
		} else {
			toast.error(response.Message);
			setIsLoading(false);
		}
	};

	return (
		<div className="app-login-container">
			<div className="login-column-container">
				{brand &&
				brand.BrandLogo && (
					<div className="logo-box">
						<img src={brand.BrandLogo} alt="Logo" />
					</div>
				)}
				<div
					className={`login-box shadow-large ${animationDefaults.animInClassName}`}
					xyz={animationDefaults.xyzDefaultSettings}
				>
					<h3>Welcome Back! Please sign in</h3>
					<LoginForm
						email={email}
						password={password}
						handleChange={handleChange}
						loginCallback={loginCallback}
						isLoading={isLoading}
					/>
				</div>
				<Link
					to="/forgot-password"
					className="btn btn-link btn-block btn-forgot-password mt-3"
				>
					Forgot Your Password?
				</Link>
			</div>
		</div>
	);
};

export default AppLogin;
