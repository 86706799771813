import { Link } from 'react-router-dom';
import { useStateValue } from '../../../StateProvider';
import CenteredContainer from '../../centeredContainer/CenteredContainer';
import LottiePlayer from '../../lottiePlayer/LottiePlayer';
import affiliatesAnimation from '../../lottiePlayer/animations/affiliate.json';
import './Affiliates.css';

const Affiliates = () => {
	const [ { brand }, dispatch ] = useStateValue();

	return (
		<div className="app-affiliates panel">
			<CenteredContainer>
				<div className="container">
					<LottiePlayer animation={affiliatesAnimation} loop={true} width={250} height={250} />
					<div className="app-feature-title">Coming Soon!</div>
					<h3>{`Earn money by referring people to ${brand.BrandName}!`}</h3>
				</div>
			</CenteredContainer>
		</div>
	);
};

export default Affiliates;
