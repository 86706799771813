import { Fragment, useEffect, useState } from 'react';
import { useStateValue } from '../../../../../StateProvider';
import './WebsiteWizardCategoriesFilter.css';
import WebsiteWizardCategory from './websiteWizardCategory/WebsiteWizardCategory';

const WebsiteWizardCategoriesFilter = ({
	categories,
	sitesCount,
	showOtherCategory,
	otherCategorySitesCount,
	onUpdate
}) => {
	const [ categoryList, setCategoryList ] = useState();
	const [ { user }, dispatch ] = useStateValue();

	useEffect(
		() => {
			if (categories) {
				let categoryKeys = Object.keys(categories);

				let tempCategories = new Array();
				for (let i = 0; i < categoryKeys.length; i++) {
					let newCategory = {
						name       : categoryKeys[i],
						sitesCount : categories[categoryKeys[i]],
						isSelected : false
					};

					// preselect the website wizard category if the group settings
					// has a list of categories to preselect
					if (
						user &&
						user.Group &&
						user.Group.WebsiteWizardPreselectCategories &&
						user.Group.WebsiteWizardPreselectCategories.length > 0
					) {
						if (
							user.Group.WebsiteWizardPreselectCategories.indexOf(
								newCategory.name
							) > -1
						) {
							newCategory.isSelected = true;
						}
					}

					tempCategories.push(newCategory);
				}

				if (showOtherCategory) {
					let otherCategory = {
						name       : 'Other',
						sitesCount : otherCategorySitesCount,
						isSelected : false
					};

					tempCategories.push(otherCategory);
				}

				// console.log(tempCategories);

				setCategoryList(tempCategories);
			}
		},
		[ categories, otherCategorySitesCount ]
	);

	const handleChange = (category) => {
		if (category) {
			if (category.name === 'Show All') {
				let tempCategories = categoryList.map(
					(tempCategory) =>
						new Object({
							...tempCategory,
							isSelected : false
						})
				);

				// console.log(tempCategories);

				setCategoryList(tempCategories);
			} else {
				let tempCategories = categoryList.slice();
				let index = tempCategories.findIndex(
					(x) => x.name === category.name
				);

				tempCategories.splice(
					index,
					1,
					new Object({
						...category,
						isSelected : !category.isSelected
					})
				);

				// if the current category is about to be deselected
				// we need to turn off the Show All category as well
				if (category.isSelected) {
					let showAll = tempCategories[0];
					tempCategories.splice(0, 1, {
						...showAll,
						isSelected : false
					});
				}

				// console.log(tempCategories);

				setCategoryList(tempCategories);
			}
		}
	};

	const handleShowAll = () => {
		let showAllCategory = {
			name : 'Show All'
		};

		handleChange(showAllCategory);
	};

	useEffect(
		() => {
			if (Array.isArray(categoryList) && onUpdate) {
				onUpdate(categoryList);
			}
		},
		[ categoryList ]
	);

	return (
		<div className="categories-container">
			<span className="categories-title">Categories:</span>

			<div className="categories-list">
				{categoryList &&
				categoryList.length > 0 && (
					<Fragment>
						<button
							className="btn btn-link mt-1"
							onClick={handleShowAll}
						>
							Show All
						</button>

						{categoryList.map((category) => (
							<Fragment>
								<WebsiteWizardCategory
									key={category.name}
									category={category}
									onChange={handleChange}
								/>
							</Fragment>
						))}
					</Fragment>
				)}
			</div>
		</div>
	);
};

export default WebsiteWizardCategoriesFilter;
