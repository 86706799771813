import { Fragment, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { actionTypes } from '../../../../reducer';
import { FindDomains } from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import { useStateValue } from '../../../../StateProvider';
import Search from '../../../search/Search';

const MakeItLiveDomainSearch = () => {
	const [ { makeItLiveSettings }, dispatch ] = useStateValue();
	const [ isSearching, setIsSearching ] = useState(false);
	const [ searchTerm, setSearchTerm ] = useState('');
	const [ errorMessage, setErrorMessage ] = useState('');
	const { guid } = useParams();
	const history = useHistory();

	const handleChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const handleSubmit = async () => {
		setErrorMessage('');
		let finalSearchTerm = searchTerm.trim();

		if (finalSearchTerm === '') {
			toast.error('Search term is required');
			return;
		}

		setIsSearching(true);

		let data = {
			SearchTerm : finalSearchTerm
		};

		const response = await FindDomains(data);

		if (Array.isArray(response) && response.length > 0) {
			let domainsFound = response;
			let availDomains = domainsFound.filter((x) => x.IsAvailable === true);

			if (availDomains.length === 0) {
				if (domainsFound.length === 1) {
					// it's a domain search so the message should
					// talk about the domain searched for
					setErrorMessage(
						`<span>Sorry... <u>${domainsFound[0].DomainName}</u> is unavailable, please search again</span>`
					);
				} else {
					setErrorMessage(
						`<span>Sorry... No domains found for the <u>${searchTerm}</u> search term, please search again</span>`
					);
				}
				setIsSearching(false);
			} else {
				dispatch({
					type               : actionTypes.SET_MAKEITLIVE_SETTINGS,
					makeItLiveSettings : {
						...makeItLiveSettings,
						['domainSearch'] : availDomains
					}
				});

				history.push(`/make-it-live/${guid}/search-results`);
			}
		} else {
			setErrorMessage(
				`<span>Sorry... No domains found for the <u>${searchTerm}</u> search term, please search again</span>`
			);
			setIsSearching(false);
		}
	};

	return (
		<div className="wizard-step wizard-domain-search">
			<h3>Let's find a domain for your website</h3>
			<p className="text-muted">
				Enter a domain name to search for to see if it's available to be registered for your website
			</p>

			<Search
				searchTerm={searchTerm}
				isDisabled={isSearching}
				handleChange={handleChange}
				submitEvent={handleSubmit}
			/>

			{errorMessage && <div className="error-message" dangerouslySetInnerHTML={{ __html: errorMessage }} />}

			<div className="buttons-container">
				<button type="button" className="btn btn-primary" disabled={isSearching} onClick={handleSubmit}>
					{!isSearching ? (
						<Fragment>
							<i className="fas fa-search" />
							<span>Search</span>
						</Fragment>
					) : (
						<Fragment>
							<Spinner />
							<span>Searching...</span>
						</Fragment>
					)}
				</button>
			</div>
			<div className="buttons-container mt-2">
				<button type="button" className="btn btn-link" disabled={isSearching} onClick={() => history.goBack()}>
					<span>
						<u>Click here</u> to go back to our suggested domain list
					</span>
				</button>
			</div>
		</div>
	);
};

export default MakeItLiveDomainSearch;
