import { useEffect, useRef, useState } from 'react';
import './CreateUserNote.css';

const CreateUserNote = ({ onCreate, onCancel }) => {
	const [ noteText, setNoteText ] = useState('');
	const inputRef = useRef(null);

	useEffect(() => {
		inputRef.current.focus();
	}, []);

	const handleChange = (e) => {
		setNoteText(e.target.value);
	};

	const handleCreateClick = () => {
		let note = {
			Content : noteText.trim()
		};

		if (onCreate) {
			onCreate(note);
		}
	};

	const handleCancelClick = () => {
		if (onCancel) {
			onCancel();
		}
	};

	return (
		<div className="user-note-create-container">
			<textarea
				ref={inputRef}
				className="form-control form-control-lg"
				rows={3}
				placeholder="Enter note content..."
				value={noteText}
				onChange={handleChange}
			/>

			<div className="buttons-container">
				<button
					type="button"
					className="btn btn-primary"
					onClick={handleCreateClick}
				>
					<span>Add Note</span>
				</button>

				<button
					type="button"
					className="btn btn-outline-primary"
					onClick={handleCancelClick}
				>
					<span>Cancel</span>
				</button>
			</div>
		</div>
	);
};

export default CreateUserNote;
