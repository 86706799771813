import { Fragment } from 'react';
import { Route, Switch } from 'react-router-dom';
import GoogleAuthorize from './googleAuthorize/GoogleAuthorize';
import GoogleCallback from './googleCallback/GoogleCallback';

const GoogleOAuth = () => {
	return (
		<Fragment>
			<Switch>
				<Route path="/oauth/google/token">
					<GoogleCallback />
				</Route>
				<Route path="/oauth/google/authorize">
					<GoogleAuthorize />
				</Route>
			</Switch>
		</Fragment>
	);
};

export default GoogleOAuth;
