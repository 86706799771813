import { Fragment, useEffect, useState } from 'react';
import { useStateValue } from '../../../../../../StateProvider';
import RefundDialog from '../refundDialog/RefundDialog';
import './EditUserBillingHistoryItem.css';

const EditUserBillingHistoryItem = ({ item, onUpdate, userDetails }) => {
	const [ { user }, dispatch ] = useStateValue();
	const [ billingStatus, setBillingStatus ] = useState('');
	const [ billingFailureMessage, setBillingFailureMessage ] = useState('');
	const [ billingDate, setBillingDate ] = useState(null);
	const [ billingCardDigits, setBillingCardDigits ] = useState(null);
	const dateFormat = new Intl.DateTimeFormat('en-US', {
		month  : 'numeric',
		year   : 'numeric',
		day    : 'numeric',
		hour   : 'numeric',
		minute : 'numeric',
		hour12 : true
	});
	const [ showRefundDialog, setShowRefundDialog ] = useState(false);

	useEffect(
		() => {
			if (item) {
				setBillingDate(new Date(item.created * 1000));
				setBillingStatus(item.status);

				if (item.refunded) {
					setBillingStatus('refunded');
				}

				if (item.source && item.source.Card) {
					setBillingCardDigits(item.source.Card.last4);
				}

				if (item.failure_message) {
					setBillingFailureMessage(item.failure_message);
				}
			}
		},
		[ item ]
	);

	const toggleRefundDialog = () => {
		setShowRefundDialog((prev) => !prev);
	};

	const handleRefundUpdate = () => {
		toggleRefundDialog();
		if (onUpdate) {
			onUpdate();
		}
	};

	return (
		<Fragment>
			<div className="rw-row">
				<div className="rw-td date">
					{billingDate && (
						<Fragment>
							<span>{dateFormat.format(billingDate)}</span>
						</Fragment>
					)}
				</div>
				<div className="rw-td card-id">
					<i className="far fa-credit-card" />
					{billingCardDigits ? (
						<Fragment>
							<span>{billingCardDigits}</span>
						</Fragment>
					) : (
						<span>--</span>
					)}
				</div>
				<div className="rw-td description">
					<span>{item.description}</span>
				</div>
				<div className="rw-td status">
					{billingFailureMessage ? (
						<Fragment>
							<div
								className={`payment-status ${billingStatus}`}
								title={billingFailureMessage}
							>
								<span>{billingStatus}</span>
							</div>
						</Fragment>
					) : (
						<Fragment>
							<div className={`payment-status ${billingStatus}`}>
								<span>{billingStatus}</span>
							</div>
						</Fragment>
					)}
				</div>
				<div className="rw-td amount">
					<span>${(item.amount / parseFloat(100)).toFixed(2)}</span>
				</div>

				{user.IsAdmin && (
					<Fragment>
						<div className="rw-td action">
							{item.paid &&
							!item.refunded && (
								<Fragment>
									<button
										type="button"
										className="btn btn-danger"
										title="Refund this payment"
										onClick={toggleRefundDialog}
									>
										<i className="fas fa-reply m-0" />
									</button>
								</Fragment>
							)}
						</div>
					</Fragment>
				)}
			</div>

			{showRefundDialog && (
				<Fragment>
					<RefundDialog
						payment={item}
						onRefund={handleRefundUpdate}
						onCancel={toggleRefundDialog}
						userDetails={userDetails}
					/>
				</Fragment>
			)}
		</Fragment>
	);
};

export default EditUserBillingHistoryItem;
