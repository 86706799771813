import { Fragment, useEffect, useState } from 'react';
import { GetUserSpamFilters } from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import './CrmSpamFiltersList.css';
import EditSpamFilterModal from './editSpamFilterModal/EditSpamFilterModal';
import SpamFilterListItem from './spamFilterListItem/SpamFilterListItem';

const CrmSpamFiltersList = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ filters, setFilters ] = useState();
	const [ showAddFilterDialog, setShowAddFilterDialog ] = useState(false);

	useEffect(() => {
		getSpamFiltersList();
	}, []);

	const getSpamFiltersList = async () => {
		const response = await GetUserSpamFilters();

		if (response.IsSuccessful) {
			const tempFilters = JSON.parse(response.Data);
			console.log(tempFilters);
			setFilters(tempFilters);
		} else {
			setFilters();
		}

		setIsLoading(false);
	};

	const toggleAddFilterDialog = () => {
		setShowAddFilterDialog((prev) => !prev);
	};

	const handleNewFilter = () => {
		getSpamFiltersList();
		toggleAddFilterDialog();
	};

	const handleFilterUpdate = () => {
		getSpamFiltersList();

		if (showAddFilterDialog) {
			toggleAddFilterDialog();
		}
	};

	if (isLoading) {
		return (
			<div className="app-spam-filters-list text-center">
				<Spinner />
			</div>
		);
	}

	return (
		<Fragment>
			<div className="app-spam-filters-list">
				<div className="container">
					<div className="rw-buttons-container mb-3">
						<button
							type="button"
							className="btn btn-primary"
							onClick={toggleAddFilterDialog}
						>
							<span>Add New Spam Filter</span>
						</button>
					</div>

					{filters && filters.length > 0 ? (
						<Fragment>
							<h5 className="rw-container-title">
								<i className="fa-solid fa-lock" />
								<span>Spam Filters</span>
							</h5>

							<div className="spam-filters-list">
								{filters.map((filter) => (
									<SpamFilterListItem
										key={filter.Guid}
										filter={filter}
										onUpdate={handleFilterUpdate}
									/>
								))}
							</div>
						</Fragment>
					) : (
						<div className="alert alert-warning">
							You currently have no spam filters setup. Click on
							the button above to create a new filter.
						</div>
					)}
				</div>
			</div>

			{showAddFilterDialog && (
				<EditSpamFilterModal
					onUpdate={handleNewFilter}
					onCancel={toggleAddFilterDialog}
				/>
			)}
		</Fragment>
	);
};

export default CrmSpamFiltersList;
