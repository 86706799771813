import { Fragment, useState } from 'react';
import { toast } from 'react-toastify';
import { LegacyLogin } from '../../../../Services';
import Spinner from '../../../../spinner/Spinner';
import './LegacyButton.css';

const LegacyButton = () => {
	const [ isLoading, setIsLoading ] = useState(false);

	const handleLegacyButtonClick = async () => {
		setIsLoading(true);

		const response = await LegacyLogin();

		if (response.IsSuccessful) {
			window.location = response.Data;
		} else {
			toast.error(response.Message);
			setIsLoading(false);
		}
	};

	return (
		<Fragment>
			<button className="btn btn-primary btn-legacy-login" onClick={handleLegacyButtonClick} disabled={isLoading}>
				{!isLoading ? (
					<Fragment>
						<i className="fas fa-dharmachakra" />
						<span>Legacy Login</span>
					</Fragment>
				) : (
					<Fragment>
						<Spinner />
						<span>Logging you in...</span>
					</Fragment>
				)}
			</button>
		</Fragment>
	);
};

export default LegacyButton;
