import { useEffect, useState } from 'react';
import ccCirrus from '../../../../../img/cirrus.png';
import ccAmex from '../../../../../img/amex.png';
import ccDiscover from '../../../../../img/discover.png';
import ccMaestro from '../../../../../img/maestro.png';
import ccMastercard from '../../../../../img/mastercard.png';
import ccVisa from '../../../../../img/visa.png';
import './MakeItLiveSelectedCard.css';

const MakeItLiveSelectedCard = ({ card, onChangeClick }) => {
	const [ cardIcon, setCardIcon ] = useState(ccVisa);

	useEffect(() => {
		if (card) {
			switch (card.CardType.toLowerCase()) {
				case 'visa':
					setCardIcon(ccVisa);
					break;
				case 'mastercard':
					setCardIcon(ccMastercard);
					break;
				case 'americanexpress':
					setCardIcon(ccAmex);
					break;
				case 'discover':
					setCardIcon(ccDiscover);
					break;
				case 'maestro':
					setCardIcon(ccMaestro);
					break;
				case 'cirrus':
					setCardIcon(ccCirrus);
					break;
				default:
					break;
			}
		}
	}, []);

	const handleChangeClick = () => {
		if (onChangeClick) {
			onChangeClick();
		}
	};

	return (
		<div className="selected-card-container">
			<div className="rw-cc-icon">
				<img src={cardIcon} alt={card.CardType} />
			</div>
			<div className="rw-cc-numbers">{card.CardDigits}</div>
			<div className="rw-cc-change">
				<a onClick={handleChangeClick} className="app-link">
					<small>Change</small>
				</a>
			</div>
		</div>
	);
};

export default MakeItLiveSelectedCard;
