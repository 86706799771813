import { Fragment, useEffect } from 'react';
import CenteredContainer from '../../../centeredContainer/CenteredContainer';
import Spinner from '../../../../spinner/Spinner';
import './CalendlyAuthorize.css';

const CalendlyAuthorize = () => {
	const clientId = 'UEDyCebH4XzrrVWdLSTCjtYb8guIhxsIOjCbPvhdb4w';
	const redirectUrl = `https://auth.calendly.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${window
		.location.origin}/c/oauth/calendly/callback`;

	useEffect(() => {
		window.location.href = redirectUrl;
	}, []);

	return (
		<Fragment>
			<CenteredContainer>
				<Spinner />
				<h3 className="mt-3">Redirecting you to Calendly...</h3>
			</CenteredContainer>
		</Fragment>
	);
};

export default CalendlyAuthorize;
