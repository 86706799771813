import { Fragment, useEffect, useRef, useState } from 'react';
import { animationDefaults } from '../../../../../AnimConfig';
import { useStateValue } from '../../../../../StateProvider';
import CenteredContainer from '../../../../centeredContainer/CenteredContainer';
import Spinner from '../../../../../spinner/Spinner';
import './WebsiteWizardPreconfigSelected.css';
import { NavLink, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CreateWebsite, CreateWebsiteAdmin } from '../../../../../Services';
import { actionTypes } from '../../../../../reducer';
import ActionsBar from '../../../../actionsBar/ActionsBar';

const WebsiteWizardPreconfigSelected = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isComplete, setIsComplete ] = useState(false);
	const [ creatingClass, setCreatingClass ] = useState('');
	const [ { user, websiteWizardPreconfig }, dispatch ] = useStateValue();
	const [ websiteName, setWebsiteName ] = useState('');
	const screenshotRef = useRef(null);
	const { userGuid, preconfigId } = useParams();
	const history = useHistory();

	useEffect(
		() => {
			if (websiteWizardPreconfig) {
				setIsLoading(false);
			} else {
				setIsLoading(true);
			}
		},
		[ websiteWizardPreconfig ]
	);

	useEffect(
		() => {
			if (screenshotRef && screenshotRef.current) {
				let screenshotUrl = websiteWizardPreconfig.ScreenshotUrl;
				let screenshotStyle = `url("/${screenshotUrl}")`;

				if (screenshotUrl.startsWith('https:')) {
					screenshotStyle = `url("${screenshotUrl}")`;
				}

				screenshotRef.current.style.backgroundImage = screenshotStyle;
			}
		},
		[ screenshotRef ]
	);

	useEffect(
		() => {
			if (isSaving) {
				setCreatingClass('creating');
			} else {
				setCreatingClass('');
			}
		},
		[ isSaving ]
	);

	const handleNameChange = (e) => {
		setWebsiteName(e.target.value);
	};

	const handleGoBack = () => {
		history.goBack();
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let tempWebsiteName = websiteName.trim();

		if (tempWebsiteName === '') {
			toast.error('Website name is required');
			return;
		}

		setIsSaving(true);

		let websiteData = {
			PreConfigId : preconfigId
				? preconfigId
				: websiteWizardPreconfig.PreconfigId,
			WebsiteName : tempWebsiteName
		};

		if (userGuid) {
			websiteData.UserGuid = userGuid;
		}

		dispatch({
			type                   : actionTypes.SET_WEBSITE_WIZARD_PRECONFIG,
			websiteWizardPreconfig : {
				...websiteWizardPreconfig,
				...websiteData
			}
		});

		if (userGuid) {
			history.push(
				`/administration/websites/create/${userGuid}/${preconfigId}/company-info`
			);
		} else {
			history.push(`/create-website/${preconfigId}/company-info`);
		}

		// if (!userGuid) {
		// 	const response = await CreateWebsite(websiteData);

		// 	if (response.IsSuccessful) {
		// 		dispatch({
		// 			type:
		// 				actionTypes.SET_WEBSITE_WIZARD_PRECONFIG,
		// 			websiteWizardPreconfig: null
		// 		});

		// 		setIsComplete(true);
		// 	} else {
		// 		toast.error(response.Message);
		// 	}
		// } else {
		// 	const response = await CreateWebsiteAdmin(websiteData);

		// 	if (response.IsSuccessful) {
		// 		dispatch({
		// 			type:
		// 				actionTypes.SET_WEBSITE_WIZARD_PRECONFIG,
		// 			websiteWizardPreconfig: null
		// 		});

		// 		setIsComplete(true);
		// 	} else {
		// 		toast.error(response.Message);
		// 	}
		// }

		setIsSaving(false);
	};

	const handleContinueToWebsitesList = () => {
		if (!userGuid) {
			history.push('/');
		} else {
			history.push(`/administration/edit/${userGuid}`);
		}
	};

	const handleChatOpen = () => {
		if (window.LC_API) {
			if (user && user.Email) {
				let name = user.FirstName + ' ' + user.LastName;
				let email = user.Email.toLowerCase();

				window.LC_API.set_visitor_name(name);
				window.LC_API.set_visitor_email(email);
			}
			window.LC_API.open_chat_window();
		}
	};

	return (
		<Fragment>
			<ActionsBar className="website-wizard-actions-bar">
				<button
					type="button"
					className="btn btn-outline-primary"
					onClick={handleGoBack}
					disabled={isSaving}
				>
					<i className="fas fa-chevron-left" />
					<span>Go Back</span>
				</button>

				<div className="secondary-actions">
					<button
						className="btn btn-link btn-app-chat"
						onClick={handleChatOpen}
					>
						<span>
							Need help? <u>Click here</u>
						</span>
					</button>

					<NavLink
						className="btn btn-outline-secondary btn-close-wizard"
						activeClassName=""
						to="/"
					>
						<i className="fas fa-times m-0" />
					</NavLink>
				</div>
			</ActionsBar>

			<div className="website-wizard-website-selected-container">
				{websiteWizardPreconfig ? (
					<div
						className={`create-website-container ${animationDefaults.animInClassName}`}
						xyz={animationDefaults.xyzDefaultSettings}
					>
						{/* {!isSaving && (
						<button
							type="button"
							className="btn btn-link"
							onClick={handleGoBack}
						>
							<i className="fas fa-chevron-left" />
							<span>Go Back</span>
						</button>
					)} */}

						<div
							ref={screenshotRef}
							className={`website-wizard-preconfig-screenshot ${creatingClass}`}
						/>

						{!isLoading && (
							<div className="website-name">
								<form onSubmit={handleSubmit}>
									{!isSaving && (
										<input
											type="text"
											className="form-control form-control-lg"
											name="website-name"
											value={websiteName}
											onChange={handleNameChange}
											placeholder="Enter your new website name"
										/>
									)}

									{isSaving && (
										<div className="alert alert-secondary">
											<span>
												Please hold on while we're
												creating your new website...
											</span>
										</div>
									)}

									<button
										type="submit"
										className="btn btn-primary"
										disabled={isSaving}
									>
										{!isSaving ? (
											<Fragment>
												{/* <i className="fas fa-database" /> */}
												<span>Continue</span>
											</Fragment>
										) : (
											<Fragment>
												<Spinner />
												<span>Saving...</span>
											</Fragment>
										)}
									</button>
								</form>
							</div>
						)}
					</div>
				) : (
					<Fragment>
						{isComplete ? (
							<div className="website-wizard-complete">
								<h3>Congratulations!</h3>
								<p className="text-muted">
									Your website has been created successfully.
								</p>
								<button
									type="button"
									className="btn btn-primary"
									onClick={handleContinueToWebsitesList}
								>
									{!userGuid ? (
										<Fragment>
											<i className="far fa-list-alt" />
											<span>See Websites List</span>
										</Fragment>
									) : (
										<Fragment>
											<i className="far fa-list-alt" />
											<span>Back to User Details</span>
										</Fragment>
									)}
								</button>
							</div>
						) : (
							<CenteredContainer>
								<Spinner />
							</CenteredContainer>
						)}
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};

export default WebsiteWizardPreconfigSelected;
