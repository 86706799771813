import { Fragment, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { AddCreditCard, GetAccountCards } from '../../../Services';
import Spinner from '../../../spinner/Spinner';
import './CardInput.css';

const CardInput = ({ onSave, onCancel, hideBackButton }) => {
	const [ isSaving, setIsSaving ] = useState(false);
	const [ cardData, setCardData ] = useState({});
	const [ years, setYears ] = useState([]);

	useEffect(() => {
		let currentYear = new Date().getFullYear();
		let tempYears = new Array();

		for (let i = currentYear; i < currentYear + 11; i++) {
			tempYears.push(i);
		}

		setYears(tempYears);

		setCardData({
			...cardData,
			['expiryMonth'] : '1',
			['expiryYear']  : tempYears[0]
		});
	}, []);

	const handleChange = (e) => {
		setCardData({
			...cardData,
			[e.target.name]: e.target.value
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!cardData.cardNumbers || cardData.cardNumbers.trim() === '') {
			toast.error('Card Numbers are required');
			return;
		}

		if (!cardData.expiryMonth || cardData.expiryMonth.trim() === '') {
			toast.error('Expiry month is required');
			return;
		}

		if (!cardData.cvc || cardData.cvc.trim() === '') {
			toast.error('CVC is required');
			return;
		}

		setIsSaving(true);

		let data = {
			CardNumbers : cardData.cardNumbers,
			ExpireMonth : parseInt(cardData.expiryMonth),
			ExpireYear  : parseInt(cardData.expiryYear),
			CVC         : cardData.cvc,
			ZIP         : cardData.zip,
			CardName    : ''
		};

		const response = await AddCreditCard(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);
			let tempCreditCard = JSON.parse(response.Data);

			const cardsResponse = await GetAccountCards();

			if (cardsResponse.IsSuccessful) {
				let tempCard;
				let tempCards = JSON.parse(cardsResponse.Data);

				if (tempCards && tempCards.length > 0) {
					tempCards.map((card) => {
						if (card.Guid === tempCreditCard.Guid) {
							if (onSave) {
								onSave(card);
							}
						}
					});
				} else {
					toast.error(
						'Something went wrong while processing the request. Please try again.'
					);
				}
			} else {
				toast.error(cardsResponse.Message);
			}
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const handleCancel = () => {
		if (onCancel) {
			onCancel();
		}
	};

	return (
		<div className="card-input">
			<form onSubmit={handleSubmit}>
				<div className="container-fluid">
					<div className="row">
						<div className="col-sm-12">
							<div className="form-group">
								<label
									htmlFor="cardNumbers"
									className="col-form-label"
								>
									Card Numbers:
								</label>
								<input
									onChange={handleChange}
									value={cardData.cardNumbers}
									id="cardNumbers"
									name="cardNumbers"
									type="text"
									className="form-control form-control-lg"
									placeholder="XXXX XXXX XXXX XXXX"
									disabled={isSaving}
								/>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-sm-6">
							<div className="form-group">
								<label
									htmlFor="expiryMonth"
									className="col-form-label"
								>
									Expiry Month:
								</label>
								<select
									onChange={handleChange}
									value={cardData.expiryMonth}
									id="expiryMonth"
									name="expiryMonth"
									className="form-control form-control-lg"
									disabled={isSaving}
								>
									<option value="1">01 - January</option>
									<option value="2">02 - February</option>
									<option value="3">03 - March</option>
									<option value="4">04 - April</option>
									<option value="5">05 - May</option>
									<option value="6">06 - June</option>
									<option value="7">07 - July</option>
									<option value="8">08 - August</option>
									<option value="9">09 - September</option>
									<option value="10">10 - October</option>
									<option value="11">11 - November</option>
									<option value="12">12 - December</option>
								</select>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="form-group">
								<label
									htmlFor="expiryYear"
									className="col-form-label"
								>
									Expiry Year:
								</label>
								<select
									onChange={handleChange}
									value={cardData.expiryYear}
									id="expiryYear"
									name="expiryYear"
									className="form-control form-control-lg"
									disabled={isSaving}
								>
									{years.length > 0 && (
										<Fragment>
											{years.map((year) => (
												<option key={year} value={year}>
													{year}
												</option>
											))}
										</Fragment>
									)}
								</select>
							</div>
						</div>
					</div>
					<div className="row mb-3">
						<div className="col-sm-6">
							<div className="form-group">
								<label htmlFor="cvc" className="col-form-label">
									CVC:
								</label>
								<input
									onChange={handleChange}
									value={cardData.cvc}
									id="cvc"
									name="cvc"
									type="text"
									className="form-control form-control-lg"
									placeholder="CVC"
									disabled={isSaving}
								/>
							</div>
						</div>
						<div className="col-sm-6">
							<div className="form-group">
								<label htmlFor="zip" className="col-form-label">
									Billing ZIP:
								</label>
								<input
									onChange={handleChange}
									value={cardData.zip}
									id="zip"
									name="zip"
									type="text"
									className="form-control form-control-lg"
									placeholder="Billing ZIP"
									disabled={isSaving}
								/>
							</div>
						</div>
					</div>
					<div className="row buttons-container">
						<div className="col-sm-12">
							<button
								type="submit"
								className="btn btn-primary"
								disabled={isSaving}
							>
								{!isSaving ? (
									<Fragment>
										<i className="fas fa-chevron-right" />
										<span>Continue</span>
									</Fragment>
								) : (
									<Fragment>
										<Spinner />
										<span>Saving...</span>
									</Fragment>
								)}
							</button>
							{!hideBackButton && (
								<button
									type="button"
									className="btn btn-outline-primary"
									disabled={isSaving}
									onClick={handleCancel}
								>
									<i className="fas fa-chevron-left" />
									<span>Back</span>
								</button>
							)}
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default CardInput;
