import { Fragment, useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
	GetLeadDetails,
	MarkContactSpam,
	RestoreContact,
	UpdateContact
} from '../../../../../Services';
import Spinner from '../../../../../spinner/Spinner';
import ActionsBar from '../../../../actionsBar/ActionsBar';
import CenteredContainer from '../../../../centeredContainer/CenteredContainer';
import CrmLeadTags from '../crmLeadTags/CrmLeadTags';
import './CrmLeadDetails.css';
import DeleteLeadDialog from './deleteLeadDialog/DeleteLeadDialog';

const CrmLeadDetails = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ isSaving, setIsSaving ] = useState(false);
	const [ lead, setLead ] = useState(null);
	const [ showTagsDialogs, setShowTagsDialog ] = useState(false);
	const [ showDeleteConfirmation, setShowDeleteConfirmation ] = useState(
		false
	);
	const [ leadTitleText, setLeadTitleText ] = useState('');
	const match = useRouteMatch();
	const history = useHistory();

	useEffect(() => {
		getLeadDetails();
	}, []);

	useEffect(
		() => {
			// console.log(lead);
			if (lead) {
				if (lead.LeadType === 'Form') {
					setLeadTitleText(
						'This contact was created through a Form submission'
					);
				} else if (lead.LeadType === 'Call') {
					setLeadTitleText(
						'This lead was created through a Phone call'
					);
				} else {
					setLeadTitleText(
						`This lead was created as a ${lead.LeadType} lead type.`
					);
				}
			}
		},
		[ lead ]
	);

	const getLeadDetails = async () => {
		let tempContact = {};
		tempContact.Guid = match.params.leadId;

		const response = await GetLeadDetails(tempContact);

		if (response.IsSuccessful) {
			let tempLead = JSON.parse(response.Data);

			if (!tempLead.CustomFields) {
				tempLead.CustomFields = {};

				if (tempLead.ContactMetadata) {
					tempLead.ContactMetadata.map((cf) => {
						tempLead.CustomFields[cf.FieldName] = cf.FieldValue;
					});
				}
			}

			setLead(tempLead);
		}

		setIsLoading(false);
	};

	const handleGoBack = () => {
		history.goBack();
	};

	const handleMarkAsSpam = async () => {
		let data = {
			Guid   : match.params.leadId,
			IsSpam : true
		};

		setIsSaving(true);

		const response = await MarkContactSpam(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);
			history.push('/crm');
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const handleMarkNotSpam = async () => {
		let data = {
			Guid   : match.params.leadId,
			IsSpam : false
		};

		setIsSaving(true);

		const response = await MarkContactSpam(data);

		if (response.IsSuccessful) {
			toast.success(response.Message);
			history.push('/crm/spam');
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.value;

		setLead({
			...lead,
			[target] : value
		});
	};

	const handleCustomFieldChange = (e) => {
		const target = e.target.name;
		const value = e.target.value;

		let tempLead = { ...lead };

		if (!tempLead.CustomFields) {
			tempLead.CustomFields = {};
		}

		setLead({
			...tempLead,
			CustomFields : {
				...tempLead.CustomFields,
				[target] : value
			}
		});
	};

	const toggleTagsDialog = () => {
		setShowTagsDialog((prev) => !prev);
	};

	const toggleDeleteDialog = () => {
		setShowDeleteConfirmation((prev) => !prev);
	};

	const handleDelete = () => {
		history.push('/crm');
	};

	const handleRestore = async () => {
		setIsSaving(true);

		const response = await RestoreContact(lead);

		if (response.IsSuccessful) {
			toast.success(response.Message);
			getLeadDetails();
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		const response = await UpdateContact(lead);

		if (response.IsSuccessful) {
			toast.success(response.Message);
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<div className="app-crm-lead-details panel">
			<div className="container">
				<ActionsBar>
					<button
						type="button"
						className="btn btn-outline-primary"
						onClick={handleGoBack}
					>
						<i className="fas fa-chevron-left" />
						<span>Go Back</span>
					</button>
				</ActionsBar>

				{!isLoading ? (
					<Fragment>
						{lead && (
							<Fragment>
								{lead.FullName && (
									<h5 className="rw-container-title mb-1">
										{lead.LeadType === 'Form' && (
											<i className="fa-solid fa-list" />
										)}
										{lead.LeadType === 'Call' && (
											<i className="fa-solid fa-square-phone-flip" />
										)}
										{lead.LeadType !== 'Call' &&
										lead.LeadType !== 'Form' && (
											<i className="fas fa-id-card" />
										)}
										<span>{lead.FullName}</span>
									</h5>
								)}

								<ActionsBar
									align="right"
									className="crm-lead-actions mb-3"
								>
									{!lead.IsDeleted ? (
										<Fragment>
											<button
												type="button"
												className="btn btn-danger"
												disabled={isLoading || isSaving}
												onClick={toggleDeleteDialog}
											>
												<i className="far fa-trash-alt" />
												<span>Delete</span>
											</button>
										</Fragment>
									) : (
										<button
											type="button"
											className="btn btn-primary"
											disabled={isLoading || isSaving}
											onClick={handleRestore}
										>
											<i className="fa-solid fa-trash-arrow-up" />
											<span>Restore</span>
										</button>
									)}

									{lead.IsSpam ? (
										<Fragment>
											<button
												type="button"
												className="btn btn-outline-secondary"
												disabled={isLoading || isSaving}
												onClick={handleMarkNotSpam}
											>
												{!isSaving ? (
													<Fragment>
														<i className="fas fa-ban" />
														<span>Not Spam</span>
													</Fragment>
												) : (
													<Fragment>
														<Spinner />
														<span>Saving...</span>
													</Fragment>
												)}
											</button>
										</Fragment>
									) : (
										<Fragment>
											<button
												type="button"
												className="btn btn-outline-secondary"
												disabled={isLoading || isSaving}
												onClick={handleMarkAsSpam}
											>
												{!isSaving ? (
													<Fragment>
														<i className="fa-solid fa-robot" />
														<span>Spam</span>
													</Fragment>
												) : (
													<Fragment>
														<Spinner />
														<span>Saving...</span>
													</Fragment>
												)}
											</button>
										</Fragment>
									)}
								</ActionsBar>

								<form onSubmit={handleSubmit}>
									<div className="rw-container shadow-large contact-details">
										<div className="form-inputs">
											<div className="form-group">
												<label htmlFor="email">
													Email
												</label>
												<input
													type="email"
													id="email"
													name="Email"
													className="form-control form-control-lg"
													disabled={
														isLoading || isSaving
													}
													value={lead.Email}
													onChange={handleChange}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="fullName">
													Full Name
												</label>
												<input
													type="text"
													id="fullName"
													name="FullName"
													className="form-control form-control-lg"
													disabled={
														isLoading || isSaving
													}
													value={lead.FullName}
													onChange={handleChange}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="phone">
													Phone
												</label>
												<input
													type="text"
													id="phone"
													name="Phone"
													className="form-control form-control-lg"
													disabled={
														isLoading || isSaving
													}
													value={lead.Phone}
													onChange={handleChange}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="mobile">
													Mobile
												</label>
												<input
													type="text"
													id="mobile"
													name="Mobile"
													className="form-control form-control-lg"
													disabled={
														isLoading || isSaving
													}
													value={lead.Mobile}
													onChange={handleChange}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="otherPhone">
													Other Phone
												</label>
												<input
													type="text"
													id="otherPhone"
													name="OtherPhone"
													className="form-control form-control-lg"
													disabled={
														isLoading || isSaving
													}
													value={lead.OtherPhone}
													onChange={handleChange}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="fax">Fax</label>
												<input
													type="text"
													id="fax"
													name="Fax"
													className="form-control form-control-lg"
													disabled={
														isLoading || isSaving
													}
													value={lead.Fax}
													onChange={handleChange}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="companyName">
													Company Name
												</label>
												<input
													type="text"
													id="companyName"
													name="CompanyName"
													className="form-control form-control-lg"
													disabled={
														isLoading || isSaving
													}
													value={lead.CompanyName}
													onChange={handleChange}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="secondaryEmail">
													Secondary Email
												</label>
												<input
													type="email"
													id="secondaryEmail"
													name="SecondaryEmail"
													className="form-control form-control-lg"
													disabled={
														isLoading || isSaving
													}
													value={lead.SecondaryEmail}
													onChange={handleChange}
												/>
											</div>
											{/* <div className="form-group">
											<label htmlFor="dateOfBirth">Date of Birth</label>
											<input
												type="text"
												id="dateOfBirth"
												name="dateOfBirth"
												className="form-control form-control-lg"
												disabled={isLoading || isSaving}
												value={lead.DateOfBirth}
											/>
										</div> */}
										</div>
										<div className="rw-container-buttons">
											<button
												type="submit"
												className="btn btn-primary"
												disabled={isLoading || isSaving}
											>
												{!isSaving ? (
													<Fragment>
														<i className="fas fa-cloud-upload-alt" />
														<span>
															Save Changes
														</span>
													</Fragment>
												) : (
													<Fragment>
														<Spinner />
														<span>
															Saving Changes
														</span>
													</Fragment>
												)}
											</button>
										</div>
									</div>

									<h5 className="rw-container-title">
										<i className="fas fa-map-marker-alt" />
										<span>Address Details</span>
									</h5>
									<div className="rw-container shadow-large contact-mailing-details">
										<div className="form-inputs">
											<div className="form-group">
												<label htmlFor="address">
													Address
												</label>
												<input
													type="text"
													id="address"
													name="MailingAddress"
													className="form-control form-control-lg"
													disabled={
														isLoading || isSaving
													}
													value={lead.MailingStreet}
													onChange={handleChange}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="city">
													City
												</label>
												<input
													type="text"
													id="city"
													name="MailingCity"
													className="form-control form-control-lg"
													disabled={
														isLoading || isSaving
													}
													value={lead.MailingCity}
													onChange={handleChange}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="state">
													State
												</label>
												<input
													type="text"
													id="state"
													name="MailingState"
													className="form-control form-control-lg"
													disabled={
														isLoading || isSaving
													}
													value={lead.MailingState}
													onChange={handleChange}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="zip">
													ZIP Code
												</label>
												<input
													type="text"
													id="zip"
													name="MailingZip"
													className="form-control form-control-lg"
													disabled={
														isLoading || isSaving
													}
													value={lead.MailingZip}
													onChange={handleChange}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="country">
													Country
												</label>
												<input
													type="text"
													id="country"
													name="MailingCountry"
													className="form-control form-control-lg"
													disabled={
														isLoading || isSaving
													}
													value={lead.MailingCountry}
													onChange={handleChange}
												/>
											</div>
										</div>
										<div className="rw-container-buttons">
											<button
												type="submit"
												className="btn btn-primary"
												disabled={isLoading || isSaving}
											>
												{!isSaving ? (
													<Fragment>
														<i className="fas fa-cloud-upload-alt" />
														<span>
															Save Changes
														</span>
													</Fragment>
												) : (
													<Fragment>
														<Spinner />
														<span>
															Saving Changes
														</span>
													</Fragment>
												)}
											</button>
										</div>
									</div>

									<h5 className="rw-container-title">
										<i className="fas fa-keyboard" />
										<span>Notes</span>
									</h5>

									<div className="rw-container shadow-large contact-additional-details">
										<div className="form-inputs">
											<div className="form-group">
												<label htmlFor="notes">
													Notes
												</label>
												<textarea
													id="notes"
													name="Notes"
													className="form-control form-control-lg"
													disabled={
														isLoading || isSaving
													}
													value={lead.Notes}
													placeholder="Enter your Notes here"
													onChange={handleChange}
													rows={3}
												/>
											</div>
										</div>
										<div className="rw-container-buttons">
											<button
												type="submit"
												className="btn btn-primary"
												disabled={isLoading || isSaving}
											>
												{!isSaving ? (
													<Fragment>
														<i className="fas fa-cloud-upload-alt" />
														<span>
															Save Changes
														</span>
													</Fragment>
												) : (
													<Fragment>
														<Spinner />
														<span>
															Saving Changes
														</span>
													</Fragment>
												)}
											</button>
										</div>
									</div>

									<h5 className="rw-container-title">
										<i className="fa-solid fa-chart-line" />
										<span>UTM</span>
									</h5>

									<div className="rw-container shadow-large utm-details">
										<div className="form-inputs">
											<div className="form-group">
												<label htmlFor="utmSource">
													UTM Source
												</label>
												<input
													type="text"
													id="utmSource"
													name="UTMSource"
													className="form-control form-control-lg"
													disabled
													value={lead.UTMSource}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="utmMedium">
													UTM Medium
												</label>
												<input
													type="text"
													id="utmMedium"
													name="UTMMedium"
													className="form-control form-control-lg"
													disabled
													value={lead.UTMMedium}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="utmCampaign">
													UTM Campaign
												</label>
												<input
													type="text"
													id="utmCampaign"
													name="UTMCampaign"
													className="form-control form-control-lg"
													disabled
													value={lead.UTMCampaign}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="utmTerm">
													UTM Term
												</label>
												<input
													type="text"
													id="utmTerm"
													name="UTMTerm"
													className="form-control form-control-lg"
													disabled
													value={lead.UTMTerm}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="utmContent">
													UTM Content
												</label>
												<input
													type="text"
													id="utmContent"
													name="UTMContent"
													className="form-control form-control-lg"
													disabled
													value={lead.UTMContent}
												/>
											</div>
											<div className="form-group">
												<label htmlFor="ipAddress">
													IP Address
												</label>
												<input
													type="text"
													id="ipAddress"
													name="IPAddress"
													className="form-control form-control-lg"
													disabled
													value={lead.IPAddress}
												/>
											</div>
										</div>
										{/* <div className="rw-container-buttons">
											<button
												type="submit"
												className="btn btn-primary"
												disabled={isLoading || isSaving}
											>
												{!isSaving ? (
													<Fragment>
														<i className="fas fa-cloud-upload-alt" />
														<span>
															Save Changes
														</span>
													</Fragment>
												) : (
													<Fragment>
														<Spinner />
														<span>
															Saving Changes
														</span>
													</Fragment>
												)}
											</button>
										</div> */}
									</div>

									<h5 className="rw-container-title">
										<i className="fas fa-keyboard" />
										<span>Other Data</span>
									</h5>

									<div className="rw-container shadow-large contact-additional-details">
										<div className="form-inputs">
											<div className="form-group">
												<label htmlFor="description">
													Description
												</label>
												<textarea
													id="description"
													name="Description"
													className="form-control form-control-lg"
													disabled={
														isLoading || isSaving
													}
													value={lead.Description}
													onChange={handleChange}
													rows={3}
												/>
											</div>

											{lead.ContactMetadata && (
												<Fragment>
													{lead.ContactMetadata.map(
														(otherData) => (
															<div className="form-group">
																<label
																	htmlFor={
																		otherData.FieldName
																	}
																>
																	{
																		otherData.FieldName
																	}
																</label>
																{otherData
																	.FieldValue
																	.length >=
																80 ? (
																	<textarea
																		id={
																			otherData.FieldName
																		}
																		name={
																			otherData.FieldName
																		}
																		className="form-control form-control-lg"
																		disabled={
																			isSaving ||
																			isLoading
																		}
																		value={
																			otherData.FieldValue
																		}
																		onChange={
																			handleCustomFieldChange
																		}
																		rows={3}
																	/>
																) : (
																	<input
																		type="text"
																		id={
																			otherData.FieldName
																		}
																		name={
																			otherData.FieldName
																		}
																		className="form-control form-control-lg"
																		disabled={
																			isSaving ||
																			isLoading
																		}
																		value={
																			lead
																				.CustomFields[
																				otherData
																					.FieldName
																			]
																		}
																		onChange={
																			handleCustomFieldChange
																		}
																	/>
																)}
															</div>
														)
													)}
												</Fragment>
											)}
										</div>
										<div className="rw-container-buttons">
											<button
												type="submit"
												className="btn btn-primary"
												disabled={isLoading || isSaving}
											>
												{!isSaving ? (
													<Fragment>
														<i className="fas fa-cloud-upload-alt" />
														<span>
															Save Changes
														</span>
													</Fragment>
												) : (
													<Fragment>
														<Spinner />
														<span>
															Saving Changes
														</span>
													</Fragment>
												)}
											</button>
										</div>
									</div>
								</form>

								<h5 className="rw-container-title">
									<i className="fa-solid fa-tags" />
									<span>Contact Tags</span>
								</h5>
								<div className="rw-container shadow-large contact-mailing-details">
									<div className="form-inputs">
										{lead.MailChimpTags &&
										lead.MailChimpTags.length > 0 && (
											<Fragment>
												<div className="tags-container mailchimp-tags-container">
													{lead.MailChimpTags.map(
														(tag) => (
															<div className="lead-tag mailchimp">
																<i className="fab fa-mailchimp" />
																<span>
																	{tag}
																</span>
															</div>
														)
													)}
												</div>
											</Fragment>
										)}

										<div className="tags-container">
											{lead.Tags &&
											lead.Tags.length > 0 && (
												<Fragment>
													{lead.Tags.map((tag) => (
														<div className="lead-tag">
															<span>{tag}</span>
														</div>
													))}
												</Fragment>
											)}
										</div>
									</div>
									<div className="rw-container-buttons">
										<Link
											onClick={toggleTagsDialog}
											className="btn btn-primary"
										>
											<i className="fa-solid fa-tags" />
											<span>Manage Tags</span>
										</Link>
									</div>
								</div>

								<div className="lead-meta-information">
									<small className="text-muted">
										{leadTitleText}
									</small>
									{lead.FormName &&
									lead.FormUrl && (
										<Fragment>
											<div className="submission-details mb-1">
												<small className="text-muted">
													{lead.FormName} form
													submitted at:{' '}
													<a
														href={lead.FormUrl}
														target="_blank"
														className="app-link"
													>
														<span>
															{lead.FormUrl}
														</span>
														<i className="fas fa-external-link-alt ml-1" />
													</a>
												</small>
											</div>
										</Fragment>
									)}
								</div>

								{showTagsDialogs && (
									<CrmLeadTags
										lead={lead}
										onUpdate={getLeadDetails}
										onClose={toggleTagsDialog}
									/>
								)}

								{showDeleteConfirmation && (
									<DeleteLeadDialog
										lead={lead}
										onCancel={toggleDeleteDialog}
										onDelete={handleDelete}
									/>
								)}
							</Fragment>
						)}
					</Fragment>
				) : (
					<CenteredContainer>
						<Spinner />
					</CenteredContainer>
				)}
			</div>
		</div>
	);
};

export default CrmLeadDetails;
