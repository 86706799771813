import React from 'react';
import Spinner from '../../../spinner/Spinner';

const LoginForm = ({ email, password, isLoading, handleChange, loginCallback }) => {
	return (
		<div className="login-form">
			<form onSubmit={loginCallback}>
				<div className="form-group mt-3">
					<label htmlFor="email" className="form-control-label">
						Email
					</label>
					<input
						id="email"
						type="email"
						name="email"
						className="form-control form-control-lg"
						value={email}
						onChange={handleChange}
						disabled={isLoading}
					/>
				</div>
				<div className="form-group mt-3">
					<label htmlFor="password" className="form-control-label">
						Password
					</label>
					<input
						id="password"
						type="password"
						name="password"
						className="form-control form-control-lg"
						value={password}
						onChange={handleChange}
						disabled={isLoading}
					/>
				</div>
				<button
					id="btnLogin"
					type="submit"
					className="btn btn-primary btn-lg btn-block mt-3"
					disabled={isLoading}
				>
					{isLoading ? <Spinner /> : <span>Continue</span>}
				</button>
			</form>
		</div>
	);
};

export default LoginForm;
