import { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GetInvoiceLinkByCode } from '../../Services';
import ReactCodeInput from 'react-verification-code-input';
import Spinner from '../../spinner/Spinner';
import CenteredContainer from '../centeredContainer/CenteredContainer';
import './InvoiceCodePage.css';

const InvoiceCodePage = () => {
	const [ formData, setFormData ] = useState({});
	const [ isSaving, setIsSaving ] = useState(false);
	const history = useHistory();
	const inputRef = useRef(null);

	useEffect(() => {
		if (inputRef && inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	const handleCodeInputComplete = async (code) => {
		setFormData({
			...formData,
			['InvoiceCode'] : code
		});

		await submitCode({
			...formData,
			['InvoiceCode'] : code
		});
	};

	const submitCode = async (data) => {
		let tempFormData = data ? data : formData;

		if (
			!tempFormData.InvoiceCode &&
			tempFormData.InvoiceCode.trim() === ''
		) {
			toast.error('Invoice code is required to continue');
			return;
		}

		setIsSaving(true);

		const response = await GetInvoiceLinkByCode(tempFormData);

		if (response.IsSuccessful) {
			history.push(response.Data);
		} else {
			toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<Fragment>
			<CenteredContainer>
				<div className="app-invoice-code">
					<h5 className="rw-container-title">
						<span>Enter your invoice code</span>
					</h5>

					<ReactCodeInput
						type="text"
						className="verification-code-inputs"
						fields={6}
						loading={isSaving}
						onComplete={handleCodeInputComplete}
					/>
				</div>
			</CenteredContainer>
		</Fragment>
	);
};

export default InvoiceCodePage;
